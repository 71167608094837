import Grid from "@material-ui/core/Grid";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import {format} from "date-fns";
import Header from "./Header";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {Link, useMatch} from "@gatsbyjs/reach-router";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap} from "@fortawesome/free-solid-svg-icons";

const GET_ROUTE = loader('../../gql/query/route.graphql');

export default function TopBar() {
    const date = new Date();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const {data} = useQuery(GET_ROUTE, {
        fetchPolicy: "cache-only",
        variables: {
            date: format(date, 'yyyy-MM-dd'),
        },
    });
    const isStep2 = useMatch('/route/step2');
    const isStep3 = useMatch('/route/step3');

    let step = 1;
    if (isStep2) {
        step = 2;
    } else if (isStep3) {
        step = 3;
    }

    let title = 'uprava ';
    if (!data?.lastRoute?.id || data?.lastRoute?.isClosed) {
        title = 'vytvaranie ';
    }

    return (
        <Grid container spacing={isSmall ? 3 : 6} alignItems="center">
            <Grid item xs={9}>
                <Header>
                    {title} trasy {format(date, 'dd.MM.yyyy')} {step}/3
                </Header>
            </Grid>

            {isStep3 && (
                <Grid item xs={3}>
                    <Link to="/map?planner">
                        <Button
                            variant="contained"
                            size="small"
                            fullWidth
                        >
                            <FontAwesomeIcon style={{fontSize: 28}} icon={faMap}/>
                        </Button>
                    </Link>
                </Grid>
            )}
        </Grid>
    );
}
