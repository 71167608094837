const actions = {
  handleInputText: 'HANDLE INPUT TEXT',
  handlePinCodeInput: 'HANDLE PIN CODE INPUT',
  handleAdditionalTextInfo: 'HANDLE ADDITIONAL INFO',
  openFailDelivery: 'OPEN FAIL DELIVERY MODAL',
  closeFailDelivery: 'CLOSE FAIL DELIVERY MODAL',
  setDate: 'SET DATE',
  setDeliveryCode: 'SET DELIYVERY CODE',
  closeAdditionalModal: 'CLOSE ADDITIONAL MODAL',
  successDeliveryButtonClick: 'SUCCESS DELIVERY BUTTON CLICK',
  okClickModal: 'OK ADDITIONAL MODAL',
  okClickDateModal: 'OK DATE MODAL',
  helpClickModal: 'HELP ADDITIONAL MODAL',
  handleCOD: 'HANDLE COD',
  sent: 'SENT',
  closeDateModal: 'CLOSE DATE MODAL',
  changeDateModal: 'CHANGE DATE MODAL',
  setDeliveryTimeSlot: 'SET DELIVERY TIME SLOT',
  closeSuccessDeliveryAdditionalModal: 'CLOSE SUCCESS DELIVERY ADDITIONAL MODAL',
  openEditClientNoteModal: 'OPEN EDIT CLIENT NOTE MODAL',
  closeEditClientNoteModal: 'CLOSE EDIT CLIENT NOTE MODAL',
  yesClickEditClientNoteModal: 'YES CLICK EDIT CLIENT NOTE MODAL',
  handleAdditionalConditions: 'HANDLE ADDITIONAL CONDITIONS UNIVERSAL',
};

export default actions;
