import Grid from "@material-ui/core/Grid";
import Search from "../search";
import RefreshButton from "../RefreshButton";
import CountShipment from "../CountShipment";
import {Link} from "@gatsbyjs/reach-router";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode, faMap} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";

export default function TopBar({handleReloadClick}) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid container spacing={isSmall ? 3 : 6} alignItems="center">
            <Grid item xs={4} md={8}>
                <Search/>
            </Grid>
            <Grid container item xs={8} md={4} spacing={6}>
                <Grid item xs={4}>
                    <RefreshButton handleClick={handleReloadClick}>
                        <CountShipment/>
                    </RefreshButton>
                </Grid>
                <Grid item xs={4}>
                    <Link to="/scannerselect">
                        <Button
                            variant="contained"
                            size="medium"
                            fullWidth
                        >
                            <FontAwesomeIcon style={{fontSize: 28}} icon={faBarcode} />
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to="/map">
                        <Button
                            variant="contained"
                            size="medium"
                            fullWidth
                        >
                            <FontAwesomeIcon style={{fontSize: 28}} icon={faMap} />
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}
