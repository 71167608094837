import React from "react";
import {loader} from "graphql.macro";
import {useQuery, useLazyQuery} from "@apollo/client";
import {filterShipment, filterShipmentSecond} from "../../utils/shipments";
import {format, startOfDay, subDays} from "date-fns";
import Button from "@material-ui/core/Button";

const GET_ALL_LOCAL = loader('../../gql/query/client/all.graphql');
const GET_FILTER = loader('../../gql/query/client/filter.graphql');
const GET_SHIPMENTS = loader('../../gql/query/shipments.graphql');

export default function SelectAll({children, className}) {
    const updateSelectedShipments = (data) => {
        if (data) {
            let filtered;
            filtered = filterShipment(data.shipments, dataLocal.filter, dataLocal.user);
            filtered = filterShipmentSecond(filtered, dataLocal.filterSecond);

            client.writeQuery({
                query: GET_FILTER,
                data: {
                    filter: {
                        ...dataLocal.filter,
                        multiSelectedShipments: filtered.map(shipment => shipment.Zasielka_Kod),
                        __typename: 'Filter',
                    }
                },
            });
        }
    }

    const {data: dataLocal, client} = useQuery(GET_ALL_LOCAL);
    const [getShipments] = useLazyQuery(GET_SHIPMENTS, {
        fetchPolicy: 'cache-only',
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'ignore',
        returnPartialData: true,
/*        onCompleted: (data) => {
            updateSelectedShipments(data);
       },*/
    });

    const handleSelectAll = async () => {
        const result = await getShipments({
            variables: {
                fromDate: format(startOfDay(subDays(new Date(), process.env.REACT_APP_HISTORY_DATE)), 'yyyy-MM-dd'),
                toDate: format(new Date(), 'yyyy-MM-dd'),
            },
        });

        updateSelectedShipments(result.data);
    }

    return (
        <Button
            onClick={handleSelectAll}
            variant="contained"
            size="small"
            className={className}
        >
            {children}
        </Button>
    );
};
