import sound from "./beep-10.mp3";

const ErrorSound = () => {
    const snd = new Audio(sound);
    const promise = snd.play();
    promise.catch(error => {
        console.error('disabled autoplay sound');
        console.error(error);
    });
};

export default ErrorSound;
