const generatePickupMapUrl = (shipment) => {
    let suffix = '';
    if (shipment.InternPickUp || (shipment.InternPickUpStreet && shipment.InternPickUpStreetNumber)) {
        suffix = shipment?.InternPickUpStreet + ' ' + shipment?.InternPickUpStreetNumber + ',' + shipment?.InternPickUpCity;
    } else {
        if (shipment.LongitudeO !== null && shipment.LatitudeO) {
            suffix = shipment.LatitudeO + ',' + shipment.LongitudeO;
        } else if ((shipment.UlicaO && shipment.UlicaO.length > 0) || (shipment.ObecO && shipment.ObecO.length > 0) || (shipment.CisloO && shipment.CisloO.length > 0)) {
            suffix = shipment.UlicaO + ' ' + shipment.CisloO + ',' + shipment.ObecO;
        }
    }
    return "https://maps.google.com/?q=" + encodeURI(suffix);
}

const generateDeliveryMapUrl = (shipment) => {
    let suffix = '';
    if (shipment.LongitudeD !== null && shipment.LatitudeD) {
        suffix = shipment.LatitudeD + ',' + shipment.LongitudeD;
    } else if (shipment.UlicaD && (shipment.UlicaD.length > 0 || (shipment.ObecD && shipment.ObecD.length) > 0 || (shipment.CisloD && shipment.CisloD.length > 0))) {
        suffix = shipment.UlicaD + ' ' + shipment.CisloD + ',' + shipment.ObecD;
    }
    return "https://maps.google.com/?q=" + encodeURI(suffix);
}

export {
    generatePickupMapUrl,
    generateDeliveryMapUrl
}
