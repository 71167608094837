import React, {useEffect, useState} from 'react';
import {alpha, makeStyles} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
    },
    activeWrapper: {
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
            minWidth: 180,
            zIndex: 10,
            position: 'relative',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
    },
    clearButton: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 2,
        },
    }
}));

const GET_FILTER = loader('../gql/query/client/filter.graphql');

export default function Search() {
    const classes = useStyles();
    const [focus, setFocus] = useState(false);
    const [search, setSearch] = useState('');
    const {data, client} = useQuery(GET_FILTER, {
        onCompleted: data => setSearch(data.filter.search),
    });

    useEffect(() => {
        if (search.length >= 3) {
            client.writeQuery({
                query: GET_FILTER,
                data: {
                    filter: {
                        ...data.filter,
                        search: search,
                        __typename: 'Filter',
                    }
                },
            })
        } else {
            if (data && data.filter.search !== '') {
                client.writeQuery({
                    query: GET_FILTER,
                    data: {
                        filter: {
                            ...data.filter,
                            search: '',
                            __typename: 'Filter',
                        }
                    },
                })
            }
        }
    }, [search]);

    const handleClickClear = () => {
        setSearch('');
    };

    return (
        <div className={clsx(focus && classes.activeWrapper)}>
            <TextField
                className={classes.search}
                placeholder="Search…"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {search &&
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickClear}
                                edge="end"
                                className={classes.clearButton}
                            >
                                <ClearIcon/>
                            </IconButton>
                            }
                        </InputAdornment>
                    )
                }}
                value={search}
                onChange={event => setSearch(event.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                error={search.length > 0 && search.length < 3}
            />
        </div>
    );
}
