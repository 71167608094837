import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Line from "./line";


const useStyles = makeStyles(theme => ({
    root: {
    },
}));

export default function CheckboxBlock({className, name, label, onChange, ...props}) {
    const classes = useStyles();
    const [state, setState] = React.useState(false);

    const handleChange = event => {
        setState(event.target.checked);
        onChange(event.target.checked);
    };

    return (
        <Line>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={state}
                        onChange={handleChange}
                        value={name}
                        color="secondary"
                        className={clsx(classes.root, className)}
                        {...props}
                    />
                }
                label={label}
            />
        </Line>
    );
};