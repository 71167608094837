import React, {forwardRef, Fragment} from 'react';
import {useQuery} from '@apollo/client';
import {loader} from "graphql.macro";
import ShipmentsSingle from "./shipmentsSingle";
import ShipmentsClassic from "./shipmentsClassic";
import {persistActualUrl} from "../utils/history/lastUrlPersist";


const ALL = loader('../gql/query/client/all.graphql');

function Shipments(props, ref) {
    const {data, loading} = useQuery(ALL);

    persistActualUrl();

    return (
        <Fragment>
            {!loading && data.filter.view && <ShipmentsSingle dataLocal={data} ref={ref} />}
            {!loading && !data.filter.view && <ShipmentsClassic dataLocal={data} ref={ref} />}
        </Fragment>
    );
}

export default forwardRef(Shipments);
