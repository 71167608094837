import Geocode from "react-geocode";

export default async function GeolocationFromLatLng(lat, lng) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage("sk");
    Geocode.setRegion("sk");

    const response = await Geocode.fromLatLng(lat, lng);

    let location = {
        street_number: null,
        street: null,
        sublocality: null,
        locality: null,
        political: null,
        postal_code: null,
        admin_2: null,
        admin_1: null,
        country: null,
    };

    response.results[0].address_components.forEach(component => {
        if (location['street_number'] === null && component['types'].includes('street_number')) {
            location['street_number'] = component['long_name'];
        } else if (location['street'] === null && component['types'].includes('route')) {
            location['street'] = component['long_name'];
        } else if (location['political'] === null && component['types'].includes('political')) {
            location['political'] = component['long_name'];
        } else if (location['sublocality'] === null && component['types'].includes('sublocality')) {
            location['sublocality'] = component['long_name'];
        } else if (location['locality'] === null && component['types'].includes('locality')) {
            location['locality'] = component['long_name'];
        } else if (location['postal_code'] === null && component['types'].includes('postal_code')) {
            location['postal_code'] = component['long_name'];
        } else if (location['admin_2'] === null && component['types'].includes('administrative_area_level_2')) {
            location['admin_2'] = component['long_name'];
        } else if (location['admin_1'] === null && component['types'].includes('administrative_area_level_1')) {
            location['admin_1'] = component['long_name'];
        } else if (location['country'] === null && component['types'].includes('country')) {
            location['country'] = component['long_name'];
        }
    });

    return location;
}
