import React from 'react';
import TabPanel from '../../../components/tabPanel';
import ExchangeTab from '../exchangeTab';


export default function ExchangeTabWrapper({data, dataLocal, value}) {
    return (
        <TabPanel value={value} index={5}>
            <ExchangeTab shipment={data.shipment} dataLocal={dataLocal}/>
        </TabPanel>
    );
}
