import {makeStyles} from '@material-ui/core/styles';
import React from "react";


const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: 'bold',
    }
}));

export default function Value(props) {
    const classes = useStyles();

    return (
        <span
            className={classes.root}
        >
            {props.children}
        </span>
    );
};