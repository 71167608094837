import React from "react";
import Grid from "@material-ui/core/Grid";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import PhoneDisabled from "@material-ui/icons/PhoneDisabled";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        textAlign: 'center'
    },
    phoneIcons: {
        fontSize: '1.5rem',
    },
    phoneHref: {
        color: theme.colors.href,
        display: 'inline-block',
        position: 'relative',
        zIndex: '1',
        padding: '1em 0.4em',
        margin: '-1em 0',
        fontSize: '1.2em',
        textDecoration: 'none',
        textAlign: 'center',
    },
}));

export default function PhoneBox({phoneSender, phoneConsignor, onPhoneClick}) {
    const classes = useStyles();

    const handlePhone = (e) => {
        onPhoneClick();
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={6} className={classes.wrapper}>
                {phoneSender && phoneSender > 0 ?
                    <a href={"tel:" + phoneSender} className={classes.phoneHref} onClick={handlePhone}>
                        <PhoneInTalk className={classes.phoneIcons}/><br/>Odosielateľ
                    </a>
                    : <span className={classes.phoneHref}><PhoneDisabled color="disabled" className={classes.phoneIcons}/><br/>Odosielateľ</span>}
            </Grid>

            <Grid item xs={6} className={classes.wrapper}>
                {phoneConsignor && phoneConsignor > 0 ?
                    <a href={"tel:" + phoneConsignor} className={classes.phoneHref} onClick={handlePhone}>
                        <PhoneInTalk className={classes.phoneIcons}/><br/>Príjemca
                    </a>
                    : <span className={classes.phoneHref}><PhoneDisabled color="disabled" className={classes.phoneIcons}/><br/>Príjemca</span>}
            </Grid>
        </Grid>
    );
};