import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {SnackbarProvider} from "notistack";

const useStyles = makeStyles(theme => ({
    root: {
        '& div': {
            flexGrow: 0,
            pointerEvents: 'auto',
        }
    },
    containerRoot: {
        pointerEvents: 'none',
    }
}));



export default function SnackbarProviderCustom(props) {
    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={8}
            autoHideDuration={3000}
            preventDuplicate={true}
            dense
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            classes={{
                root: classes.root,
                containerRoot: classes.containerRoot,
            }}
        >
            {props.children}
        </SnackbarProvider>
    );
}