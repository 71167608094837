import React, {useEffect, useState} from 'react';
import Types from "../../datasource/shipmentPointsGraphQL";
import LocationPickerModal from "../map/locationPickerModal";
import {loader} from "graphql.macro";
import {useMutation} from "@apollo/client";
import {errorMessage, successMessage} from "../../utils/messages";
import {useSnackbar} from "notistack";
import {Loading} from "../index";

const UPDATE_POINT_GEOLOCATION_MUTATION = loader('../../gql/mutations/updatePointGeolocation.graphql');

const UpdateLocationModal = ({shipment, type, show, updatePointGeolocationCallback, closeWindowCallback}) => {
    let initLocation = null;

    const [openLocationModal, setOpenLocationModal] = useState(show);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [sendUpdatePointGeolocation, {loading}] = useMutation(UPDATE_POINT_GEOLOCATION_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: data => {
            successMessage('Uspešne zmenená poloha.', enqueueSnackbar, closeSnackbar);
            handleClose();
            if (updatePointGeolocationCallback) {
                updatePointGeolocationCallback(data);
            }
        },
    });

    useEffect(() => {
        setOpenLocationModal(show);
    }, [show]);

    const handleLocation = (position) => {
        sendUpdatePointGeolocation({
            variables: {
                id: shipment.Zasielka_Kod,
                location: {
                    latitude: position.lat,
                    longitude: position.lng,
                },
                pointType: type === Types.TO_PICKUP ? 'Pickup' : 'Delivery',
            },
        });
    }

    const handleClose = (event) => {
        if (event)
            event.stopPropagation();
        setOpenLocationModal(false);
        if (closeWindowCallback) {
            closeWindowCallback();
        }
    }

    // if shipment have location we use it as init location, if not we try geocode address and use it as approx init location
    if (type === Types.TO_PICKUP) {
        if (shipment.LongitudeO !== null && shipment.LatitudeO !== null) {
            initLocation = {
                lat: shipment.LatitudeO,
                lng: shipment.LongitudeO,
            };
        } else if (shipment.UlicaO?.length > 0 || shipment.ObecO?.length > 0 || shipment.CisloO?.length > 0) {
            // todo move useEffect
            //initLocation = await GeolocationFromAddress(shipment?.UlicaO + ' ' + shipment?.CisloO + ',' + shipment?.ObecO);
        }
    } else if (type === Types.TO_DELIVERY) {
        if (shipment.LongitudeD !== null && shipment.LatitudeD !== null) {
            initLocation = {
                lat: shipment.LatitudeD,
                lng: shipment.LongitudeD,
            };
        } else if (shipment.UlicaD?.length > 0 || shipment.ObecD?.length > 0 || shipment.CisloD?.length > 0) {
            // todo move to useEffect
            //initLocation = await GeolocationFromAddress(shipment?.UlicaD + ' ' + shipment?.CisloD + ',' + shipment?.ObecD);
        }
    }

    if (loading) return <Loading/>;

    return (
        <LocationPickerModal
            open={openLocationModal}
            handleClose={handleClose}
            handlePosition={handleLocation}
            initLocation={initLocation}
        />
    );
};

export default UpdateLocationModal;
