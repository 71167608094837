import {isWithinInterval} from "date-fns";

const {subBusinessDays, isSameDay, startOfDay, endOfDay, isSaturday, isSunday, addDays} = require('date-fns')

const actualYears = new Date().getFullYear();

const slovakHolidays = [
    new Date(actualYears, 0, 1),
    new Date(actualYears, 6, 5),
    new Date(actualYears, 7, 29),
    new Date(actualYears, 8, 1),
    new Date(actualYears, 10, 17),

    new Date(actualYears, 0, 6),
    new Date(actualYears, 4, 1),
    new Date(actualYears, 4, 8),
    new Date(actualYears, 8, 15),
    new Date(actualYears, 10, 1),
    new Date(actualYears, 11, 24),
    new Date(actualYears, 11, 25),
    new Date(actualYears, 11, 26),

    //Easter
    new Date(2021, 3, 2),
    new Date(2021, 3, 5),
    new Date(2022, 3, 15),
    new Date(2022, 3, 18),
    new Date(2023, 3, 7),
    new Date(2023, 3, 10),
    new Date(2024, 2, 29),
    new Date(2024, 3, 1),
    new Date(2025, 3, 18),
    new Date(2025, 3, 21),
    new Date(2026, 3, 3),
    new Date(2026, 3, 6),
    new Date(2027, 2, 26),
    new Date(2027, 2, 29),
    new Date(2028, 3, 14),
    new Date(2028, 3, 17),
    new Date(2029, 2, 30),
    new Date(2029, 3, 2),
    new Date(2030, 3, 19),
    new Date(2030, 3, 22),
    new Date(2030, 3, 11),
    new Date(2030, 3, 14),
];

const isInArray = (date, arrayDates) => {
    for (const d of arrayDates) {
        const r = isSameDay(d, date);

        if (r) {
            return true;
        }
    }

    return false;
}

const subBusinessDaysAndHolidays = (date, amount) => {
    let startDate = startOfDay(subBusinessDays(date, amount));
    let endDate = endOfDay(date);
    let actualDay = new Date(startDate.getTime());

    let subDays = 0;

    for (let d = actualDay; d <= endDate; d.setDate(d.getDate() + 1)) {
        if (!isSunday(d) && !isSaturday(d) && isInArray(d, slovakHolidays)) {
            subDays++;
        }
    }

    if (subDays !== 0) {
        return subBusinessDaysAndHolidays(startDate, subDays);
    }

    return startDate;
};

// skip also slovak holidays
const addWorkingDays = (date, days) => {
    let actualDay = new Date(date.getTime());
    let countHolidays = 0;

    actualDay.setDate(actualDay.getDate() + 1);
    for (let i = 0; i < days; i++) {
        if (isSunday(actualDay) || isSaturday(actualDay) || isInArray(actualDay, slovakHolidays)) {
            countHolidays++;
        }

        actualDay.setDate(actualDay.getDate() + 1);
    }

    return addDays(date, days+countHolidays);
};

const isHoliday = (date) => {
    for (const d of slovakHolidays) {
        const r = isSameDay(d, date);

        if (r) {
            return true;
        }
    }

    return false;
}

/**
 * if is time in date in fist time slot range 00:00-12:00
 * @param date
 * @returns {boolean}
 */
const isFistTimeSlot = (date) => {
    let from = new Date(date.getTime());
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);

    let to = new Date(date.getTime());
    to.setHours(12);
    to.setMinutes(0);
    to.setSeconds(0);

    return isWithinInterval(
        date,
        { start: from, end: to }
    );
}

/**
 * if is time in date in fist time slot range 12:00-16:30
 * @param date
 * @returns {boolean}
 */
const isSecondTimeSlot = (date) => {
    let from = new Date(date.getTime());
    from.setHours(12);
    from.setMinutes(0);
    from.setSeconds(0);

    let to = new Date(date.getTime());
    to.setHours(16);
    to.setMinutes(30);
    to.setSeconds(0);

    return isWithinInterval(
        date,
        { start: from, end: to }
    );
}


/**
 * if is time in date in fist time slot range 16:30-23:59
 * @param date
 * @returns {boolean}
 */
const isThirdTimeSlot = (date) => {
    let from = new Date(date.getTime());
    from.setHours(16);
    from.setMinutes(30);
    from.setSeconds(0);

    let to = new Date(date.getTime());
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);

    return isWithinInterval(
        date,
        { start: from, end: to }
    );
}

export {
    addWorkingDays,
    subBusinessDaysAndHolidays,
    isHoliday,
    isFistTimeSlot,
    isSecondTimeSlot,
    isThirdTimeSlot
};

