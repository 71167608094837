import {convertMinutesToTime, splitNames} from "../../../../utils";

export const formatShipmentToDeliveryMutation = (values, shipment) => {
    const deliveryName = splitNames(values.nameDelivery);

    let data = {
        delivered: values.delivered,
        deliveryCode: values.deliveryCode,
        zasielkaKod: shipment.Zasielka_Kod,
        dobaCakania: convertMinutesToTime(values.time),
        poznamkaIntern: values.noteIntern,
        poznamkaKlient: values.noteClient,
        menoD: deliveryName[0] || '',
        priezviskoD: deliveryName[1] || '',
        telefonD: values.phoneDelivery,
        CODPaymentType: values.CODPaymentType,
        pinCode: values.pinCode,
        additionalInfo: JSON.stringify(values.additionalInfo),
    };

    const ignoredKeys = [
        'hmotnostId',
        'menoD',
        'priezviskoD',
        'poznamkaKlient', // courier be able to delete exist note
    ];
    Object.keys(data).forEach(
        (key) => {
            if ((data[key] == null || data[key] === '') && !ignoredKeys.includes(key) && key) {
                delete data[key];
            }
        }
    );

    if (data.poznamkaKlient === null) {
        data.poznamkaKlient = '';
    }

    return data;
};
