import {getFlagVariant, wasDelivery, wasPickUp} from './../utils';
import {gql} from '@apollo/client';
import Types from "../datasource/shipmentPointsGraphQL";
import {isZdravaStrava} from "../utils/shipments";

export const checkSearch = (shipment, search) => {
    return shipment.search.includes(search);
};

const sortCallbackExchangingLast = (shipment1, shipment2) => {
    if (shipment1.ExchangingToMe && !shipment2.ExchangingToMe) {
        return 1;
    }
    if (shipment2.ExchangingToMe && !shipment1.ExchangingToMe) {
        return -1;
    }
};

const sortCallbackWithFlagLater = (shipment1, shipment2) => {
    const picked1 = wasPickUp(shipment1);
    const haveFlag1 = getFlagVariant(shipment1.StatDISO, shipment1.ObecD, shipment1.UlicaD, shipment1.CisloD, shipment1.NaDepo);

    const picked2 = wasPickUp(shipment2);
    const haveFlag2 = getFlagVariant(shipment2.StatDISO, shipment2.ObecD, shipment2.UlicaD, shipment2.CisloD, shipment2.NaDepo);

    if (picked1 && haveFlag1 && (!picked2 || (picked2 && !haveFlag2))) {
        return 1;
    }

    if (picked2 && haveFlag2 && (!picked1 || (picked1 && !haveFlag1))) {
        return -1;
    }

    return undefined;
};

const checkPreSortConditions = (shipment1, shipment2) => {
    const exchangingSort = sortCallbackExchangingLast(shipment1, shipment2);
    if (typeof exchangingSort !== "undefined") {
        return exchangingSort;
    }

    const withFlagSort = sortCallbackWithFlagLater(shipment1, shipment2);
    if (typeof withFlagSort !== "undefined") {
        return withFlagSort;
    }
}

const postSortCondition = (shipment1, shipment2) => {
    return shipment2.id - shipment1.id;
}

export const getSortCallbackClassic = (order) => {
    const sortDateCreateOld = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment1.DatumObjed - shipment2.DatumObjed;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortDateCreateNew = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment2.DatumObjed - shipment1.DatumObjed;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortStreetPickUpAZ = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment1.UlicaO.localeCompare(shipment2.UlicaO);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortStreetPickUpZA = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment2.UlicaO.localeCompare(shipment1.UlicaO);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortStreetDeliveryAZ = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment1.UlicaD.localeCompare(shipment2.UlicaD);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortStreetDeliveryZA = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment2.UlicaD.localeCompare(shipment1.UlicaD);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortNameDeliveryAZ = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment1.NazovD.localeCompare(shipment2.NazovD);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortNameDeliveryZA = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment2.NazovD.localeCompare(shipment1.NazovD);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortNameConsignorAZ = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment1.NazovO.localeCompare(shipment2.NazovO);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortNameConsignorZA = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const result = shipment2.NazovO.localeCompare(shipment1.NazovO);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortNameAZ = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const name1 = wasPickUp(shipment1) || shipment1.ExchangingToMe ? shipment1.NazovD : shipment1.NazovO;
        const name2 = wasPickUp(shipment2) || shipment2.ExchangingToMe ? shipment2.NazovD : shipment2.NazovO;
        const result = name1.localeCompare(name2);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortNameZA = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }
        const name1 = wasPickUp(shipment1) || shipment1.ExchangingToMe ? shipment1.NazovD : shipment1.NazovO;
        const name2 = wasPickUp(shipment2) || shipment2.ExchangingToMe ? shipment2.NazovD : shipment2.NazovO;
        const result = name2.localeCompare(name1);
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortDeliveryDateOld = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }

        // null date on end of list
        if (shipment1.DatumDoruc !== null && shipment2.DatumDoruc === null) {
            return -1;
        }
        if (shipment1.DatumDoruc === null && shipment2.DatumDoruc !== null) {
            return 1;
        }

        const result = shipment1.DatumDoruc - shipment2.DatumDoruc;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortDeliveryDateNew = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }

        // null date on end of list
        if (shipment1.DatumDoruc !== null && shipment2.DatumDoruc === null) {
            return -1;
        }
        if (shipment1.DatumDoruc === null && shipment2.DatumDoruc !== null) {
            return 1;
        }

        const result = shipment2.DatumDoruc - shipment1.DatumDoruc;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortPickupDateOld = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }

        // null date on end of list
        if (shipment1.DatumPrevz !== null && shipment2.DatumPrevz === null) {
            return -1;
        }
        if (shipment1.DatumPrevz === null && shipment2.DatumPrevz !== null) {
            return 1;
        }

        const result = shipment1.DatumPrevz - shipment2.DatumPrevz;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortPickupDateNew = (shipment1, shipment2) => {
        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }

        // null date on end of list
        if (shipment1.DatumPrevz !== null && shipment2.DatumPrevz === null) {
            return -1;
        }
        if (shipment1.DatumPrevz === null && shipment2.DatumPrevz !== null) {
            return 1;
        }

        const result = shipment2.DatumPrevz - shipment1.DatumPrevz;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortPickupETA = (shipment1, shipment2) => {
/*        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }*/

        if (shipment1.ETAPickUp === null && shipment2.ETAPickUp !== null) {
            return 1;
        }
        if (shipment1.ETAPickUp !== null && shipment2.ETAPickUp === null) {
            return -1;
        }

        const result = shipment1.ETAPickUp - shipment2.ETAPickUp;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortDeliveryETA = (shipment1, shipment2) => {
/*        const preSort = checkPreSortConditions(shipment1, shipment2);
        if (typeof preSort !== "undefined") {
            return preSort;
        }*/

        // null date on end of list
        if (shipment1.ETADelivery !== null && shipment2.ETADelivery === null) {
            return -1;
        }
        if (shipment1.ETADelivery === null && shipment2.ETADelivery !== null) {
            return 1;
        }

        const result = shipment1.ETADelivery - shipment2.ETADelivery;
        return result === 0 ? postSortCondition(shipment1, shipment2) : result;
    };
    const sortRoute = (shipment1, shipment2) => {
        const type1 = shipment1?.ShipmentRoute === null || typeof shipment1?.ShipmentRoute[0]?.order === "undefined";
        const type2 = shipment2?.ShipmentRoute === null || typeof shipment2?.ShipmentRoute[0]?.order === "undefined";
        if (type1 && type2) {
            return sortDateCreateNew(shipment1, shipment2);
        }
        if (type1 && !type2) {
            return 1;
        }
        if (type2 && !type1) {
            return -1;
        }

        return shipment1.ShipmentRoute[0].order < shipment2.ShipmentRoute[0].order ? -1 : 1;
    };

    switch (order) {
        case 'dateCreateOld':
            return sortDateCreateOld;
        case 'dateCreateNew':
            return sortDateCreateNew;
        case 'deliveryTimeNew':
            return sortDeliveryDateNew;
        case 'deliveryTimeOld':
            return sortDeliveryDateOld;
        case 'streetPA':
            return sortStreetPickUpAZ;
        case 'streetPZ':
            return sortStreetPickUpZA;
        case 'streetDA':
            return sortStreetDeliveryAZ;
        case 'streetDZ':
            return sortStreetDeliveryZA;
        case 'namePA':
            return sortNameConsignorAZ;
        case 'namePZ':
            return sortNameConsignorZA;
        case 'nameDA':
            return sortNameDeliveryAZ;
        case 'nameDZ':
            return sortNameDeliveryZA;
        case 'nameA':
            return sortNameAZ;
        case 'nameZ':
            return sortNameZA;
        case 'pickupTimeOld':
            return sortPickupDateOld;
        case 'pickupTimeNew':
            return sortPickupDateNew;
        case 'pickupETAOld':
            return sortPickupETA;
        case 'deliveryETAOld':
            return sortDeliveryETA;
        case 'route':
            return sortRoute;
        default:
            console.info('Invalid sort order');
            break;
    }
};

export const getSortCallbackSingle = (order) => {
    const sortDateCreateOld = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : object1.shipment.DatumObjed - object2.shipment.DatumObjed;
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortDateCreateNew = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : object2.shipment.DatumObjed - object1.shipment.DatumObjed;
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortDateDeliveryOld = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : object1.shipment.DeliveryTime - object2.shipment.DeliveryTime;
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortDateDeliveryNew = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : object2.shipment.DeliveryTime - object1.shipment.DeliveryTime;
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortEtaTime = (object1, object2) => {
        //const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const date1 = object1.type === Types.TO_PICKUP ? object1.shipment.ETAPickUp : object1.shipment.ETADelivery;
        const date2 = object2.type === Types.TO_PICKUP ? object2.shipment.ETAPickUp : object2.shipment.ETADelivery;
        return date1 - date2;
        //const result = typeof exchangingSort !== "undefined" ? exchangingSort : date1 - date2;
        //return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortStreetAZ = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const street1 = object1.type === Types.TO_PICKUP || object1.shipment.ExchangingToMe ? object1.shipment.UlicaO : object1.shipment.UlicaD;
        const street2 = object2.type === Types.TO_PICKUP || object2.shipment.ExchangingToMe ? object2.shipment.UlicaO : object2.shipment.UlicaD;
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : street1.localeCompare(street2);
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortStreetZA = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const street1 = object1.type === Types.TO_PICKUP || object1.shipment.ExchangingToMe ? object1.shipment.UlicaO : object1.shipment.UlicaD;
        const street2 = object2.type === Types.TO_PICKUP || object2.shipment.ExchangingToMe ? object2.shipment.UlicaO : object2.shipment.UlicaD;
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : street2.localeCompare(street1);
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortNameAZ = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const name1 = object1.type === Types.TO_PICKUP || object1.shipment.ExchangingToMe ? object1.shipment.NazovO : object1.shipment.NazovD;
        const name2 = object2.type === Types.TO_PICKUP || object2.shipment.ExchangingToMe ? object2.shipment.NazovO : object2.shipment.NazovD;
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : name1.localeCompare(name2);
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const sortNameZA = (object1, object2) => {
        const exchangingSort = sortCallbackExchangingLast(object1.shipment, object2.shipment);
        const name1 = object1.type === Types.TO_PICKUP || object1.shipment.ExchangingToMe ? object1.shipment.NazovO : object1.shipment.NazovD;
        const name2 = object2.type === Types.TO_PICKUP || object2.shipment.ExchangingToMe ? object2.shipment.NazovO : object2.shipment.NazovD;
        const result = typeof exchangingSort !== "undefined" ? exchangingSort : name2.localeCompare(name1);
        return result === 0 ? postSortCondition(object1, object2) : result;
    };
    const custom = (object1, object2) => {
        const type1 = typeof object1.order === "undefined";
        const type2 = typeof object2.order === "undefined";
        if (type1 && type2) {
            return postSortCondition(object1, object2);
        }
        if (type1 && !type2) {
            return 1;
        }
        if (type2 && !type1) {
            return -1;
        }

        if (object1.order === object2.order) {
            return postSortCondition(object1, object2);
        }

        return object1.order < object2.order ? -1 : 1;
    };

    const sortRoute = (object1, object2) => {
        const type1 = typeof object1.order === "undefined";
        const type2 = typeof object2.order === "undefined";
        if (type1 && type2) {
            return sortDateCreateNew(object1, object2);
        }
        if (type1 && !type2) {
            return 1;
        }
        if (type2 && !type1) {
            return -1;
        }

        return object1.order < object2.order ? -1 : 1;
    };

    switch (order) {
        case 'custom':
            return custom;
        case 'dateCreateOld':
            return sortDateCreateOld;
        case 'dateCreateNew':
            return sortDateCreateNew;
        case 'deliveryTimeNew':
            return sortDateDeliveryNew;
        case 'deliveryTimeOld':
            return sortDateDeliveryOld;
        case 'streetA':
            return sortStreetAZ;
        case 'streetZ':
            return sortStreetZA;
        case 'nameA':
            return sortNameAZ;
        case 'nameZ':
            return sortNameZA;
        case 'etaTimeOld':
            return sortEtaTime;
        case 'route':
            return sortRoute;
        default:
            console.info('Invalid single sort order');
            break;
    }
};

export const isMine = (shipment, userCode) => (
    (shipment.Priradene && shipment.Priradene.Kurier_Kod === userCode)
);

export const wasPickupByMe = (shipment, userCode) => ((shipment.Prevzal && shipment.Prevzal.Kurier_Kod === userCode));
    //|| (shipment.Prevzal && shipment.Prevzal.Kurier_Kod === userCode) || (shipment.Dorucil && shipment.Dorucil.Kurier_Kod === userCode)) //sedma pridal field na priradenie takze netreba hadckovat priradenie podla ukladania kto prevzal;
export const isNotAssign = shipment => shipment.Priradene === null; // && shipment.Prevzal === null  //sedma pridal field na priradenie takze netreba hadckovat priradenie podla ukladania kto prevzal
export const wasDelivered = (shipment, userCode) => wasDelivery(shipment) || (shipment.Priradene && shipment.Priradene.Kurier_Kod !== userCode);

export const updateFilterCount = (client, items, user, filter) => {
    let countAllFilter = 0;
    let countMyDeliveredFilter = 0;
    let countMyToDeliveryFilter = 0;
    let countMyFilter = 0;
    let countNotFilter = 0;

    for (let i = 0; i < items.length; i++) {
        countAllFilter++;

        const wasDeliveredShipment = wasDelivered(items[i], user.kod);
        const wasDeliveryShipment = wasDelivery(items[i]);
        const wasPickupByMeShipment = wasPickupByMe(items[i], user.kod);

        if ((isMine(items[i], user.kod) || wasPickupByMeShipment)) {
            countMyFilter++;

            if (
                !wasDeliveredShipment && isMine(items[i], user.kod) //to delivery
            ) {
                countMyToDeliveryFilter++;
            } else if (
                (wasDeliveredShipment || (!isMine(items[i], user.kod) && wasPickupByMeShipment)) //delivered
            ) {
                countMyDeliveredFilter++;
            }
        } else if (isNotAssign(items[i]) && !wasDeliveryShipment) { // not assign
            countNotFilter++;
        }
    }

    client.writeQuery({
        query:
        gql`{
            counts @client {
                countAllFilter
                countMyDeliveredFilter
                countMyToDeliveryFilter
                countMyFilter
                countNotFilter
            }
        }`,
        data: {
            counts:
                {
                    countAllFilter: countAllFilter,
                    countMyDeliveredFilter: countMyDeliveredFilter,
                    countMyToDeliveryFilter: countMyToDeliveryFilter,
                    countMyFilter: countMyFilter,
                    countNotFilter: countNotFilter,
                    __typename: 'Counts'
                }
        }
    });
};

export const updateSecondFilterCount = (client, items, user) => {
    let countBa = 0;
    let countLocal = 0;
    let countInternational = 0;
    let countCartage = 0;
    let countBaMine = 0;
    let countZS = 0;
    let countLocalMine = 0;
    let countInternationalMine = 0;
    let countCartageMine = 0;
    let countZSMine = 0;

    for (let i = 0; i < items.length; i++) {
        const shipment = items[i];
        const mine = isMine(shipment, user.kod);
        const wasPickupByMeShipment = wasPickupByMe(shipment, user.kod);
        const isZS = isZdravaStrava(shipment.PayerCompany.Firma_Kod);

        if (isZS) {
            countZS++;

            if (mine || wasPickupByMeShipment) {
                countZSMine++;
            }
        }
    }

    client.writeQuery({
        query:
        gql`{
            countsFilterSecond @client {
                ba
                local
                international
                cartage
                baMine
                localMine
                internationalMine
                cartageMine
                zs
                zsMine
            }
        }`,
        data: {
            countsFilterSecond:
                {
                    ba: countBa,
                    local: countLocal,
                    international: countInternational,
                    cartage: countCartage,
                    baMine: countBaMine,
                    localMine: countLocalMine,
                    internationalMine: countInternationalMine,
                    cartageMine: countCartageMine,
                    zs: countZS,
                    zsMine: countZSMine,
                    __typename: 'CountsFilterSecond',
                }
        }
    });
};
