import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {gql, useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {Loading} from "../index";

const FILTER_SECOND = loader('../../gql/query/client/filterSecond.graphql');

const useStyles = makeStyles(theme => ({
    label: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 8,
        paddingRight: 9,
        margin: '2px 2px 3px 2px',
    },
    checkbox: {
        padding: '2px 2px 2px 5px',
        fontSize: 2,
    },
    wrapper: {
        margin: '0 10px',
        display: 'flex',
        justifyContent: 'center',
    },
    mine: {
        color: '#930001',
    },
    labelText: {
        lineHeight: 0.9,
        fontSize: 12,
    },
}));

export default function TopFilter() {
    const classes = useStyles();
    const {data: dataLocal, loading, client} = useQuery(FILTER_SECOND);

    if (loading) return <Loading/>;

    const handleChange = (event) => {

        let newFilter = {
            [event.target.name]: event.target.checked,
        };

        if (event.target.name === "local") {
            newFilter['international'] = event.target.checked;
        }

        client.writeQuery({
            query: gql`{
                filterSecond @client {
                    ba
                    local
                    international
                    cartage
                    zs
                }
            }`,
            data: {
                filterSecond: {
                    ...dataLocal.filterSecond,
                    ...newFilter,
                }
            },
        });
    };

    return (
        <div className={classes.wrapper}>
            <FormControlLabel
                className={classes.label}
                classes={{
                    label: classes.labelText
                }}
                control={
                    <Checkbox
                        size="small"
                        checked={dataLocal?.filterSecond.zs}
                        onChange={handleChange}
                        name="zs"
                        color="secondary"
                        className={classes.checkbox}
                    />
                }
                label={<Fragment>ZS ({dataLocal?.countsFilterSecond.zs}/<span className={classes.mine}>{dataLocal?.countsFilterSecond.zsMine}</span>)</Fragment>}
            />
        </div>
    );
};
