import sound from "./beep-08b.mp3";

const OkSound = () => {
    const snd = new Audio(sound);
    const promise = snd.play();
    promise.catch(error => {
        console.error('disabled autoplay sound');
        console.error(error);
    });
}

export default OkSound;
