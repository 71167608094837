const BACK_URL_KEY = 'backUrl';

const persistActualUrl = () => {
    const url = new URL(window.location.href);
    localStorage.setItem(BACK_URL_KEY, url.pathname);
}

const getLastPersistUrl = () => {
    return localStorage.getItem(BACK_URL_KEY);
}

export {
    persistActualUrl,
    getLastPersistUrl,
};