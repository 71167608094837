import React, {Fragment, useEffect, useState} from 'react';
import {GoogleMap, useJsApiLoader, Marker, Polyline, InfoWindow} from '@react-google-maps/api'
import {loader} from "graphql.macro";
import {makeStyles} from "@material-ui/core/styles";
import Loading from "../../components/loading";
import {useQuery} from "@apollo/client";
import {filterShipment} from "../../utils/shipments";
import {format, parse} from "date-fns";
import {parse as parseString} from "query-string";
import useSupercluster from "use-supercluster";
import {isMine} from "../shipmentsHelper";
import {wasDelivery, wasPickUp, wasPickUpByMe} from "../../utils";
import {navigate, useLocation} from "@gatsbyjs/reach-router";
import Fab from "@material-ui/core/Fab";
import './map.css';
import useLocalStorage from "../../utils/react/hooks/useLocalStorage";
import PointTypeGraphQL from "../../datasource/pointTypeGraphQL";
import BackButton from "../../components/BackButton";
import UpdateLocationModal from "../../components/shippingPoint/updateLocationModal";
import Types from "../../datasource/shipmentPointsGraphQL";
import {wktToGoogleMaps} from "../../utils/map/wktToGoogleMaps";


const containerStyle = {
    width: '100%',
    height: '100%',
    marginTop: -98,
};

const GET_SHIPMENTS = loader('../../gql/query/shipments.graphql');
const LOCAL_ALL = loader('../../gql/query/client/all.graphql');

const DEFAULT_ZOOM = 14;
const position = {
    lat: 48.1486329,
    lng: 17.1128048,
};

const jsApiLoaderOptions = {
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: ["places"],
};

const useStyles = makeStyles(theme => ({
    wrapperMain: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.custom.main.drawerWidth}px)`,
        },
        position: 'fixed',
    },
    infoBox: {
        margin: 3,
        paddingLeft: 10,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 170,
    },
    wrapper: {
        marginTop: -80,
    },
    link: {
        color: 'black',
    },
    wrapperInfoWindow: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const Map = () => {
    const classes = useStyles();
    const location = useLocation();
    const httpParams = parseString(location.search);
    let usingFromRoutePlanner = false;
    if (httpParams && typeof httpParams.planner !== "undefined") {
        usingFromRoutePlanner = true;
    }

    const [state, setState] = useState({
        map: null,
        wasSetMapToCurrentPosition: false,
        currentLocation: null,
    });

    const [openChangeLocationModal, setOpenChangeLocationModal] = useState(false);

    const [mapOptions, setMapOptions] = useLocalStorage('map', {
        zoom: DEFAULT_ZOOM,
        center: position,
        selectedId: null,
        bounds: [
            position.lng - 0.5,
            position.lat - 0.5,
            position.lng + 0.5,
            position.lat + 0.5,
        ],
    });
    const {data: dataLocal, loading: loadingLocal} = useQuery(LOCAL_ALL);
    let from;
    let to;
    if (dataLocal) {
        from = format(parse(dataLocal.filter.fromDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
        to = format(parse(dataLocal.filter.toDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    }

    const {data, refetch} = useQuery(GET_SHIPMENTS, {
        fetchPolicy: "cache-and-network",
        variables: {
            fromDate: from,
            toDate: to,
            notFinishedRoutes: usingFromRoutePlanner,
        },
        //skip: loadingLocal,
        //onError: error => errorMessage('Chyba pri načítavaní: ' + error, enqueueSnackbar, closeSnackbar),
    });

    let filtered = [];
    if (typeof data !== "undefined" && typeof dataLocal !== "undefined") {
        filtered = filterShipment(data.shipments, dataLocal.filter, dataLocal.user);
    }

    const {isLoaded, loadError} = useJsApiLoader(jsApiLoaderOptions);
    let points = [];
    let routePolylines = [];
    for (const shipment of filtered) {
        let isMineVar = isMine(shipment, dataLocal.user.kod);
        let wasPickedUp = wasPickUp(shipment);
        let wasPickedUpByMe = wasPickUpByMe(shipment, dataLocal.user.kod);
        let wasDelivered = wasDelivery(shipment);

        if (shipment.LatitudeD && shipment.LongitudeD && !wasDelivered) {
            const content = shipment.UlicaD + ' ' + shipment.CisloD;
            let order = -1;
            let findRouteD = false;
            if (shipment.Orders.length > 0) {
                const key = shipment.Orders[0].Adresa_Typ === 1 ? 0 : 1;
                if (typeof shipment.Orders[key] !== "undefined") {
                    // add order from dispatcher route
                    //content = '<span class="dispatch-order">(' + shipment.Orders[key].Poradie + ')</span> ' + content;
                    order = shipment.Orders[key].Poradie;
                }
            }

            // add order from courier route
            for (const shipmentRoute of shipment?.ShipmentRoute) {
                if (shipmentRoute.pointType === PointTypeGraphQL.DELIVERY) {
                    order = shipmentRoute.order;
                    findRouteD = true;
                }
            }

            if ((usingFromRoutePlanner && findRouteD) || !usingFromRoutePlanner) {
                points.push({
                    type: "Feature",
                    properties: {
                        cluster: false,
                        shipment: {
                            id: shipment.Zasielka_Kod,
                            idSuffix: 'd',
                            //content: '<b>' + shipment.UlicaD + ' ' + shipment.CisloD + '</b><br/>' + shipment.NazovD,
                            content: content,
                            order,
                            shipment,
                        },
                        showPickup: isMineVar && wasPickedUpByMe && !wasDelivered,
                        showMine: isMineVar && !wasPickedUpByMe,
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            parseFloat(shipment.LongitudeD),
                            parseFloat(shipment.LatitudeD)
                        ]
                    }
                });
            }
        }

        if (shipment.LatitudeO && shipment.LongitudeO && !wasPickedUp && !usingFromRoutePlanner) {
            const content = shipment.UlicaO + ' ' + shipment.CisloO;
            let order = -1;
            if (shipment.Orders.length > 0) {
                const key = shipment.Orders[0].Adresa_Typ === 0 ? 0 : 1;
                if (typeof shipment.Orders[key] !== "undefined") {
                    order = shipment.Orders[key].Poradie;
                }
            }

            points.push({
                type: "Feature",
                properties: {
                    cluster: false,
                    shipment: {
                        id: shipment.Zasielka_Kod,
                        idSuffix: 'p',
                        //content: '<b>' + shipment.UlicaO + ' ' + shipment.CisloO + '</b><br/>' + shipment.NazovO + '<div class="pickup">P</div>',
                        content: content,
                        order,
                    },
                    showPickup: isMineVar && wasPickedUpByMe && !wasDelivered,
                    showMine: isMineVar && !wasPickedUpByMe,
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(shipment.LongitudeO),
                        parseFloat(shipment.LatitudeO)
                    ]
                }
            });
        }

        // draw route
        /*
        for (const shipmentRoute of shipment?.ShipmentRoute) {
            if (shipmentRoute.wkt) {
                routePolylines.push(wktToGoogleMaps(shipmentRoute.wkt));
            }
        }
         */
    }

    // sort points by order for create "air lines"
    points.sort((a, b) => {
        return a.properties.shipment.order - b.properties.shipment.order;
    });

    // draw air lines between points
    routePolylines[0] = [];
    for (const point of points) {
        if (point.properties.shipment.order > 0) {
            routePolylines[0].push({lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0]});
        }
    }


    const updatePosition = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                    setState((prevState) => {
                        const latLng = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        // center map after geolocation
/*                       if (!prevState.wasSetMapToCurrentPosition && prevState.map) {
                            prevState.map.setCenter(latLng);
                        }*/

                        return {
                            ...prevState,
                            currentLocation: latLng,
                            wasSetMapToCurrentPosition: true,
                        }
                    });
                },
                () => {
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 5000,
                });
        }
    }

    const intervalTick = () => {
        updatePosition();
    };

    useEffect(() => {
        let interval = setInterval(intervalTick, 10000)
        return () => {
            clearInterval(interval);
        };
    }, []);

    const {clusters} = useSupercluster({
        points,
        bounds: mapOptions.bounds,
        zoom: mapOptions.zoom,
        options: {
            radius: 65,
            maxZoom: usingFromRoutePlanner ? 1 : 19, // disable clustering for planning map
            minPoints: 3,
        }
    });

    const onZoomChangeHandle = () => {
        if (state.map) {
            setMapOptions({
                ...mapOptions,
                zoom: state.map.getZoom(),
            });
        }
    }

    const onIdleHandle = () => {
        if (state.map) {
            const ne = state.map.getBounds().getNorthEast();
            const sw = state.map.getBounds().getSouthWest();
            const bounds = [
                sw.lng(),
                sw.lat(),
                ne.lng(),
                ne.lat(),
            ];
            const center = {
                lat: state.map.getCenter().lat(),
                lng: state.map.getCenter().lng(),
            };

            if (JSON.stringify(mapOptions.center) !== JSON.stringify(center) && JSON.stringify(mapOptions.bounds) !== JSON.stringify(bounds)) {
                setMapOptions({
                    ...mapOptions,
                    bounds,
                    center,
                });
            }
        }
    }

    const renderMap = () => {
        return <div className={classes.wrapperMain}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                options={{
                    zoomControl: true,
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControlOptions: false,
                    // mapTypeControlOptions: {
                    //     style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    //     position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                    // },
                }}
                onZoomChanged={onZoomChangeHandle}
                onIdle={onIdleHandle}
                zoom={mapOptions.zoom}
                center={mapOptions.center}
                onLoad={map => {
                    setState((prevState) => {
                            return {
                                ...prevState,
                                map,
                            };
                        }
                    );
                    updatePosition();
                }}
            >

                {state.currentLocation && (
                    <Marker
                        position={state.currentLocation}
                        icon={{
                            path: 'M 0,0 0,-20 A 10,10 0 1 1 0,-40 A 10,10 0 1 1 0,-20',
                            //path: 'A 10,10 0 1 1 0,-40 A 10,10 0 1 1 0,-20',
                            fillColor: "#1C73E8",
                            fillOpacity: 0.8,
                            strokeWeight: 2,
                            rotation: 0,
                            scale: 1,
                            //anchor: new google.maps.Point(15, 30),
                        }}
                    />
                )}

                {clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                        cluster: isCluster,
                        point_count: pointCount,
                        shipment,
                        /* eslint-disable */
                        showPickup, // could be used in future
                        showMine
                    } = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${cluster.id}-${cluster.idSuffix}`}
                                position={{lat: latitude, lng: longitude}}
                                icon={'https://unpkg.com/@googlemaps/markerclustererplus@1.0.3/images/m1.png'}
                                label={String(pointCount)}
                            />
                        );
                    }

                    return (
                        <Fragment key={shipment.id + shipment.idSuffix}>
                            <Marker
                                /*key={shipment.id + shipment.idSuffix}*/
                                position={{lat: latitude, lng: longitude}}
                                label={{text: shipment.order.toString(), className: 'marker-label', color: 'white'}}
                                onClick={() => {
                                    if (usingFromRoutePlanner) {
                                        setOpenChangeLocationModal({
                                            shipment,
                                        });
                                    } else {
                                        navigate('/map/' + shipment.id + (shipment.idSuffix === 'd' ? '/delivery' : '/pickup'));
                                    }
                                }}
                            />

                            <InfoWindow
                                position={{lat: latitude, lng: longitude}}
                                options={{
                                    pixelOffset: {
                                        width: 0,
                                        height: -32,
                                    },
                                    disableAutoPan: true,
                                }}
                            >
                                <p className='info-window'>{shipment.content}</p>
                            </InfoWindow>
                        </Fragment>
                    );
                    /*                return (
                                        <InfoWindow
                                            key={shipment.id+shipment.idSuffix}
                                            position={{lat: latitude, lng: longitude}}
                                            options={{maxWidth: 170, disableAutoPan: true,}}
                                            zIndex={mapOptions.selectedId === shipment.id+shipment.idSuffix ? 100000 : undefined}
                                        >
                                            <div className={classes.wrapperInfoWindow} onClick={handleOnClick(shipment.id+shipment.idSuffix)}>
                                                <ShipmentFlags showPickup={showPickup} showMine={showMine}/>
                                                <p className={classes.infoBox} dangerouslySetInnerHTML={{__html: shipment.content}}></p>
                                                <Link to={'/map/'+shipment.id+(shipment.idSuffix === 'd' ? '/delivery' : '/pickup')} className={classes.link}>
                                                    <ArrowForwardIosIcon className={classes.detailIcon}/>
                                                </Link>
                                            </div>
                                        </InfoWindow>
                                    );*/
                })}

                {/*draw route lines*/}
                {routePolylines.map((polyline, i) => <Polyline path={polyline} key={i}/>)}

                {/*<Link to="/">*/}
                    <Fab color="primary" style={{position: 'fixed', top: '4%', right: '6%', zIndex: 100}}>
                        <BackButton/>
                    </Fab>
                {/*</Link>*/}
            </GoogleMap>
            {!!openChangeLocationModal &&
                <UpdateLocationModal
                    shipment={openChangeLocationModal?.shipment.shipment}
                    type={openChangeLocationModal?.shipment.idSuffix === 'd' ? Types.TO_DELIVERY : Types.TO_PICKUP}
                    show={!!openChangeLocationModal}
                    updatePointGeolocationCallback={() => {
                        setOpenChangeLocationModal(false);
                        refetch();
                    }}
                />
            }
        </div>
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded || loadingLocal ? renderMap() : <Loading/>
};

export default Map;
