import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '1.06rem',
    },
}));

export default function Header({children}) {
    const classes = useStyles();

    return (
        <Typography variant="h6" className={classes.root}>
            {children}
        </Typography>
    );
};
