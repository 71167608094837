import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TopFilter from "./shipments/TopFilter";
import DrawerBarWrapper from "./main/DrawerBarWrapper";

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

function TopBar({showIcon, showFilter, handleDrawerToggle, ...props}) {
    const classes = useStyles();

    return (
        <AppBar position="fixed">
            <DrawerBarWrapper>
                <Toolbar>
                    {showIcon &&
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon/>
                        </IconButton>
                    }
                    {props.children}
                </Toolbar>
                {showFilter &&
                    <TopFilter/>
                }
            </DrawerBarWrapper>
        </AppBar>
    );
}

TopBar.defaultProps = {
    showIcon: true,
};

export default TopBar;
