const actions = {
  successDeliveryButtonClick: 'SUCCESS DELIVERY BUTTON CLICK',
  handleAdditionalConditions: 'HANDLE ADDITIONAL CONDITIONS UNIVERSAL',
  handleSelectBox: 'HANDLE SELECT BOX',
  handleSelectSlot: 'HANDLE SELECT SLOT',
  handleChangeDate: 'HANDLE CHANGE DATE',
  handleChangeTime: 'HANDLE CHANGE TIME',
};

export default actions;
