import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useMutation} from '@apollo/client';
import {Loading} from '../../components/index';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {navigate, useMatch} from "@gatsbyjs/reach-router";
import Line from "../../components/shipment/line";
import Button from "@material-ui/core/Button";
import {errorMessage, successMessage} from "../../utils/messages";
import {wasAssign, wasPickUp, wasDelivery, isInBox, waitingToDeliveryToBox} from "../../utils";
import {isMine} from "../shipmentsHelper";
import Divider from "@material-ui/core/Divider";
import {CartPlus} from "mdi-material-ui";


const DISPERSING_DEPOT_MUTATION = loader('../../gql/mutations/returnDispersingShipmentToDepot.graphql');
const MARK_DEPOT_MUTATION = loader('../../gql/mutations/markToDepot.graphql');
const RECEIVE_DEPOT = loader('../../gql/mutations/receiveDepot.graphql');

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    button: {
        marginTop: 8,
        marginBottom: 8,
    },
    divider: {
       height: 2,
    }
}));

export default function DepoTab({shipment, dataLocal}) {
    const classes = useStyles();
    const isScannerUrl = useMatch('/scanner/*');
    const isScannerNoteUrl = useMatch('/scannernote/*');
    const [sendMarkToDepot, {loading: loading2}] = useMutation(MARK_DEPOT_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: _ => {
            successMessage('Pridaný príznak na depo.', enqueueSnackbar, closeSnackbar);
            if (isScannerUrl || isScannerNoteUrl) {
                navigate('/scanner');
            } else {
                navigate('/');
            }
        },
    });

    const [sendMarkToDepotDispersing, {loading: loading3}] = useMutation(DISPERSING_DEPOT_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: _ => {
            successMessage('Vrátenie rozvozovej zásieky na depo.', enqueueSnackbar, closeSnackbar);
            if (isScannerUrl || isScannerNoteUrl) {
                navigate('/scanner');
            } else {
                navigate('/');
            }
        },
    });

    const [receiveDepot, {loading: loading4}] = useMutation(RECEIVE_DEPOT, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: _ => {
            successMessage('Prijate na sklad.', enqueueSnackbar, closeSnackbar);
            if (isScannerUrl || isScannerNoteUrl) {
                navigate('/scanner');
            } else {
                navigate('/');
            }
        },
    });

    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const markDepotHandler = () => {
        sendMarkToDepot(
            {
                variables: {zasielkaKod: shipment.Zasielka_Kod}
            }
        );
    };
    const sendDispersingDepotHandler = () => {
        sendMarkToDepotDispersing(
            {
                variables: {zasielkaKod: shipment.Zasielka_Kod}
            }
        );
    };
    const receiveDepotHandler = () => {
        receiveDepot(
            {
                variables: {shipmentId: shipment.Zasielka_Kod}
            }
        );
    };

    const disabled = !wasAssign(shipment) || !wasPickUp(shipment) || shipment.Exchanging || wasDelivery(shipment) || !isMine(shipment, dataLocal?.user?.kod) || isInBox(shipment) || waitingToDeliveryToBox(shipment);

    return (
        <form className={classes.container} autoComplete="on" noValidate>
            {(loading2 || loading3 || loading4) && <Loading/>}
            <Grid container spacing={0}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Line>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            onClick={markDepotHandler}
                            disabled={disabled || shipment.NaDepo}
                            className={classes.button}
                        >
                            Pridať zásielke príznak 'na depo'
                        </Button>
                    </Line>
                    <Divider variant={"fullWidth"} light={true} className={classes.divider}/>
                    <Line>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            onClick={sendDispersingDepotHandler}
                            disabled={disabled}
                            className={classes.button}
                        >
                            Vrátiť <b>&nbsp;rozvozovú&nbsp;</b> zásielku na depo
                        </Button>
                    </Line>

                    {dataLocal && (
                        <Fragment>
                            <Divider variant={"fullWidth"} light={true} className={classes.divider}/>

                            <Line>
                                <Button
                                    fullWidth={true}
                                    variant="contained"
                                    onClick={receiveDepotHandler}
                                    disabled={waitingToDeliveryToBox(shipment)}
                                    className={classes.button}
                                    startIcon={<CartPlus />}
                                >
                                    Príjem na sklad
                                </Button>
                            </Line>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </form>
    );
}
