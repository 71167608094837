export const removeAllShipments = (cache) => {
    const serializedState = cache.extract();
    // remove all shipments object from cache
    Object.values(serializedState).filter(item => item.__typename === 'Shipment').map(shipment => {
        cache.evict({
            id: cache.identify({
                id: shipment.id,
                __typename: 'Shipment',
                broadcast: true,
            })
        });
    });
    cache.gc({
        resetResultCache: true,
    });
}
