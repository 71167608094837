import {convertMinutesToTime, splitNames} from "../../../../utils";

export const formatShipmentToDeliveryUpdateMutation = (values, shipment, delivered, deliveryCode) => {
    const deliveryName = splitNames(values.nameDelivery);

    let data = {
        zasielkaKod: shipment.Zasielka_Kod,
        dobaCakania: convertMinutesToTime(values.time),
        poznamkaIntern: values.noteIntern,
        poznamkaKlient: values.noteClient,
        menoD: deliveryName[0] || '',
        priezviskoD: deliveryName[1] || '',
        telefonD: values.phoneDelivery,
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '')  && key !== 'menoD' && key !== 'priezviskoD' && delete data[key]);

    return data;
};