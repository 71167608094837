import React from "react";
import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'hidden',
    },
}));

export default function Wrapper({children}) {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            {children}
        </Container>
    );
};
