import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {makeStyles} from "@material-ui/core/styles";
import {Link} from '@gatsbyjs/reach-router';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: '3em',
        color: 'black',
    },
}));

export default function BackButton({path}) {
    const classes = useStyles();

    const handleClick = () => {
        window.history.back();
    }

    if (path) {
        return (<Link to={path}><ArrowBackIcon className={classes.icon} /></Link>);
    }

    return (<Button onClick={handleClick}><ArrowBackIcon className={classes.icon} /></Button>);
}
