import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {getFlagVariant} from "../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        height: 16,
    },
}));

export default function Flag({iso, city, street, streetNumber, depotFlag, className}) {
    const classes = useStyles();

    const variant = getFlagVariant(iso, city, street, streetNumber, depotFlag);

    let src = null;
    if (variant === 'sk') {
        src = '/img/flags/sk.png'
    } else if (variant === 'cz') {
        src = '/img/flags/cz.png'
    } /*else if (variant === 'ba') {
        src = '/img/flags/ba.svg'
    } */ else if (variant === 'eu') {
        src = '/img/flags/eu.png'
    } else if (variant === 'depo') {
        src = '/img/flags/depo.gif'
    } else {
        return null;
    }

    return (
        <img src={src} alt="flag" className={clsx(classes.root, className)}/>
    );
};