import React, {Fragment, useState} from 'react';
import styled from '@emotion/styled';
import {css} from 'emotion'
import {Link, navigate} from '@gatsbyjs/reach-router';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StyledP from '../shipments/styledP';
import PhoneBox from '../shipments/phoneBox';
import Wrapper from '../shipments/wrapper';
import {DotsVertical} from 'mdi-material-ui';
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import Types from "../../datasource/shipmentPointsGraphQL";
import AckButton from "../shipment/ackButton";
import ScrollManager from "../../utils/ScrollManager";
import {isMine} from "../../pages/shipmentsHelper";
import {wasDelivery, wasPickUp} from "../../utils";
import {useQuery} from "@apollo/client";
import {errorMessage} from "../../utils/messages";
import {useSnackbar} from "notistack";
import {loader} from "graphql.macro";
import ShipmentFlags from "../shipments/ShipmentFlags";
import ExchangeWrapper from "../shipments/exchangeWrapper";
import ExchangeText from "../shipments/exchangeText";
import Grid from "@material-ui/core/Grid";
import RejectButton from "../shipment/rejectButton";
import {Input} from "@material-ui/core";
import {format} from "date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddressRow from "./addressRow";
import DeliveryData from "./deliveryData";
import PickUpData from "./pickUpData";

const ABOUT_USER = loader('../../gql/query/client/aboutUser.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    normal: {
        backgroundColor: theme.colors.greenDomca,
    },
    express: {
        backgroundColor: theme.colors.redDomca,
    },
    locale: {
        backgroundColor: theme.colors.blueDomca,
    },
    international: {
        backgroundColor: theme.colors.pinkDomca,
    },
    icon: {
        fontSize: '1em',
    },
    small: {
        fontSize: '0.85em',
        margin: 0,
        textAlign: 'left',
    },
    detailIcon: {
        fontSize: '2.5em',
        marginBottom: '-7px',
    },
    alert: {
        color: theme.colors.redDomca,
    },
    noteAlert: {
        fontSize: '2em',
    },
    phoneIcons: {
        fontSize: '1.2em',
    },
    swipeHeader: {
        width: 50,
    },
    selected: {
        border: '5px solid red',
    },
    disableClick: {
        '&': {
            pointerEvents: 'none'
        },
    },
    movePart: {
        width: 20,
        paddingLeft: 7,
        margin: '14px 9px 14px -8px',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
    },
    main: {
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
    },
    textPart: {
        flexDirection: 'column',
        flexGrow: 2,
    },
    rightPart: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        minWidth: 39,
        justifyContent: 'flex-end',
    },
    dotsIcon: {
        margin: '0 -8px',
    },
    downtimeInput: {
        width: 80,
        height: 19,
        marginRight: 20,
    },
    titleRow: {
        flexDirection: 'row',
        display: 'flex',
    },
    downtimeFormControl: {
        marginLeft: 'auto',
    },
}));


const ShippingPointTitle = ({className, shipment, type, dragHandleProps, multiSelect, isSelected, handleMultiSelectShipment, routeView = false, routeView2 = false, downtime = false, handleChangeDowntime, updatePointGeolocationCallback}) => {
    const id = shipment.Id_Zasielka + String(type);
    const classes = useStyles();
    const [index, setIndex] = useState(1);
    const [downtimeState, setDowntime] = useState(downtime);
    const [isNewDowntimeValueDifferent, setNewDowntimeValueDifferent] = useState(false);
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const {data: dataLocal} = useQuery(ABOUT_USER, {
        onError: error => errorMessage('Chyba pri nacitavani: ' + error, enqueueSnackbar, closeSnackbar),
    });

    const isExpress = shipment.Flags.includes('e');
    let isMineVar = isMine(shipment, dataLocal.user.kod);
    let wasPickedUp = wasPickUp(shipment);
    let wasDelivered = wasDelivery(shipment);

    let url = '';
    if (type  === Types.TO_PICKUP) {
        url = '/shipment/' + shipment.Zasielka_Kod + '/pickup';
    } else if (type  === Types.TO_DELIVERY) {
        url = '/shipment/' + shipment.Zasielka_Kod + '/delivery';
    }

    const handleSwipe = (index) => {
        setIndex(index);
        if (index === 2) {
            navigate(url);
        }
    };

    const onPhoneClick = () => {
        setIndex(1);
    }

    const handleMultiSelectClick = event => {
        event.stopPropagation();
        if (multiSelect && typeof event.target.parentNode.dataset.detailLink === "undefined" && event.isTrusted) {
            handleMultiSelectShipment(shipment.Zasielka_Kod + String(type));
        }
    };

    const handleSetDowntime = (e) => {
        const newVal = e.target.value;
        if (newVal !== downtimeState) {
            setNewDowntimeValueDifferent(true);
            setDowntime(newVal);
        } else {
            setNewDowntimeValueDifferent(false);
        }
    }

    const handleOnBlurDowntime = (e) => {
        if (isNewDowntimeValueDifferent) {
            handleChangeDowntime(shipment.id, type, downtimeState);
            setNewDowntimeValueDifferent(false);
        }
    }

    let additionalInfo = [];
    if (shipment.AreaDelivery) {
        additionalInfo.push('zona: ' + shipment.AreaDelivery.Label);
    }
    shipment.Flags.forEach(flag => {
        additionalInfo.push((
            <span key={flag}>{flag}</span>)
        );
    });
    if (shipment.Dobierka != null && shipment.Dobierka && shipment.Dobierka.Amount) {
        additionalInfo.push((
            <span className={classes.alert}>
                {shipment.Dobierka.Amount}{shipment.Dobierka.Currency && (shipment.Dobierka.Currency)}
            </span>
        ));
    }

    if (shipment.PoznamkaKlient2) {
        additionalInfo.push(shipment.PoznamkaKlient2);
    }

    if (shipment.ETADelivery) {
        additionalInfo.push((<Fragment>ETA {format(shipment.ETADelivery, "HH:mm")}</Fragment>));
    }

    if (shipment.ETAPickUp) {
        additionalInfo.push((<Fragment>ETD {format(shipment.ETAPickUp, "HH:mm")}</Fragment>));
    }

    let exchangePart;
    if (shipment.LastExchange?.To.Kurier_Kod === dataLocal.user.kod && !shipment.LastExchange?.Canceled && !shipment.LastExchange?.Rejected && !shipment.LastExchange?.Approved && !shipment.LastExchange?.Delivered) {
        exchangePart = <ExchangeWrapper><ExchangeText>Kurier {shipment.LastExchange?.From.Kurier_Kod} doručuje zásielku na prehodenie na {shipment.LastExchange.Street} {shipment.LastExchange.StreetNumber}, {shipment.LastExchange.City}</ExchangeText></ExchangeWrapper>;
    }  else if (shipment.LastExchange?.To.Kurier_Kod === dataLocal.user.kod && !shipment.LastExchange?.Canceled && !shipment.LastExchange?.Rejected && !shipment.LastExchange?.Approved && shipment.LastExchange?.Delivered) {
        exchangePart =
            <ExchangeWrapper>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid container item xs={12}>
                        <ExchangeText>Kurier {shipment.ExchangingFrom.Kurier_Kod} prehadzuje zásielku - {shipment.LastExchange.Street} {shipment.LastExchange.StreetNumber}, {shipment.LastExchange.City}</ExchangeText>
                    </Grid>
                    <Grid container item xs={6}>
                        <RejectButton shipmentCode={shipment.Zasielka_Kod}/>
                    </Grid>
                    <Grid container item xs={6}>
                        <AckButton shipmentCode={shipment.Zasielka_Kod}/>
                    </Grid>
                </Grid>
            </ExchangeWrapper>
    }

    return (
        <Fragment>
            <Wrapper className={className} isSelected={multiSelect && isSelected}>
                <ScrollManager scrollKey={id} />
                <div onClick={multiSelect ? handleMultiSelectClick : undefined}>
                    <ShipmentFlags showPickup={isMineVar && wasPickedUp && !wasDelivered} showMine={isMineVar && !wasPickedUp} showExpress={isExpress} />
                    <SwipeableViews
                        onChangeIndex={handleSwipe}
                        enableMouseEvents={true}
                        style={{overflow: 'hidden'}}
                        //slideStyle={{padding: '10px 30px', boxSizing: 'border-box'}}
                        index={index}
                        disabled={routeView || routeView2} // disabled swiping left and right because it catch event from modal map window in update geolocation
                    >
                        <div style={{overflow: 'hidden'}}>
                            <PhoneBox phoneSender={shipment.TelefonO} phoneConsignor={shipment.TelefonD} onPhoneClick={onPhoneClick}/>
                        </div>
                        <div className={classes.main}>
                            {!routeView &&
                                <div {...dragHandleProps} className={classes.movePart}>
                                    <DotsVertical className={classes.dotsIcon}/>
                                    <DotsVertical className={classes.dotsIcon}/>
                                </div>
                            }
                            <div className={classes.textPart}>
                                {type === Types.TO_DELIVERY ? (
                                    <StyledP>{shipment.NazovD}</StyledP>
                                ) : (
                                    <StyledP>{shipment.NazovO}</StyledP>
                                )}

                                <div className={classes.titleRow}>
                                    <AddressRow shipment={shipment} routeView={routeView || routeView2} type={type} updatePointGeolocationCallback={updatePointGeolocationCallback}/>

                                    {routeView2 && (
                                        <FormControl
                                            className={classes.downtimeFormControl}
                                            error={downtimeState === ""}
                                        >
                                            <Input
                                                className={classes.downtimeInput}
                                                id="downtime-input"
                                                type="number"
                                                value={downtimeState}
                                                onChange={handleSetDowntime}
                                                endAdornment={<InputAdornment position="end">min</InputAdornment>}
                                                inputProps={{
                                                    enterKeyHint: "done",
                                                    tabIndex: "-1",
                                                    onBlur: (e) => {
                                                        handleOnBlurDowntime(e);
                                                    },
                                                    onKeyDown: (e) => {
                                                        if (e.keyCode === 13) { // press enter
                                                            e.target.blur();
                                                        }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                </div>

                                <p className={classes.small}>
                                    {type === Types.TO_DELIVERY && 0 in shipment?.ShipmentRoute && (
                                        <b>{shipment.ShipmentRoute[0]?.order}., </b>
                                    )}
                                    {shipment.ShippingTime},
                                    {shipment.ShippingTimeName},
                                    {
                                        additionalInfo.map((info, index) => (
                                            <Fragment key={index}>{info}, </Fragment>
                                        ))
                                    }

                                    {type === Types.TO_DELIVERY && (
                                        <DeliveryData shipment={shipment}/>
                                    )}
                                    {type === Types.TO_PICKUP && (
                                        <PickUpData shipment={shipment}/>
                                    )}
                                </p>
                            </div>

                            {!routeView && !routeView2 &&
                                <div className={classes.rightPart}>
                                    <StyledLink to={url}>
                                        {(shipment.PoznamkaIntern || shipment.PoznamkaKlient) &&
                                        <span className={clsx(classes.alert, classes.noteAlert)}>!</span>}
                                        <ArrowForwardIosIcon className={classes.detailIcon}/>
                                    </StyledLink>
                                </div>
                            }
                            {/*<StyledP className={smaller}>{stav}</StyledP>*/}
                            {/*<StyledP className={smaller}>{shipment.ShippingTimeGroup} {shipment.ShippingTimeName} {shipment.ShippingTime} <span>{shipment.Flags.join(',')}</span></StyledP>*/}
                        </div>
                        <div>
                            <Typography variant="h3" className={classes.swipeHeader}>
                                {type === Types.TO_DELIVERY ? 'Doručiť' : 'Vyzdvihnúť'}
                            </Typography>
                        </div>
                    </SwipeableViews>
                </div>
            </Wrapper>
            {exchangePart}
        </Fragment>
    );
};

export const cardClassName = css({
    borderRadius: 7,
    color: 'black',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

export const smaller = css({
    fontSize: 10,
});

const StyledLink = styled(Link)(cardClassName, {
    '&:hover': {
        color: 'grey',
    },
    /*    display: 'inline-block',*/
    color: 'black',
    zIndex: '1',
    padding: '1em',
    margin: '-1em',
    textDecoration: 'none',
    /*    position: 'absolute',
        top: 22,
        right: 16,*/
});

export default ShippingPointTitle;
