import {makeStyles} from '@material-ui/core/styles';
import React from "react";


const useStyles = makeStyles(theme => ({
    root: {
        margin: '3px 3px',
    }
}));

export default function Value(props) {
    const classes = useStyles();

    return (
        <div
            className={classes.root}
        >
            {props.children}
        </div>
    );
};