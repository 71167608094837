import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    marks: {
        position: 'absolute',
        width: 10,
/*        height: '75%',
        top: '10%',*/
        height: '100%',
        top: 0,
    },
    leftMark: {
        left: 0,
        borderRadius: '0 5px 5px 0',
    },
    rightMark: {
        right: 0,
        borderRadius: '5px 0 0 5px',
    },
    mineFlag: {
        backgroundColor: 'orange',
    },
    pickUpFlag: {
        backgroundColor: theme.colors.blueDomca,
    },
    expressFlag: {
        backgroundColor: theme.colors.redDomca,
    },
}));

export default function ShipmentFlags({showMine, showPickup, showExpress}) {
    const classes = useStyles();

    return (
        <Fragment>
            {(showMine || showPickup) && (
                <div
                    className={clsx(classes.marks, classes.leftMark, showPickup && classes.pickUpFlag, showMine && classes.mineFlag)}/>
            )}
            {showExpress && (
                <div
                    className={clsx(classes.marks, classes.rightMark, classes.expressFlag)}/>
            )}
        </Fragment>
    );
};