import React, {Fragment, useReducer} from 'react';
import Grid from '@material-ui/core/Grid';
import {useMutation, useQuery} from '@apollo/client';
import {Loading} from '../../../components';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Line from "../../../components/shipment/line";
import {errorMessage, successMessage} from "../../../utils/messages";
import clsx from "clsx";
import {navigate} from "@gatsbyjs/reach-router";
import copy from "copy-to-clipboard";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {checkAdditionalConditions} from "../utils";
import {getDefaultDeliveryCondition, getInitDeliveryState} from "../delivery/utils";
import DeliveryCheck from "../common/deliveryCheck";
import {useGeolocated} from "react-geolocated";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from "@material-ui/core/Checkbox";
import ShowBox from "../../../components/deliveryBBtab/showBox";
import {format, parseISO} from "date-fns";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import skLocale from "date-fns/locale/sk";
import deliveryBBReducer from "./reducer";
import actions from "./actions";

const GET_SHIPMENT_DETAILS = loader('../../../gql/query/shipmentDetail.graphql');
const DELIVERY = loader('../../../gql/mutations/deliveryShipment.graphql');
const BOX_RESERVATION = loader('../../../gql/mutations/createBoxReservation.graphql');
const CANCEL_RESERVATION = loader('../../../gql/mutations/cancelBoxReservation.graphql');
const BOXES = loader('../../../gql/query/boxes.graphql');

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    buttonSuccess: {
        color: theme.colors.greenDomca,
        paddingBottom: 0,
        display: 'inline-block',
    },
    icons: {
        fontSize: '1.5em',
    },
    iconGreen: {
        fill: theme.colors.greenDomca,
    },
    buttonLabel: {
        fontSize: '0.6em',
        flexBasis: '100%',
    },
    iconButtonLabel: {
        flexWrap: 'wrap',
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.015)',
        },
    },
}));

const getDefaultReservationDate = () => {
    return new Date(new Date().getTime()+(3*60*1000));
}

export default function DeliveryBBTab({shipment, dataLocal, callCallbackForSend}) {
    const [state, dispatch] = useReducer(deliveryBBReducer, undefined, () => {
        return {
            values: getInitDeliveryState(shipment, dataLocal?.user?.kod),
            additionalConditions: getDefaultDeliveryCondition(shipment),
            selectedBox: null,
            selectedSlot: null,
            reservationDate: getDefaultReservationDate(),
        };
    });

    const {coords, isGeolocationAvailable, isGeolocationEnabled} =
        useGeolocated({
            positionOptions: {
                maximumAge: 45000,
                enableHighAccuracy: true,
                timeout: 2000,
            },
            //userDecisionTimeout: 5000,
        });

    const {data: dataBoxes, loading: loadingBoxes, error} = useQuery(
        BOXES,
        {
            variables: {
                location: {
                    latitude: coords?.latitude,
                    longitude: coords?.longitude,
                },
                limit: 140,
            },
            skip: !coords,
            fetchPolicy: 'cache-and-network',
        }
    );

    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [sendDelivery, {loading}] = useMutation(DELIVERY, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Uspešne uložené.', enqueueSnackbar, closeSnackbar);
            navigate('/');
        },
    });

    const [createBoxReservation, {loading: loading2}] = useMutation(BOX_RESERVATION, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Rezervácia uspešne vytvorená', enqueueSnackbar, closeSnackbar);
            //navigate('/');
        },
        //refetchQueries: () => ['shipmentList'],
        refetchQueries: () => [{
            query: GET_SHIPMENT_DETAILS,
            variables: {
                zasielka_kod: shipment.Zasielka_Kod,
            },
        }],
    });


    const [cancelReservation, {loading: loading3}] = useMutation(CANCEL_RESERVATION, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Uspešne zrušené.', enqueueSnackbar, closeSnackbar);
            // todo refresh shipment
            //navigate('/');
        },
        refetchQueries: () => [{
            query: GET_SHIPMENT_DETAILS,
            variables: {
                zasielka_kod: shipment.Zasielka_Kod,
            },
        }],
    });

    const handleClickSlotTable = (event, slot) => {
        dispatch({
            type: actions.handleSelectSlot,
            payload: slot,
        });
    };

    const handleChangeDate = (date) => {
        dispatch({
            type: actions.handleChangeDate,
            payload: date,
        });
    };

    const handleChangeTime = (date) => {
        date.setSeconds(59);
        if (date < new Date) {
            date = getDefaultReservationDate();
        }
        dispatch({
            type: actions.handleChangeTime,
            payload: date,
        });
    };

    const handleSubmit = () => {
        let data = null;
        if (state.selectedBox && state.selectedBox.company.code === 'AB') {
            data = {
                shipmentId: shipment.Zasielka_Kod,
                boxId: parseInt(state.selectedBox.id),
                slotId: state.selectedSlot ? parseInt(state.selectedSlot.id) : null,
                reservationDate: state.reservationDate,
            };
        } else {
            data = {
                delivered: true,
                deliveryCode: 'PBL',
                zasielkaKod: shipment.Zasielka_Kod,
            };
        }

        if (typeof callCallbackForSend === 'function') {
            callCallbackForSend(data);
            return;
        }

        if (state.selectedBox && state.selectedBox.company.code === 'AB') {
            createBoxReservation({
                variables: data,
            });
        } else {
            sendDelivery({
                variables: data,
            });
        }
    }

    const handleCancelReservation = () => {
        cancelReservation({
            variables: {
                shipmentId: shipment.Zasielka_Kod,
            },
        });
    };

    const isSendButtonDisabled = state.values.disabled || !checkAdditionalConditions(state.additionalConditions) || state.selectedBox === null;

    let phoneNumberClipboard = shipment?.TelefonD || '';
    if (phoneNumberClipboard.slice(0, 4) === '+421') {
        phoneNumberClipboard = phoneNumberClipboard.slice(4, phoneNumberClipboard.length);
    }

    // manual add Direct4me box -> using with his own app
    let boxes = null;
    if (dataBoxes?.boxes) {
        boxes = [...dataBoxes.boxes];
        boxes.push({
            name: 'Direct4me',
            company: {
                code: 'D4ME',
            },
            available: true,
            distance: '-',
        });
    }

    let deliveryButtonText = 'Doručiť do boxu';
    if (state.selectedBox && state.selectedBox.company.code === 'AB') {
        deliveryButtonText = 'Vytvoriť rezerváciu';
    }

    return (
        <form className={classes.container} autoComplete="on" noValidate>
            {(loading || loading2 || loading3) && <Loading/>}
            {!isGeolocationEnabled && <p>Povol geolokaciu v tvojom prehliadaci.</p>}

            <Grid container spacing={0}>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center'}}>
                    <IconButton
                        onClick={handleSubmit}
                        disabled={isSendButtonDisabled}
                        classes={{
                            root: classes.buttonSuccess,
                            label: classes.iconButtonLabel,
                        }}
                    >
                        <MailOutlineIcon className={clsx(classes.icons, !isSendButtonDisabled && classes.iconGreen)}/>
                        <div className={classes.buttonLabel}>{deliveryButtonText}</div>
                    </IconButton>
                </Grid>


                {shipment.LastActiveCreateReservation && !shipment.LastActiveCreateReservation.deliveredToBox && (
                    <Fragment>
                        <p>Na tuto zásielku je aktívna rezervácia
                            od {format(parseISO(shipment.LastActiveCreateReservation.reservationDate), 'dd.MM.yyyy HH:mm:ss')} do
                            boxu {shipment.LastActiveCreateReservation.box.name}</p>
                        <p>Doručte zásielku do boxu. Na boxe klikni na vlajku na zmenu jazyka a následne na kúsok tlačidla "Potvrdiť". Vyberte GO4 a oskenujte QR kód.</p>

                        <ShowBox box={shipment.LastActiveCreateReservation.box} showPin={true} />

                        <Line>
                            <Button
                                onClick={handleCancelReservation}
                                variant="contained"
                                //startIcon={<FileCopyOutlinedIcon/>}
                                //className={classes.copyButton}
                            >
                                Zrušiť rezerváciu v boxe
                            </Button>
                        </Line>
                    </Fragment>
                )}

                {shipment.LastActiveCreateReservation && shipment.LastActiveCreateReservation.deliveredToBox && (
                    <Fragment>
                        <p>Zasielka bola dorucena do boxu {shipment.LastActiveCreateReservation.box.name} a caka na vyzdvihnutie.</p>
                    </Fragment>
                )}

                {!shipment.LastActiveCreateReservation && (
                    <Fragment>
                        <DeliveryCheck shipment={shipment} dispatch={dispatch} state={state} isInDeliveryBox={true}/>

                        {boxes && (
                            <Autocomplete
                                id="disabled-options-demo"
                                value={state.selectedBox}
                                onChange={(event, newValue) => {
                                    dispatch({
                                        type: actions.handleSelectBox,
                                        payload: newValue,
                                    });
                                }}
                                options={boxes}
                                getOptionLabel={(option) => option.name + ' - ' + option.distance + 'km'}
                                getOptionDisabled={(option) => !option.available}
                                style={{width: '100%'}}
                                renderInput={(params) => (
                                    <TextField {...params} label="BalikoBox" variant="outlined"/>
                                )}
                            />
                        )}

                        {state.selectedBox && state.selectedBox.company.code === 'AB' && (
                            <Grid container spacing={2}>

                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={skLocale}>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            disableToolbar
                                            format="dd.MM.yyyy"
                                            margin="normal"
                                            id="date-picker-to"
                                            label="Rezervácia boxu od"
                                            okLabel="Potvrdiť"
                                            todayLabel="Dnes"
                                            cancelLabel="Zrušiť"
                                            showTodayButton
                                            disablePast
                                            value={state.reservationDate}
                                            onChange={handleChangeDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TimePicker
                                            ampm={false}
                                            disablePast
                                            id="time-picker"
                                            mask="__:__"
                                            label="Rezervácia boxu od"
                                            value={state.reservationDate}
                                            onChange={handleChangeTime}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <ShowBox box={state.selectedBox} showPin={false}>
                                    <Line>
                                        <span>Sloty:</span><br/>
                                        <small style={{color: 'red'}}>Pri väčšom balíku výberte veľkosť boxu, aby
                                            bola garancia, že bude voľný.</small>
                                        <TableContainer component={Paper} style={{ maxWidth: '600px' }}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            Vybratý slot
                                                        </TableCell>
                                                        <TableCell align="right">Počet volných</TableCell>
                                                        <TableCell align="right">Výška</TableCell>
                                                        <TableCell align="right">Šírka</TableCell>
                                                        <TableCell align="right">Hĺbka</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.selectedBox.slots.map((row) => {
                                                        const isItemSelected = row.id === state.selectedSlot?.id;

                                                        return (
                                                            <TableRow
                                                                key={row.id}
                                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                                hover
                                                                onClick={(event) => handleClickSlotTable(event, row)}
                                                                role="checkbox"
                                                                selected={isItemSelected}
                                                                className={classes.tableRow}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        checked={isItemSelected}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">{row.countFree}</TableCell>
                                                                <TableCell align="right">{row.height}cm</TableCell>
                                                                <TableCell align="right">{row.width}cm</TableCell>
                                                                <TableCell align="right">{row.length}cm</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Line>
                                </ShowBox>
                            </Grid>
                        )}

                        {state.selectedBox && state.selectedBox.company.code === 'D4ME' && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Line>
                                        <span>Meno odosielateľa: <b>GO4</b></span>
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <Button
                                            onClick={() => copy('GO4')}
                                            variant="contained"
                                            startIcon={<FileCopyOutlinedIcon/>}
                                            className={classes.copyButton}
                                        >
                                            Kopírovať
                                        </Button>
                                    </Line>
                                </Grid>

                                <Grid item xs={12}>
                                    <Line>
                                        <span>kod zasielky: <b>{shipment.Zasielka_Kod}</b></span>
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <Button
                                            onClick={() => copy(shipment.Zasielka_Kod)}
                                            variant="contained"
                                            startIcon={<FileCopyOutlinedIcon/>}
                                            className={classes.copyButton}
                                        >
                                            Kopírovať
                                        </Button>
                                    </Line>
                                </Grid>

                                {shipment.TelefonD && shipment.TelefonD.length > 0 && (
                                    <Grid item xs={12}>
                                        <Line>
                                            <span>telefon: <b>{phoneNumberClipboard}</b></span>
                                            <span>&nbsp;&nbsp;&nbsp;</span>
                                            <Button
                                                onClick={() => copy(phoneNumberClipboard)}
                                                variant="contained"
                                                startIcon={<FileCopyOutlinedIcon/>}
                                                className={classes.copyButton}
                                            >
                                                Kopírovať
                                            </Button>
                                        </Line>
                                    </Grid>
                                )}
                            </Fragment>
                        )}


                    </Fragment>
                )}

            </Grid>
        </form>
    );
}
