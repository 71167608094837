import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import MyPaper from "./paper";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    conditionBlock: {
        backgroundColor: theme.colors.redDomca,
        color: theme.colors.white + ' !important',
    },
    valid: {
        backgroundColor: theme.colors.greenDomca,
    },
}));

export default function ConditionWrapper({children, valid}) {
    const classes = useStyles();

    return (
        <MyPaper className={clsx(classes.conditionBlock, valid && classes.valid)}>
            {children}
        </MyPaper>
    );
};