import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import PickUp from '../../components/icons/pickUp';
import Delivery from '../../components/icons/delivery';
import Reject from '../../components/icons/reject';
import Depo from '../../components/icons/depo';
import { InformationOutline } from 'mdi-material-ui'
import DeliveryTabWrapper from './tabPanels/DeliveryTabWrapper';
import DeliveryBBTabWrapper from './tabPanels/DeliveryBBTabWrapper';
import DepoTabWrapper from './tabPanels/DepoTabWrapper';
import ExchangeTabWrapper from './tabPanels/ExchangeTabWrapper';
import InfoTabWrapper from './tabPanels/InfoTabWrapper';
import PickUpTabWrapper from './tabPanels/PickUpTabWrapper';
import RejectTabWrapper from './tabPanels/RejectTabWrapper';
import { Link, Router } from "@gatsbyjs/reach-router";
import {withStyles} from "@material-ui/core";
import DeliveryBB from "../../components/icons/deliveryBB";


const useStyles = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        width: '100%',
        //backgroundColor: theme.palette.background.paper,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 10,
    },
    appBar: {
        marginLeft: theme.custom.main.drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.custom.main.drawerWidth}px)`,
        },
    },
}));

const StyledTab = withStyles((theme) => ({
    root: {
        '&$selected': {
            color: 'black',
            fontWeight: theme.typography.fontWeightBold,
            transform: 'scale(1.3)',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function ShipmentCore({data, dataLocal}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const NavLink = React.forwardRef((props, ref) => (
        <Link
            {...props}
            getProps={({ isPartiallyCurrent }) => {
                if (isPartiallyCurrent) {
                    handleChange(null, props.index);
                }
            }}
        />
    ));

    return (
        <div>

            <div className={classes.root}>
                <Router>
                    <PickUpTabWrapper path="/pickup" data={data} dataLocal={dataLocal} value={value} />
                    <DeliveryBBTabWrapper path="/deliveryBB" data={data} dataLocal={dataLocal} value={value} />
                    <DeliveryTabWrapper path="/delivery" data={data} dataLocal={dataLocal} value={value} />
                    <ExchangeTabWrapper path="/exchange" data={data} dataLocal={dataLocal} value={value} />
                    <RejectTabWrapper path="/reject" data={data} value={value} />
                    <DepoTabWrapper path="/depo" data={data} dataLocal={dataLocal} value={value} />
                    <InfoTabWrapper path="/" data={data} dataLocal={dataLocal} value={value} />
                </Router>
            </div>

            <div className={classes.toolbar}/>
            <Paper className={classes.stickToBottom}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    TabIndicatorProps={{
                        style: {background: "black"}
                    }}
                >
                    <StyledTab label="Info" index={0} icon={<InformationOutline />} component={NavLink} to="./" />
                    <StyledTab label="Vyzdvihnutie" index={1} icon={<PickUp />} component={NavLink} to="./pickup" />
                    <StyledTab label="Dorucenie" index={2}  icon={<Delivery />} component={NavLink} to="./delivery" />
                    <StyledTab label="Dorucenie BB" index={3}  icon={<DeliveryBB />} component={NavLink} to="./deliveryBB" />
                    <StyledTab label="Depo" index={4}  icon={<Depo />} component={NavLink} to="./depo" />
                    <StyledTab label="Odmietnutie" index={6}  icon={<Reject />} component={NavLink} to="./reject" />
                </Tabs>
            </Paper>
        </div>
    );
}
