import sound from "./beep-06.mp3";

const DuplicitySound = () => {
    const snd = new Audio(sound);
    const promise = snd.play();
    promise.catch(error => {
        console.error('disabled autoplay sound');
        console.error(error);
    });
}

export default DuplicitySound;
