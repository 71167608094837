import React from 'react';
import Button from '@material-ui/core/Button';
import {navigate} from "@gatsbyjs/reach-router";

export default function RoutePlanningButton() {
    return (
        <Button
            variant="contained"
            fullWidth
            onClick={() => {
                navigate('/route/step1');
            }}
        >
            Naplánovať trasu
        </Button>
    );
}
