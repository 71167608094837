import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Line from "./line";


const useStyles = makeStyles(theme => ({
    label: {
        color: theme.colors.white + ' !important',
    },
}));

export default function RadioBlock({description, name, values, onChange, value}) {
    const classes = useStyles();

    const handleChange = event => {
        onChange(event.target.value);
    };

    return (
        <Line>
            <FormControl component="fieldset">
                <FormLabel className={classes.label} component="legend">{description}</FormLabel>
                <RadioGroup aria-label="gender" name={name} value={value} onChange={handleChange}>
                    {Object.entries(values).map(([key, value]) => {
                        return (
                            <FormControlLabel key={key} value={key} control={<Radio />} label={value} />
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </Line>
    );
};