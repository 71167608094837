import React from "react";
import {loader} from "graphql.macro";
import {useQuery} from "@apollo/client";
import Button from "@material-ui/core/Button";

const GET_FILTER = loader('../../gql/query/client/filter.graphql');

export default function UnselectAll({children, className}) {
    const {data, client} = useQuery(GET_FILTER);

    const handleUnselectAll = () => {
        client.writeQuery({
            query: GET_FILTER,
            data: {
                filter: {
                    ...data.filter,
                    multiSelectedShipments: [],
                    __typename: 'Filter',
                }
            },
        });
    }

    return (
        <Button
            onClick={handleUnselectAll}
            variant="contained"
            size="small"
            className={className}
        >
            {children}
        </Button>
    );
};
