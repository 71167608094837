import {useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {loader} from "graphql.macro";
import useLocalStorage from "../utils/react/hooks/useLocalStorage";

const ALL = loader('../gql/query/client/all.graphql');
const userSettingsKey = 'us';

function PersistSettings() {
    const {data} = useQuery(ALL);

    const [,setSettings] = useLocalStorage(
        userSettingsKey,
        0
    );

    useEffect(() => {
        setSettings(data);
    }, [data]);

    return null;
}

export {
    PersistSettings,
    userSettingsKey,
};
