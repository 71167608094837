import {InMemoryCache} from '@apollo/client/cache';
import {persistCache} from 'apollo3-cache-persist';
import {ApolloLink, HttpLink} from "@apollo/client";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import localForage from "localforage";
import {loader} from "graphql.macro";
import {getInitFromDate, getInitToDate, removeAccents, waitingToDeliveryToBox, wasPickUp} from "./utils";
import {format, parseISO} from "date-fns";
import packageJson from '../package.json';
import {groupedVar} from "./utils/apollo/reactiveVar/grouped";
import {userSettingsKey} from "./components/PersistSettings";
import {getShipmentCodeForReturnSubShipmentFromBox} from "./pages/shipment/utils";


const stringToDateObject = (existing, fieldFunctionOptions) => {
    if (existing && typeof existing === 'string') {
        return parseISO(existing);
    }

    return existing;
};

export const cache = new InMemoryCache({
    typePolicies: {
        Shipment: {
            merge: true,
            fields: { // Field policy map for the Product type
                search: { // Field policy for the isInCart field
                    read(_, fieldFunctionOptions) {
                        const searchFields = ['Zasielka_Kod', 'NazovO', 'MenoO', 'PriezviskoO', 'UlicaO', 'NazovD', 'MenoD', 'PriezviskoD', 'UlicaD', 'ObecD', 'ObecO', 'customerIdentification'];
                        let search = '';

                        searchFields.forEach(function (field) {
                            const value = fieldFunctionOptions.readField(field);
                            if (typeof value !== 'undefined' && value !== null && value !== '') {
                                search += removeAccents(value.toLowerCase() + ',');
                            }
                        });

                        return search;
                    }
                },
                key: {
                    read(_, fieldFunctionOptions) {
                        if (fieldFunctionOptions.readField('NaDepo')) {
                            return 'depo';
                        }

                        let shipment = {};
                        shipment['ZStav_popis'] = fieldFunctionOptions.readField('ZStav_popis');
                        shipment['ZStav'] = fieldFunctionOptions.readField('ZStav');
                        if (waitingToDeliveryToBox(shipment)) {
                            const boxReservationRef = fieldFunctionOptions.readField('LastActiveCreateReservation');
                            const boxRef = fieldFunctionOptions.readField('box', boxReservationRef);
                            const boxId = fieldFunctionOptions.readField('id', boxRef);

                            if (boxId) {
                                return 'BOX' + boxId.toString();
                            }
                        }

                        const customerIdentification = fieldFunctionOptions.readField('customerIdentification');
                        const mainShipmentId = getShipmentCodeForReturnSubShipmentFromBox(customerIdentification);
                        if (mainShipmentId) {
                            return mainShipmentId;
                        }

                        if (shipment['ZStav']?.ZStav_Kod === 'IN' && shipment['ZStav_popis']?.ZStav_Popis_Kod === 'PBM' ) { // expired in box waiting to pick up to depo
                            return fieldFunctionOptions.readField('Zasielka_Kod');
                        }

                        let key = '';
                        let keyFields = [];

                        shipment['Prevzal'] = fieldFunctionOptions.readField('Prevzal');
                        shipment['DatumPrevz'] = fieldFunctionOptions.readField('DatumPrevz');

                        const wasPickedUp = wasPickUp(shipment);
                        if (!wasPickedUp) {
                            keyFields = ['UlicaO', 'CisloO', 'ObecO', 'NazovO'];
                        } else {
                            keyFields = ['UlicaD', 'CisloD', 'ObecD', 'NazovD'];
                        }

                        keyFields.forEach(function (field) {
                            const value = fieldFunctionOptions.readField(field);
                            if (typeof value !== "undefined" && value !== null && value !== '') {
                                key += removeAccents(value.toLowerCase() + ',');
                            }
                        });

                        key += wasPickedUp;

                        return key;
                    }
                },
                grouped: {
                    read(_, fieldFunctionOptions) {
                        const grouped = groupedVar();
                        const id = fieldFunctionOptions.readField('id');
                        if (grouped[id] && grouped[id].length > 0) {
                            return grouped[id];
                        }
                        return null;
                    }
                },
                DeliveryTime: {
                    read: stringToDateObject,
                },
                DatumObjed: {
                    read: stringToDateObject,
                },
                DatumPrevz: {
                    read: stringToDateObject,
                },
                DatumDoruc: {
                    read: stringToDateObject,
                },
                ETAPickUp: {
                    read: stringToDateObject,
                },
                ETADelivery: {
                    read: stringToDateObject,
                },
                DeliveryTimeFrom: {
                    read: stringToDateObject,
                },
                DeliveryTimeTo: {
                    read: stringToDateObject,
                },
            },
        },
        ZStav: {
            merge: true,
        },
        ZStav_popis_type: {
            merge: true,
        },
        ZStav_zasielka: {
            merge: true,
        },
        Courier: {
            merge: true,
        },
        COD: {
            merge: true,
        },
        Exchange: {
            merge: true,
        },
        Company: {
            merge: true,
        },
        ContactPerson: {
            merge: true,
        },
        DateTime: {},
        Settings: {
            merge: true,
        },
    }
});

export const createCache = async () => {
    await persistCache({
        cache,
        storage: localForage,
        //storage: localStorage,
        debug: process.env.REACT_APP_PRODUCTION !== 'TRUE',
    });

    return cache;
};

export const initCache = (client, force = false) => {
    const initData = {
        token: null,
        isLoggedIn: false,
        loading: false,
        user: {
            uid: 0,
            kod: 0,
            isAdmin: false,
            isDispatcher: false,
            isStockIn: false,
            vehicleType: null,
            __typename: 'User',
        },
        settings: {
            smsPickup: 'UNIVER',
            smsDelivery: 'UNIVER',
            fontSize: 'normal',
            __typename: 'Settings',
        },
        filter: {
            isFromChanged: false,
            dateChangeDate: format(new Date(), 'dd.MM.yyyy'),
            fromDate: getInitFromDate(),
            toDate: getInitToDate(),
            shipingFilter: 'myToDelivery',
            search: '',
            order: 'deliveryETAOld',
            orderSingle: 'etaTimeOld',
            group: false,
            multiSelect: false,
            multiSelectedShipments: [],
            view: false,
            __typename: 'Filter',
        },
        filterSecond: {
            ba: true,
            local: true,
            international: true,
            cartage: true,
            zs: true,
            __typename: 'FilterSecond',
        },
        countsFilterSecond: {
            ba: 0,
            local: 0,
            international: 0,
            cartage: 0,
            zs: 0,
            baMine: 0,
            localMine: 0,
            internationalMine: 0,
            cartageMine: 0,
            zsMine: 0,
            __typename: 'CountsFilterSecond',
        },
        counts: {
            countAllFilter: 0,
            countMyDeliveredFilter: 0,
            countMyToDeliveryFilter: 0,
            countMyFilter: 0,
            countNotFilter: 0,
            __typename: 'Counts',
        }
    };
    const userSettings = localStorage.getItem(userSettingsKey);
    const loadedData = (localStorage.hasOwnProperty(userSettingsKey) && userSettings !== "undefined" && !force) ? JSON.parse(localStorage.getItem(userSettingsKey)) : undefined;

    client.writeQuery({
        query: loader('./gql/query/client/all.graphql'),
        data: loadedData ?? initData,
    });
};

export const authLink = new ApolloLink((operation, forward) => {
    const ABOUT_USER = loader('./gql/query/client/aboutUser.graphql');
    const data = cache.readQuery({
        query: ABOUT_USER,
    });
    if (data && data.token) {
        operation.setContext(({headers = {}}) => ({
            headers: {
                ...headers,
                authorization: data.token ? `Bearer ${data.token}` : "",
            }
        }));
    }

    return forward(operation);
});

export const batchHttpLink = new BatchHttpLink({
    uri: process.env.REACT_APP_SERVER,
    headers: {
        'client-name': 'Kurier web client',
        'client-version': packageJson.version,
        batch: "true ",
    }
});

export const httpLink = new HttpLink({
    uri: process.env.REACT_APP_SERVER,
    headers: {
        'client-name': 'Kurier web client',
        'client-version': packageJson.version,
    },
});
