import {parseTime} from "../../../../utils";
import {isZdravaStrava} from "../../../../utils/shipments";
import {isDisabled} from "./isDisabled";

export const getInitDeliveryState = (shipment, userCode) => {
    return {
        time: shipment.DobaCakania2 === null || shipment.DobaCakania2 === '00:00:00' ? "0" : parseTime(shipment.DobaCakania2).toString(),
        nameDelivery: shipment.KontaktDoruc,
        phoneDelivery: shipment.TelefonD,
        noteIntern: shipment.PoznamkaIntern,
        noteClient: shipment.PoznamkaKlient,
        wasClientNoteEdited: false,
        noteClientBeforeEdit: null,
        disabled: isDisabled(shipment, userCode),
        deliveryCode: 'DGC',
        delivered: shipment.ZStav && (shipment.ZStav.ZStav_Kod === 'D' || shipment.ZStav.ZStav_Kod === 'UD' ) ? shipment.ZStav.ZStav_Kod === 'D' : true,
        CODPaymentType: null,
        birthDate: null,
        pinCode: '',
        pinRequired: shipment.PinRequired,
        additionalInfo: {},
        additionalInfo2: '',
        isZS: isZdravaStrava(shipment.PayerCompany.Firma_Kod),
    };
};
