import React, {useState} from 'react';
import {Card, CardContent, CardHeader, TextField, Button, Grid, Typography} from '@material-ui/core';
import {format, parseISO} from "date-fns";
import {useMutation} from "@apollo/client";
import {errorMessage, successMessage} from "../../utils/messages";
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {navigate} from "@gatsbyjs/reach-router";

const UPDATE_SHIFT = loader('../../gql/mutations/updateShift.graphql');

const ShiftDetail = ({shift, onSubmit, onCancel}) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [formValues, setFormValues] = useState({
        hour_count_courier: shift?.hour_count_courier || '',
        km_count_courier: shift?.km_count_courier || '',
        stop_count_courier: shift?.stop_count_courier || '',
        comment: shift?.comment || ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [sendShift, {loading}] = useMutation(UPDATE_SHIFT, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Smena uspešne uložené.', enqueueSnackbar, closeSnackbar);
            onSubmit(formValues);
            navigate('/shifts');
        },
    });

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);

        sendShift({
            variables: {
                id: Number(shift.id),
                hour_count_courier: Number(formValues.hour_count_courier) || null,
                km_count_courier: Number(formValues.km_count_courier) || null,
                stop_count_courier: Number(formValues.stop_count_courier) || null,
                comment: formValues.comment === '' ? null : formValues.comment,
            },
        });
    };

    return (<Card className="shift-detail">
            {shift && (<CardHeader
                    title={<b>{shift.name}</b>}
                    subheader={`${format(parseISO(shift.from), 'dd.MM.yyyy HH:mm')} - 
                        ${format(parseISO(shift.to), 'HH:mm')}
                    `}
                />)}

            <form onSubmit={handleSubmit}>
                <CardContent>
                    <Grid container spacing={3}>
                        {shift?.hour_fee && (<Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Typography>Počet hodín</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="hour_count_courier"
                                        type="number"
                                        step=".01"
                                        value={Number(formValues.hour_count_courier)}
                                        onChange={handleInputChange}
                                        error={submitted && !formValues.hour_count_courier}
                                        helperText={submitted && !formValues.hour_count_courier ? 'Počet hodín musí byť zadaný' : ''}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>)}

                        {shift?.km_fee && (<Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Typography>Počet km</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="km_count_courier"
                                        type="number"
                                        value={formValues.km_count_courier}
                                        onChange={handleInputChange}
                                        error={submitted && !formValues.km_count_courier}
                                        helperText={submitted && !formValues.km_count_courier ? 'Počet km musí byť zadaný' : ''}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>)}

                        {shift?.stop_fee && (<Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Typography>Počet zastavení</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="stop_count_courier"
                                        type="number"
                                        value={formValues.stop_count_courier}
                                        onChange={handleInputChange}
                                        error={submitted && !formValues.stop_count_courier}
                                        helperText={submitted && !formValues.stop_count_courier ? 'Počet zastavení musí byť zadaný' : ''}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>)}

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>Poznámka</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    multiline
                                    rows={5}
                                    value={formValues.comment}
                                    onChange={handleInputChange}
                                    error={submitted && formValues.comment.length > 255}
                                    helperText={submitted && formValues.comment.length > 255 ? `Maximum 255 znakov (aktuálne ${formValues.comment.length})` : ''}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Button type="submit" variant="contained" color="primary">
                                    Potvrdiť
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="outlined" color="secondary" onClick={onCancel}>
                                    Naspäť
                                </Button>
                            </Grid>
                        </Grid>

                        {/*                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    fullWidth
                                    onClick={onSendEmail}
                                >
                                    Poslať súbor k tejto smene emailom
                                </Button>
                            </Grid>
                        </Grid>*/}
                    </Grid>
                </CardContent>
            </form>
        </Card>);
};

export default ShiftDetail;