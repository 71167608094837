import React from 'react';
import Button from '@material-ui/core/Button';

export default function SmenyButton() {

    return (
        <a
            href="https://smeny.go4.eu/"
            target="_blank"
            rel="noreferrer"
        >
            <Button
                variant="contained"
                fullWidth
            >
                smeny.go4.eu
            </Button>
        </a>
    );
}
