import React from 'react';
import {useQuery} from '@apollo/client';
import {Loading} from '../../components/index';
import {makeStyles} from '@material-ui/core/styles';
import {loader} from "graphql.macro";
import TopBar from "../../components/TopBar";
import BackButton from "../../components/BackButton";
import MyPaper from "../../components/shipment/paper";
import Typography from "@material-ui/core/Typography";
import Value from "../../components/shipment/value";
import ShipmentCore from "./shipmentCore";
import {useSnackbar} from "notistack";
import {errorMessage} from "../../utils/messages";
import {useMatch} from "@gatsbyjs/reach-router";
import {getLastPersistUrl} from "../../utils/history/lastUrlPersist";


const ALL = loader('../../gql/query/client/all.graphql');
const GET_SHIPMENT_DETAILS = loader('../../gql/query/shipmentDetail.graphql');

const useStyles = makeStyles(theme => ({
    id: {
        textAlign: 'center',
        margin: '0 20px'
    },
    header: {
        lineHeight: 1.15,
    }
}));

export default function Shipment({shipmentId, handleDrawerToggle}) {
    const classes = useStyles();
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const isPickupUrl = useMatch('pickup');
    const isDeliveryUrl = useMatch('delivery');
    const isDeliveryBBUrl = useMatch('deliveryBB');
    const isDepoUrl = useMatch('depo');
    const isExhangeUrl = useMatch('exchange');
    const isRejectUrl = useMatch('reject');
    const {data, loading, error} = useQuery(
        GET_SHIPMENT_DETAILS,
        {
            variables: {zasielka_kod: shipmentId},
            fetchPolicy: 'cache-and-network',
        }
    );
    const {data: dataLocal} = useQuery(ALL, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
    });
    const isMapUrl = useMatch('/map/*');

    if (error) {
        errorMessage('Chyba: '+error, enqueueSnackbar, closeSnackbar);
    }
    if (loading) return <Loading/>;
    if ((!data || !data.shipment) && !loading) {
       return <p>Zasielka neexistuje alebo na nu nemate opravnenie</p>;
    }

    let title;
    if (isPickupUrl) {
        title = 'Vyzdvihnutie';
    } else if (isDeliveryUrl) {
        title = 'Doručenie';
    } else if (isDeliveryBBUrl) {
        title = 'Doručenie balikobox';
    } else if (isDepoUrl) {
        title = 'Depo';
    } else if (isExhangeUrl) {
        title = 'Prehodenie';
    } else if (isRejectUrl) {
        title = 'Odmietnutie';
    } else {
         title = 'Info';
    }

    let lastUrl = null;
    if (isMapUrl) {
        lastUrl = null;
    } else {
        lastUrl = getLastPersistUrl();
        if (typeof lastUrl === "undefined") {
            lastUrl = "/";
        }
    }

    return (
        <div>
            <TopBar handleDrawerToggle={handleDrawerToggle}>
                <BackButton path={lastUrl}/>
                <MyPaper className={classes.id}>
                    <Typography variant="h6" className={classes.header}>
                        {title}<br />
                        ID <Value>{data.shipment.Zasielka_Kod}</Value>
                    </Typography>
                </MyPaper>
            </TopBar>

            <ShipmentCore data={data} dataLocal={dataLocal} />
        </div>
    );
}
