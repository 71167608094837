import {isInBox, waitingToDeliveryToBox, wasDelivery, wasPickUp} from "../../../../utils";
import {isMine} from "../../../shipmentsHelper";

export const isDisabled = (shipment, userCode) => {
    return (
        (wasDelivery(shipment) && shipment.ZStav && shipment.ZStav_popis && shipment.ZStav.ZStav_Kod !== 'UD' && (
            (
                shipment.ZStav.ZStav_Kod === 'D' ||
                shipment.ZStav.ZStav_Kod === 'SP'
            ) ||
            !(
                (shipment.ZStav.ZStav_Kod === 'PU' && shipment.ZStav_popis.ZStav_Popis_Kod === 'PGC') ||
                (shipment.ZStav.ZStav_Kod === 'IN' &&
                    (
                        shipment.ZStav_popis.ZStav_Popis_Kod === 'PUP' ||
                        shipment.ZStav_popis.ZStav_Popis_Kod === 'INT'
                    )
                )
            )
        )) ||
        isInBox(shipment) ||
        waitingToDeliveryToBox(shipment) ||
        shipment.Priradene === null ||
        !isMine(shipment, userCode) ||
        !wasPickUp(shipment) ||
        shipment.Exchanging
    );
};
