import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '4px 15px',
        lineHeight: 1,
        margin: [[12, 0]],
    }
}));

export default function MyPaper({ className, ...props }) {
    const classes = useStyles();

    return (
        <Paper
            className={clsx(classes.root, className)}
            square={false}
            {...props}
        >
            {props.children}
        </Paper>
    );
};