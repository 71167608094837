import React, {Fragment, useState} from 'react'
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LocationPicker from "./locationPicker";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modalHeader: {
        padding: 10,
        height: 55,
    },
    text: {
        margin: '0 10px',
    }
}));

export default function LocationPickerModal({handlePosition, handleClose, open, initLocation}) {
    const [position, setPosition] = useState(initLocation || {
        lat: 48.1486329,
        lng: 17.1528048,
    });
    const classes = useStyles();

    const handleOk = () => {
        handlePosition(position);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Fragment>
                <Paper elevation={0} square={true} className={classes.modalHeader}>
                    <Button autoFocus onClick={handleOk} color="primary" variant="contained">
                        OK
                    </Button>
                    <span className={classes.text}>nájdi bod na mape a stlač OK</span>
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Paper>
                <LocationPicker handlePosition={setPosition} initLocation={initLocation} />
            </Fragment>
        </Modal>
    );
}
