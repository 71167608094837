import "./dbr";
import {BarcodeScanner} from "dynamsoft-javascript-barcode";
import React from 'react';
import './BarcodeScanner.css';

class BarcodeScannerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.pScanner = null;
        this.elRef = React.createRef();
    }

    async componentDidMount() {
        try {
            if (this.pScanner == null) {
                this.pScanner = BarcodeScanner.createInstance();
            }

            const scanner = await this.pScanner;

            this.elRef.current.appendChild(scanner.getUIElement());
            this.elRef.current.style.width = "100%";
            this.elRef.current.style.height = "100%";

            scanner.onUniqueRead = (txt, result) => {
                if (txt.substring(0,4) === '{GS}') {
                    txt = txt.substring(4,txt.length);
                }
                this.props.onScan(txt);
            };
            await scanner.open();

        } catch (ex) {
            console.error(ex);
        }
    }

    shouldComponentUpdate() {
        // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
        return false;
    }

    render() {
        return (
            <div ref={this.elRef}></div>
        );
    }
}

export default BarcodeScannerComponent;
