import React from 'react';
import {useQuery} from "@apollo/client";
import {Loading} from '../components/index';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import packageJson from '../../package.json';
import {makeStyles} from "@material-ui/core/styles";
import {errorMessage} from "../utils/messages";


const ABOUT_USER = loader('../gql/query/client/aboutUser.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    version: {
        marginLeft: 'auto',
    }
}));


export default function AboutUser() {
    const classes = useStyles();
    const {data, loading, error} = useQuery(ABOUT_USER);
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();

    if (loading) return <Loading/>;
    if (error) {
        errorMessage('Chyba: '+error, enqueueSnackbar, closeSnackbar);
    }

    return (
        <div className={classes.root}>
            {data && data.user && data.user.kod && <span>Kód kuriera: <b>{data.user.kod}</b></span>}
            <div className={classes.version}> v{packageJson.version}</div>
        </div>
    );
}
