import React from 'react';
import TabPanel from '../../../components/tabPanel';
import PickUpTab from '../pickUpTab';


export default function PickUpTabWrapper({data, dataLocal, value}) {
    return (
        <TabPanel value={value} index={1}>
            <PickUpTab shipment={data.shipment} dataLocal={dataLocal}/>
        </TabPanel>
    );
}