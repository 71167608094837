import React from 'react';
import TabPanel from '../../../components/tabPanel';
import DepoTab from '../depoTab';


export default function DepoTabWrapper({data, dataLocal, value}) {
    return (
        <TabPanel value={value} index={4}>
            <DepoTab shipment={data.shipment} dataLocal={dataLocal}/>
        </TabPanel>
    );
}
