import React from 'react';
import TabPanel from '../../../components/tabPanel';
import InfoTab from '../infoTab';


export default function InfoTabWrapper({data, dataLocal, value}) {
    return (
        <TabPanel value={value} index={0}>
            <InfoTab shipment={data.shipment} dataLocal={dataLocal}/>
        </TabPanel>
    );
}