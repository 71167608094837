import React, {useState, Fragment} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {format, parse} from "date-fns";
import {Loading, ShippingPointTitle, ShippingTile} from "../../components";
import Grid from "@material-ui/core/Grid";
import {errorAutoHideMessage as errorMessage, successMessage} from "../../utils/messages";
import {createShipmentPoints, filterShipments} from "../../utils/shipments/shipmentPoints";
import {getSortCallbackSingle} from "../shipmentsHelper";
import clsx from "clsx";
import PointTypes from "../../datasource/pointTypeGraphQL";
import ShipmentPointsTypes from "../../datasource/shipmentPointsGraphQL";
import {default as RouterBottomBar} from "../../components/route/BottomBar";
import {Button} from "@material-ui/core";
import {NavigateBefore, NavigateNext} from "@material-ui/icons";
import Wrapper from "../../components/route/Wrapper";
import Content from "../../components/route/Content";
import {Link, navigate} from "@gatsbyjs/reach-router";
import Finished from "../../components/route/Finished";
import {filterShipmentsPointWithoutDeliveryGeoPosition} from "../../utils/shipments";
import Header from "../../components/shipment/header";
import Box from "@material-ui/core/Box";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const GET_ROUTE = loader('../../gql/query/route.graphql');
const GET_SHIPMENTS = loader('../../gql/query/shipments.graphql');
const UPDATE_ROUTE = loader('../../gql/mutations/updateRoute.graphql');
const ALL = loader('../../gql/query/client/all.graphql');

const useStyles = makeStyles(theme => ({
    code: {
        textAlign: 'center',
        fontSize: '20px',
        marginBottom: 20,
        marginLeft: 15,
    },
    radio: {
        margin: '5px 20px',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    spaceForMultiPanel: {
        marginBottom: 153,
    },
    wrapper: {
        textAlign: 'center',
        marginTop: -80,
    },
    floatRight: {
        marginLeft: 'auto',
        order: 2,
    },
    "@keyframes shake": {
        "10%, 90%": {
            transform: "translate3d(-1px, 0, 0)",
        },
        "20%, 80%": {
            transform: "translate3d(2px, 0, 0)",
        },
        "30%, 50%, 70% ": {
            transform: "translate3d(-4px, 0, 0)",
        },
        "40%, 60%": {
            transform: "translate3d(4px, 0, 0)",
        },
    },
    shakeAnimation: {
        animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97) both`
    }
}));
let date = new Date();

const getErrorToAddShipment = (routeDate, startDelivery, point) => {
    if (point.shipment?.TimeSlot?.Date && point.shipment?.TimeSlot?.Date !== routeDate) {
        return "Zásielka " + point.shipment.Zasielka_Kod + " ma iny datum casoveho okna ako aktualna trasa";
    }

    if (point.shipment?.TimeSlot?.TimeTo && point.shipment?.TimeSlot?.TimeTo < startDelivery) {
        return "Zásielka " + point.shipment.Zasielka_Kod + "ma koniec casoveho slotu je pred zaciatkom dorucovania";
    }

    if (!point.shipment.LatitudeD || !point.shipment.LongitudeD) {
        return "Zásielka " + point.shipment.UlicaD + " " + point.shipment.CisloD + " - " + point.shipment.Zasielka_Kod + " nemá geolokáciu, kontaktujte dispecera";
    }

    return false;
};

const RouteStep2 = () => {
    const classes = useStyles();
    const [selectedShipments, setSelectedShipments] = useState([]);
    const [showSelectAll, setShowSelectAll] = useState(true);
    const [shakeShipmentId, setShakeShipmentId] = useState(null);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {data: dataLocal} = useQuery(ALL);
    const {data: dataRoute} = useQuery(GET_ROUTE, {
        fetchPolicy: "network-only",
        variables: {
            date: format(date, 'yyyy-MM-dd'),
        },
        onCompleted: (data) => {
            if (data.lastRoute) {
                setSelectedShipments(prevState => ([...data.lastRoute.routeShipments.map(routeShipments => routeShipments.shipmentId + String(+(routeShipments.pointType === "Delivery")))]));
            }
        },
        refetchQueries: () => ['shipmentList'],
    });
    const [sendUpdateRoute, {loading: loadingMutation}] = useMutation(UPDATE_ROUTE, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            successMessage('Uspešne uložené - trasa vypočitaná.', enqueueSnackbar, closeSnackbar);
            navigate('/route/step3');
        },
    });
    let from;
    let to;
    if (dataLocal) {
        from = format(parse(dataLocal.filter.fromDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
        to = format(parse(dataLocal.filter.toDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    }
    const {data, loading} = useQuery(GET_SHIPMENTS, {
        variables: {
            fromDate: from,
            toDate: to,
            notFinishedRoutes: true,
        },
        onError: error => errorMessage('Chyba pri načítavaní zasielok: ' + error, enqueueSnackbar, closeSnackbar),
    });

    let filtered = [];
    if (typeof data !== "undefined") {
        let routeDataLocalFilter = Object.assign({}, dataLocal.filter);
        routeDataLocalFilter.shipingFilter = 'myToDelivery';
        filtered = filterShipments(createShipmentPoints(data.shipments), routeDataLocalFilter, dataLocal.user);
        filtered = filtered.filter(shipment => shipment.type === ShipmentPointsTypes.TO_DELIVERY); // show only delivery point, pickup point are not supported
        filtered.sort(getSortCallbackSingle(dataRoute?.lastRoute?.routeShipments.length !== 0 ? 'route' : dataLocal.filter.orderSingle));
    }

    const handleMultiSelectShipment = async (id) => {
        setShakeShipmentId(null);
        if (selectedShipments.includes(id)) { //removing
            setSelectedShipments(prevState => ([...prevState.filter(value => value !== id)]));

            if (selectedShipments.length === filtered.length) {
                setShowSelectAll(true);
            } else if (selectedShipments.length - 1 === 0) {
                setShowSelectAll(true);
            }
        } else { //adding
            const error = getErrorToAddShipment(dataRoute?.lastRoute.date, dataRoute.lastRoute.startDelivery, filtered.find(point => id === point.shipment.Zasielka_Kod + String(point.type)));
            if (error) {
                errorMessage(error, enqueueSnackbar, closeSnackbar);
                setShakeShipmentId(id);
                return;
            }

            setSelectedShipments(prevState => ([...prevState, id]));

            if (selectedShipments.length + 1 === filtered.length) {
                setShowSelectAll(false);
            }
        }
    };

    const handleSelectButton = () => {
        if (showSelectAll) {
            const shipmentToSelect = filtered
                .filter(point => {
                    const error = getErrorToAddShipment(dataRoute?.lastRoute.date, dataRoute?.lastRoute.startDelivery, point);
                    if (error) {
                        errorMessage(error, enqueueSnackbar, closeSnackbar);
                        setShakeShipmentId(point.shipment.Zasielka_Kod + String(point.type));
                        return false;
                    }

                    return true;
                })
                .map(point => point.shipment.Zasielka_Kod + String(point.type));
            setSelectedShipments(shipmentToSelect);
            setShowSelectAll(false);
        } else {
            setSelectedShipments([]);
            setShowSelectAll(true);
        }
    }

    const save = () => {
        sendUpdateRoute({
            variables: {
                id: dataRoute.lastRoute.id,
                date: dataRoute.lastRoute.date,
                recalculation: 'OrderAndETA',
                shipmentsOrders: selectedShipments.map(item => ({
                    shipmentId: item.slice(0, -1),
                    pointType: item.slice(-1) === '0' ? PointTypes.PICKUP : PointTypes.DELIVERY,
                    downtime: dataRoute?.lastRoute?.routeShipments.find(point => item.slice(0, -1) === point.shipmentId)?.downtime || null,
                })),
                synchro: true,
            }
        });
    };

    if (dataRoute?.lastRoute?.isClosed) {
        return (<Finished/>);
    }

    const [normalShipmentPoints, withoutGeoPosition] = filterShipmentsPointWithoutDeliveryGeoPosition(filtered);

    return (
        <Fragment>
            <Wrapper>

                {withoutGeoPosition && withoutGeoPosition.length > 0 && (
                    <Fragment>
                        <Grid className={classes.toDepotTitle} container item xs={12} spacing={0}>
                            <Header>
                                    Bez geolokacie <ArrowDownwardIcon className={classes.icon}/>
                            </Header>
                        </Grid>

                        {withoutGeoPosition.map((object, index) => (
                            <ShippingPointTitle
                                className={clsx(shakeShipmentId === object.shipment.Zasielka_Kod + String(object.type) && classes.shakeAnimation)}
                                key={object.id}
                                shipment={object.shipment}
                                type={object.type}
                                routeView={true}
                                multiSelect={true}
                                isSelected={selectedShipments.includes(object.shipment.Zasielka_Kod + String(object.type))}
                                handleMultiSelectShipment={handleMultiSelectShipment}
                            />
                        ))}

                    </Fragment>
                )}

                <Content>
                    {(loadingMutation || loading) && <Loading/>}
                    {/*                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={0}
                        className={clsx(dataLocal.multiSelect && classes.grid)}
                    >
                    </Grid>*/}


                    {withoutGeoPosition && withoutGeoPosition.length > 0 && (
                        <Grid className={classes.toDepotTitle} container item xs={12} spacing={0}>
                            <Header>
                                <Box mt={11} mb={3}>
                                    S geolokaciou <ArrowDownwardIcon className={classes.icon}/>
                                </Box>
                            </Header>
                        </Grid>
                    )}

                    {/*<Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={1}>
                            {loading && <Loading/>}*/}
                    {normalShipmentPoints.map((object, index) => (
                        <ShippingPointTitle
                            className={clsx(shakeShipmentId === object.shipment.Zasielka_Kod + String(object.type) && classes.shakeAnimation)}
                            key={object.id}
                            shipment={object.shipment}
                            type={object.type}
                            routeView={true}
                            multiSelect={true}
                            isSelected={selectedShipments.includes(object.shipment.Zasielka_Kod + String(object.type))}
                            handleMultiSelectShipment={handleMultiSelectShipment}
                        />
                    ))}

                    {/*to show last shipment hidden under bottom menu*/}
                    <div style={{minHeight: 56}}></div>
                    {/*</Grid>*/}
                </Content>
            </Wrapper>

            <RouterBottomBar>
                <Link to="/route/step1">
                    <Button
                        variant="contained"
                        startIcon={<NavigateBefore/>}
                        className={classes.floatRight}
                    >
                        Späť
                    </Button>
                </Link>

                <Button
                    onClick={handleSelectButton}
                    variant="contained"
                >
                    {showSelectAll ? 'Označiť všetky' : 'Odznačiť všetky'}
                </Button>

                <Button
                    onClick={save}
                    variant="contained"
                    endIcon={<NavigateNext/>}
                    className={classes.floatRight}
                    disabled={selectedShipments.length === 0}
                >
                    Ďalej
                </Button>
            </RouterBottomBar>
        </Fragment>
    );
};

export default RouteStep2;

