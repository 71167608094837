const failStatus = [
    {code: 'CAC', title: 'Zrušené po príchode kuriera',},
    {code: 'COF', title: 'Nezdvíha telefón',},
    {code: 'WA', title: 'Zmena adresy',},
    {code: 'DM', title: 'Balík poškodený pri preprave',},
    {code: 'LOS', title: 'Balík stratený pri preprave',},
    {code: 'RF', title: 'Odmietnuté prevzatie',},
    {code: 'UCD', title: 'Zmena termínu',},
    {code: 'UA', title: 'Neprítomný',},
    {code: 'AF', title: 'Nemá 18 rokov',},
    {code: 'PP', title: 'Osobný odber',},
    {code: 'BP', title: 'Zlé telefónne číslo',},
    {code: 'ANF', title: 'Adresát neznámy',},
    {code: 'OTH', title: 'Iné',},
];

export default failStatus;
