import React from "react";
import {Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    bottom: {
        overflow: 'hidden',
        position: 'fixed',
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.custom.main.drawerWidth}px)`,
        },
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));

export default function BottomBar({children}) {
    const classes = useStyles();

    return (
        <Toolbar className={classes.bottom} color="secondary">
            {children}
        </Toolbar>
    );
}
