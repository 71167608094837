import React, {Fragment, useState} from 'react';
import A from '../shipments/a';
import Types from "../../datasource/shipmentPointsGraphQL";
import {generateDeliveryMapUrl, generatePickupMapUrl} from "../shippingTitle/utils";
import UpdateLocationModal from "./updateLocationModal";


const AddressRow = ({shipment, routeView, type, updatePointGeolocationCallback}) => {
    let mapUrl = null;
    let onClickHandler = null;

    const [openLocationModal, setOpenLocationModal] = useState(false);

    const onStreetClickHandler = (event) => {
        event.stopPropagation();
        setOpenLocationModal(true); // popup for geolocation
    };

    const closeWindowCallback = () => {
        setOpenLocationModal(false);
    }

    if (routeView) {
        // link address to update geocode popup
        onClickHandler = onStreetClickHandler;
    } else {
        // link address to google maps in normal view
        if (type === Types.TO_PICKUP) {
            mapUrl = generatePickupMapUrl(shipment);
        } else if (type === Types.TO_DELIVERY) {
            mapUrl = generateDeliveryMapUrl(shipment);
        }
    }

    return (
        <Fragment>
            {type === Types.TO_PICKUP && (shipment.UlicaO?.length > 0 || shipment.CisloO?.length > 0) && (
                <A href={mapUrl} onClick={onClickHandler}>
                    <b>{shipment?.UlicaO} {shipment?.CisloO}</b>
                </A>
            )}

            {type === Types.TO_DELIVERY && (shipment.UlicaD?.length > 0 || shipment.CisloD?.length > 0) && (
                <A href={mapUrl} onClick={onClickHandler}>
                    <b>{shipment?.UlicaD} {shipment?.CisloD}</b>
                </A>
            )}
            {routeView &&
                <UpdateLocationModal
                    shipment={shipment}
                    type={type}
                    show={openLocationModal}
                    updatePointGeolocationCallback={updatePointGeolocationCallback}
                    closeWindowCallback={closeWindowCallback}
                />
            }
        </Fragment>
    );
};

export default AddressRow;
