import React from 'react';
import TabPanel from '../../../components/tabPanel';
import DeliveryBBTab from '../deliveryBB';

export default function DeliveryBBTabWrapper({data, dataLocal, value}) {
    return (
        <TabPanel value={value} index={3}>
            <DeliveryBBTab shipment={data.shipment} dataLocal={dataLocal}/>
        </TabPanel>
    );
}
