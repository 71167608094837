import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(2),
        maxWidth: 350,
    },
    selectField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(2),
        maxWidth: 350,
        minWidth: 220,
    },
    buttonSuccess: {
        color: theme.colors.greenDomca,
        paddingBottom: 0,
        display: 'inline-block',
    },
    buttonFail: {
        color: theme.colors.redDomca,
        paddingBottom: 0,
        display: 'inline-block',
    },
    icons: {
        fontSize: '1.5em',
    },
    input: {
        color: 'white',
        textAlign: 'center',
    },
    referenceNumber: {
        fontSize: '2em',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
        margin: '0 5px',
    },
    datePickerDialogPaper: {
        margin: 5,
    },
    dialogContent: {
        padding: 0,
        '&> *': {
            alignItems: 'center',
        }
    },
    dialogActions: {
        justifyContent: 'center',
    },
    buttonLabel: {
        fontSize: '0.6em',
        flexBasis: '100%',
    },
    iconButtonLabel: {
        flexWrap: 'wrap',
    },
    slotBox: {
        margin: '0 20px',
    },
    copyButton: {
        margin: '0 10px',
    },
    iconHref: {
        color: theme.colors.href,
        display: 'inline-block',
        position: 'relative',
        zIndex: '1',
        padding: '1em 1em',
        margin: '-1em 0',
    },
}));
