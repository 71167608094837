import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {makeStyles} from '@material-ui/core/styles';
import {useQuery} from "@apollo/client";
import {Loading} from '../components/index';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {errorMessage} from "../utils/messages";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3) + 'px 2px',
    },
}));

const GET_FILTER = loader('../gql/query/client/all.graphql');

export default function FontSize() {
    const {data, client, loading, error} = useQuery(GET_FILTER);
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    if (loading) return <Loading/>;
    if (error) {
        errorMessage('Chyba: ' + error, enqueueSnackbar, closeSnackbar);
    }

    const handleValue = event => {
        client.writeQuery({
            query: GET_FILTER,
            data: {
                settings: {
                    ...data.settings,
                    fontSize: event.target.value,
                    __typename: 'Settings',
                }
            },
        });
    };

    return (
        <div>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Velkosť písma</FormLabel>
                <RadioGroup aria-label="fontSize" name="fontSize" value={data?.settings.fontSize}
                            onChange={handleValue}>
                    <FormControlLabel value="normal" control={<Radio/>}
                                      label={"normálne"}/>
                    <FormControlLabel value="big" control={<Radio/>}
                                      label={"vačšie"}/>
                </RadioGroup>
            </FormControl>
        </div>
    );
}
