import clsx from "clsx";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0px 0px',
    lineHeight: 1.2,
    textAlign: 'left',
  }
}));

export default function StyleP(props) {
  const classes = useStyles();

  return (
      <div style={props.style} className={clsx(props.className, classes.root)}>
        {props.children}
      </div>
  );
};
