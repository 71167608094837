import React, {useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {format, isPast, parse, parseISO} from "date-fns";
import {Loading, ShippingPointTitle} from "../../components";
import Grid from "@material-ui/core/Grid";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {errorMessage, successMessage} from "../../utils/messages";
import {
    createShipmentPointsFromRoute,
} from "../../utils/shipments/shipmentPoints";
import {getSortCallbackSingle} from "../shipmentsHelper";
import clsx from "clsx";
import PointTypes from "../../datasource/pointTypeGraphQL";
import RecalculationRoute from "../../datasource/recalculationRouteGraphQL";
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import UpdateIcon from '@material-ui/icons/Update';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import CancelIcon from '@material-ui/icons/Cancel';
import {navigate} from "@gatsbyjs/reach-router";
import Finished from "../../components/route/Finished";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const GET_FILTER = loader('../../gql/query/client/all.graphql');
const GET_ROUTE = loader('../../gql/query/route.graphql');
const GET_SHIPMENTS = loader('../../gql/query/shipments.graphql');
const UPDATE_ROUTE = loader('../../gql/mutations/updateRoute.graphql');
const FINISH_ROUTE = loader('../../gql/mutations/finishRoute.graphql');
const ALL = loader('../../gql/query/client/all.graphql');


const useStyles = makeStyles(theme => ({
    code: {
        textAlign: 'center',
        fontSize: '20px',
        marginBottom: 20,
        marginLeft: 15,
    },
    radio: {
        margin: '5px 20px',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        transform: 'translateZ(0px)',
        flexGrow: 1,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    spaceForMultiPanel: {
        marginBottom: 153,
    },
    wrapper: {
        textAlign: 'center',
        marginTop: -80,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    speedDialAction: {
        [theme.breakpoints.up('sm')]: {
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            width: '70vw',
        },
    }
}));


let date = new Date();

const RouteStep3 = () => {
    const classes = useStyles();
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [autoUpdate, setAutoUpdate] = useState(false);
    const [state, setState] = useState({
        disableDrag: false,
        draggingId: null,
    });
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {data: dataLocal} = useQuery(ALL);
    const {data: dataRoute} = useQuery(GET_ROUTE, {
        fetchPolicy: "network-only",
        variables: {
            date: format(date, 'yyyy-MM-dd'),
        },
    });

    let from;
    let to;
    if (dataLocal) {
        from = format(parse(dataLocal.filter.fromDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
        to = format(parse(dataLocal.filter.toDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    }
    // todo optimalization, load only shipments on route not all between dates
    const {data, loading, client} = useQuery(GET_SHIPMENTS, {
        fetchPolicy: "network-only",
        variables: {
            fromDate: from,
            toDate: to,
            notFinishedRoutes: true,
        },
        onError: error => errorMessage('Chyba pri načítavaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {}
    });
    const [sendUpdateRoute, {loading: loadingMutation}] = useMutation(UPDATE_ROUTE, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            if (autoUpdate) {
                successMessage('Uspešne uložené - trasa prepočítaná.', enqueueSnackbar, closeSnackbar);
            } else {
                successMessage('Uspešne uložené.', enqueueSnackbar, closeSnackbar);
            }
        },
        refetchQueries: () => ['shipmentList'],
    });

    const [sendFinishRoute, {loading: loadingFinished}] = useMutation(FINISH_ROUTE, {
        onError: (error) => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: (data) => {
            client.writeQuery({
                query: GET_FILTER,
                data: {
                    filter: {
                        ...dataLocal.filter,
                        'orderSingle': 'route',
                        'order': 'route',
                        __typename: 'Filter',
                    }
                },
            });
            successMessage('Trasa uzavreta.', enqueueSnackbar, closeSnackbar);
            navigate('/');
        },
        refetchQueries: [
            {
                query: GET_SHIPMENTS,
                variables: {
                    fromDate: from,
                    toDate: to,
                    notFinishedRoutes: false,
                }
            }
        ],
    });

    let filtered = [];
    if (typeof data !== "undefined") {
        filtered = createShipmentPointsFromRoute(data.shipments);
        filtered.sort(getSortCallbackSingle('route'));
    }

    const handleSaveRoute = (resetOrder) => () => {
        handleClose();
        sendUpdateRoute({
            variables: {
                id: dataRoute.lastRoute.id,
                date: dataRoute.lastRoute.date,
                recalculation: resetOrder ? RecalculationRoute.ORDER_AND_ETA : RecalculationRoute.ONLY_ETA,
                shipmentsOrders: filtered.map(item => ({
                    shipmentId: item.shipment.Zasielka_Kod,
                    pointType: Number.parseInt(item['id'].slice(-1)) === 0 ? PointTypes.PICKUP : PointTypes.DELIVERY,
                    downtime: item.shipmentRoute.downtime,
                })),
                synchro: true,
            }
        })
     };

    const handleFinishRoute = () => {
        handleClose();
        sendFinishRoute({
            variables: {
                id: dataRoute.lastRoute.id,
            }
        });
    }

    const handleChangeDowntime = (shipmentId, type, value) => {
        let shipmentsData = [];
        for (const shipment of filtered) {
            const data = {
                shipmentId: shipment.shipment.Zasielka_Kod,
                pointType: shipment.shipmentRoute.type === 0 ? PointTypes.PICKUP : PointTypes.DELIVERY,
                downtime: shipment.shipmentRoute.downtime,
            };

            if (shipment.shipment.Zasielka_Kod === shipmentId && type === shipment.type) {
               data.downtime = value * 60;
            }

            //shipmentsData.push(data);
            shipmentsData[shipment.shipmentRoute.order] = data; // todo wip
        }

        sendUpdateRoute({
            variables: {
                id: dataRoute.lastRoute.id,
                date: dataRoute.lastRoute.date,
                recalculation: RecalculationRoute.ONLY_ETA,
                shipmentsOrders: shipmentsData.filter(x => x),
                synchro: autoUpdate,
            }
        })
    }

    const handleOpen = () => {
        setOpenSpeedDial(true);
    };

    const handleClose = () => {
        setOpenSpeedDial(false);
    };

    const handleToggleAutoupdate = () => {
        handleClose();
        setAutoUpdate( (oldVal) => !oldVal);
    }

    const handleEditTime = () => {
        navigate('/route/step1?r=step3');
    }

    const afterUpdatePointGeolocation = () => {
        //handleSaveRoute(false)();
    }

    const onBeforeDragStart = (object) => {
        const id = object.draggableId;
        setState({...state,
            draggingId: id,
        });
    };

    const onDragEnd = (result) => {
        const {destination, source} = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const movedItem = filtered[source.index];
        const newItems = Array.from(filtered);
        newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, movedItem);

        sendUpdateRoute({
            variables: {
                id: dataRoute.lastRoute.id,
                date: dataRoute.lastRoute.date,
                recalculation: RecalculationRoute.ONLY_ETA,
                shipmentsOrders: newItems.map(item => ({
                    shipmentId: item.shipment.Zasielka_Kod,
                    pointType: Number.parseInt(item['id'].slice(-1)) === 0 ? PointTypes.PICKUP : PointTypes.DELIVERY,
                    downtime: item.shipmentRoute.downtime,
                })),
                synchro: autoUpdate,
            },
        })

        setState({...state,
            draggingId: null,
        });
    };

    if (dataRoute?.lastRoute?.isClosed) {
        return (<Finished/>);
    }

    return (
        <div className={classes.wrapper}>
            {(loadingMutation || loading || loadingFinished) && <Loading/>}
            <h2>planovanie trasy pre {format(date, 'dd.MM.yyyy')}</h2>
            {(dataRoute?.lastRoute?.percentage < 100 && dataRoute?.lastRoute?.percentage !== null) &&
                <p>
                    Trasa nebola dopocitana, skuste stranku aktualizovat o 15-30s, ak trasa nebude dopocitana mame technicky problem.
                </p>
            }
            <p>
                optimalne vypocitana trasa (mozete zasielky manualne prehadzat, zmenit prestoje,...), po kazdej zmene sa aktualizuje cas dorucenia. Ak zasieka nema ETD (cas dorucenia) nebolo ju mozne zaradit do trasy.
            </p>

            {(dataRoute && dataRoute?.lastRoute?.error) &&
                <p style={{color: 'red'}}>
                    Solvertech error: {dataRoute.lastRoute.error}
                </p>
            }

            <Grid
                container
                direction="row"
                alignItems="center"
                spacing={0}
                className={clsx(dataLocal.multiSelect && classes.grid)}
            >
                <Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={1}>

                    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
                        <Droppable droppableId="list-point">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {filtered.map((object, index) => (
                                        <Draggable draggableId={object.id} key={object.id} index={index} >
                                            {(providedDraggable) => {
                                                return (
                                                    <div
                                                        {...providedDraggable.draggableProps}
                                                        ref={providedDraggable.innerRef}
                                                    >
                                                        <ShippingPointTitle
                                                            key={object.id}
                                                            shipment={object.shipment}
                                                            type={object.type}
                                                            routeView2={true}
                                                            downtime={parseInt((object.shipmentRoute.downtime !== null ? object.shipmentRoute.downtime : dataRoute.lastRoute.downtime) / 60)}
                                                            handleChangeDowntime={handleChangeDowntime}
                                                            dragHandleProps={providedDraggable.dragHandleProps}
                                                            updatePointGeolocationCallback={afterUpdatePointGeolocation}
                                                        />
                                                    </div>
                                                )}
                                            }
                                        </Draggable>
                                    ))}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                </Grid>
            </Grid>

            <Backdrop open={openSpeedDial} />
            <SpeedDial
                ariaLabel="SpeedDial route"
                className={classes.speedDial}
                hidden={false}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={openSpeedDial}
            >
                <SpeedDialAction
                    key={'final'}
                    icon={<SaveIcon />}
                    tooltipTitle={'Finalizácia trasy'}
                    tooltipOpen
                    onClick={handleFinishRoute}
                    classes={{ staticTooltipLabel: classes.speedDialAction }}
                />

                <SpeedDialAction
                    key={'onlyETA'}
                    icon={<UpdateIcon />}
                    tooltipTitle={'Preratanie časov (ostáva vlastné poradie)'}
                    tooltipOpen
                    onClick={handleSaveRoute(false)}
                    classes={{ staticTooltipLabel: classes.speedDialAction }}
                />

                <SpeedDialAction
                    key={'orderAndEta'}
                    icon={<SaveIcon />}
                    tooltipTitle={'Optimálna trasa (zmaže vlastné poradie)'}
                    tooltipOpen
                    onClick={handleSaveRoute(true)}
                    classes={{ staticTooltipLabel: classes.speedDialAction }}
                />

                <SpeedDialAction
                    key={'toggleAutosave'}
                    icon={autoUpdate ? <ToggleOnIcon /> : <ToggleOffIcon />}
                    tooltipTitle={'Auto prerátavanie po každej zmene Vyp/Zap' }
                    tooltipOpen
                    onClick={handleToggleAutoupdate}
                    classes={{ staticTooltipLabel: classes.speedDialAction }}
                />

                <SpeedDialAction
                    key={'editTime'}
                    icon={<ScheduleIcon />}
                    tooltipTitle={'Upraviť čas štartu/prestoje' }
                    tooltipOpen
                    onClick={handleEditTime}
                    classes={{ staticTooltipLabel: classes.speedDialAction }}
                />

                <SpeedDialAction
                    key={'cancelRoute'}
                    icon={<CancelIcon />}
                    tooltipTitle={'Zrušiť trasu' }
                    tooltipOpen
                    onClick={handleFinishRoute}
                    classes={{ staticTooltipLabel: classes.speedDialAction }}
                />
            </SpeedDial>
        </div>
    );
};

export default RouteStep3;

