import React from 'react';
import TabPanel from '../../../components/tabPanel';
import RejectTab from '../rejectTab';


export default function RejectTabWrapper({data, value}) {
    return (
        <TabPanel value={value} index={6}>
            <RejectTab shipment={data.shipment}/>
        </TabPanel>
    );
}
