import React from 'react';
import {Grid, Typography, Card, CardContent} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles} from "@material-ui/core/styles";
import {format, parseISO} from "date-fns";

const useStyles = makeStyles(theme => ({
    danger: {
        color: theme.colors.redDomca,
    }
}));

const ShiftsData = ({shifts, onShiftClick}) => {
    const classes = useStyles();

    return (<div className="shifts-data">
            <Typography variant="h4" className="shifts-header">Zoznam smien</Typography>
            <Grid container spacing={2}>
                {shifts.map((shift, index) => (<Grid item xs={12} key={index} onClick={(e) => onShiftClick(e, shift)}>
                        <Card className="shift-item">
                            <CardContent>
                                <Typography variant="body1">
                                    <b>{shift.name}</b> <br/>
                                    {format(parseISO(shift.from), 'dd.MM.yyyy HH:mm')} - {format(parseISO(shift.to), 'HH:mm')}
                                </Typography>

                                {(shift.hour_fee || shift.km_fee || shift.stop_fee) && (<Grid container>
                                        <Grid item xs={6} className="shift-header">&nbsp;</Grid>
                                        <Grid item xs={3} className="shift-header">Kuriér</Grid>
                                        <Grid item xs={3} className="shift-header">Systém</Grid>
                                    </Grid>)}

                                {shift.hour_fee && (<Grid container>
                                        <Grid item xs={6}>Počet hodín:</Grid>
                                        <Grid item xs={3} style={{textAlign: "center"}}>
                                            <b>{shift.hour_count_courier}</b>
                                        </Grid>
                                        <Grid item xs={3} style={{textAlign: "center"}}
                                              className={clsx({[classes.danger]: shift.hour_count_courier !== shift.hour_count})}>
                                            <b>{shift.hour_count}</b>
                                        </Grid>
                                    </Grid>)}

                                {shift.km_fee && (<Grid container>
                                        <Grid item xs={6}>Počet km:</Grid>
                                        <Grid item xs={3} style={{textAlign: "center"}}>
                                            <b>{shift.km_count_courier}</b>
                                        </Grid>
                                        <Grid item xs={3} style={{textAlign: "center"}}
                                              className={clsx({[classes.danger]: shift.km_count_courier !== shift.km_count})}>
                                            <b>{shift.km_count}</b>
                                        </Grid>
                                    </Grid>)}

                                {shift.stop_fee && (<Grid container>
                                        <Grid item xs={6}>Počet zastavení:</Grid>
                                        <Grid item xs={3} style={{textAlign: "center"}}>
                                            <b>{shift.stop_count_courier}</b>
                                        </Grid>
                                        <Grid item xs={3} style={{textAlign: "center"}}
                                              className={clsx({[classes.danger]: shift.stop_count_courier !== shift.stop_count})}>
                                            <b>{shift.stop_count}</b>
                                        </Grid>
                                    </Grid>)}

                                {shift.comment && (<Grid container>
                                        <Grid item xs={12} className="shift-comment">
                                            <Typography variant="body2">Poznámka:</Typography>
                                            <Typography variant="body2">{shift.comment}</Typography>
                                        </Grid>
                                    </Grid>)}

                                {shift.closed === '-1' && (<Grid container>
                                        <Grid item xs={12} className="shift-comment">
                                            <Typography variant="body2">Odmena: {shift.courier_cost}€</Typography>
                                        </Grid>
                                    </Grid>)}
                            </CardContent>
                        </Card>
                    </Grid>))}
            </Grid>
        </div>);
};


export default ShiftsData;
