import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    appBar: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.custom.main.drawerWidth,
            width: `calc(100% - ${theme.custom.main.drawerWidth}px)`,
        },
    },
}));


export default function DrawerBarWrapper({children}) {
    const classes = useStyles();

    return (
        <div className={classes.appBar}>
            {children}
        </div>
    );
}
