import React from "react";
import {removeAccents} from "../../../utils";
import isIos from "../../../utils/deviceDetection/isIos";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {getSMSByKey} from "../../../utils/sms";

const ALL = loader('../../../gql/query/client/all.graphql');

export default function SmsHref({shipment, to, children, isDelivery, className}) {

    const {data} = useQuery(ALL);

    let smsBody = "?body=";
    if (isIos()) {
        smsBody = "&body=";
    }

    const sms = getSMSByKey(isDelivery ? data?.settings?.smsDelivery : data?.settings?.smsPickup, shipment, data.user, !isDelivery);

    return (
        <a href={"sms:" + to + smsBody + encodeURI(removeAccents(sms))}
           className={className}>
            {children}
        </a>
    );
};
