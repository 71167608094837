import {isStringValidInteger} from "../../../../utils";

export const getDeliveryValidationRules = values => {
    return {
        nameDelivery: (values.nameDelivery === null || values.nameDelivery.trim() === ""),
        time: !isStringValidInteger(values.time) || (parseInt(values.time) < 0),
        additionalInfoText: values.isZS && values.deliveryCode !== 'DGC' &&
            (typeof values?.additionalInfo?.text === "undefined" || values?.additionalInfo?.text?.trim() === ""),
    };
};
