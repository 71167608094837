import React from 'react';
import {useApolloClient} from '@apollo/client';
import Button from '@material-ui/core/Button';
import {navigate} from "@gatsbyjs/reach-router";
import {loader} from "graphql.macro";
const IS_USER_LOGGED_IN = loader('../../gql/query/client/isLoggedIn.graphql');

export default function LogoutButton() {
    const client = useApolloClient();

    return (
        <Button
            variant="contained"
            fullWidth
            onClick={() => {
                client.writeQuery({
                    query: IS_USER_LOGGED_IN,
                    data: {
                        isLoggedIn: false,
                        token: null,
                    }
                });
                navigate('/');
            }}
        >
            Logout
        </Button>
    );
}
