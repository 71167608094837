import React, {useState} from 'react'
import {GoogleMap, useJsApiLoader, StandaloneSearchBox, Marker} from '@react-google-maps/api'
import Loading from "../loading";
import "./locationPicker.css";

const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 55px)'
};
const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '10px',
    right: '10px',
}

const DEFAULT_ZOOM = 14;
const position = {
    lat: 48.1486329,
    lng: 17.1528048,
};

const jsApiLoaderOptions = {
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: ["places"],
};

const LocationPicker = function LocationPicker({handlePosition, initLocation}) {
    const searchRef = React.useRef(null);
    const [state, setState] = useState({position: initLocation || position, marker: initLocation || position});
    const {isLoaded, loadError} = useJsApiLoader(jsApiLoaderOptions);

    const inputOnClick = (event) => {
        event.stopPropagation();
    }

    const onClick = (event) => {
        event.domEvent.stopPropagation();
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const position = {lat, lng};
        setState({marker: position});
        handlePosition(position);
    }

    const onPlacesChanged = () => {
        const place = searchRef.current.getPlaces();
        const position = {
            lat: place[0].geometry.location.lat(),
            lng: place[0].geometry.location.lng()
        };
        setState({position, marker: position});
        handlePosition(position);
    }

    const onLoad = ref => searchRef.current = ref;

    const renderMap = () => {
        return <GoogleMap
            mapContainerStyle={containerStyle}
            options={{
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
/*                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM,
                },*/
            }}
            zoom={DEFAULT_ZOOM}
            onClick={onClick}
            center={state.position}
        >
            <StandaloneSearchBox
                onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}
            >
                <input
                    type='text'
                    placeholder='Search address'
                    style={inputStyle}
                    onClick={inputOnClick}
                />
            </StandaloneSearchBox>
            <Marker
                draggable
                position={state.marker}
                onDragEnd={onClick}
            />
        </GoogleMap>
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <Loading/>
}

export default React.memo(LocationPicker);
