import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 5,
        width: '100%',
        padding: '1px 8px',
    }
}));

export default function ExchangeWrapper({className, ...props}) {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root, className)}
            {...props}
        >
            {props.children}
        </div>
    );
};