import React, {Fragment, useState} from 'react';
import {useLazyQuery, useQuery} from '@apollo/client';
import Button from '@material-ui/core/Button';
import {loader} from "graphql.macro";
import {endOfDay, format, parseISO, startOfDay, subDays} from "date-fns";
import {useSnackbar} from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {errorMessage} from "../../utils/messages";
import {makeStyles} from "@material-ui/core/styles";
import Row from "./row";
import CurrencySymbol from "./currencySymbol";
import Loading from "../loading";

const GET_ALL_LOCAL = loader('../../gql/query/client/all.graphql');
const COD_SUMMARY = loader('../../gql/query/codSummary.graphql');

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    cellPadding: {
        padding: '3px 12px 3px 8px',
    },
}));

export default function CodSummary() {
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [state, setState] = useState({open: false, shipmentByDate: {}, data: {codSummary: [], shipments: [], codSummaryDiff: []}});
    const {data: dataLocal, loading: loadingLocal} = useQuery(GET_ALL_LOCAL);
    const from = startOfDay(subDays(new Date(), process.env.REACT_APP_HISTORY_DATE));
    const to = endOfDay(new Date());
    const [getCODData, {loading}] = useLazyQuery(COD_SUMMARY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            fromDate: format(from, 'yyyy-MM-dd'),
            toDate: format(to, 'yyyy-MM-dd'),
        },
        errorPolicy: 'all',
        skip: loadingLocal,
        onError: error => errorMessage('Chyba pri načítavaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: function (data) {

            let codDetail = {};
            data?.shipments?.forEach(shipment => {
                if (shipment.Dobierka.PaymentType === "Cash" && shipment.Dobierka.Process && shipment.Dorucil.Kurier_Kod === dataLocal.user.kod) {
                    const deliveryDate = format(shipment.DatumDoruc, 'yyyyMMdd');
                    if (typeof codDetail[deliveryDate] === "undefined") {
                        codDetail[deliveryDate] = [];
                    }
                    codDetail[deliveryDate].push(shipment);
                }
            });

            setState({...state, 'data': data, 'shipmentByDate': codDetail});
        },
    });

    const handleOpen = () => {
        setState({...state, 'open': true});
        getCODData();
    };

    const handleClose = () => {
        setState({...state, 'open': false});
    };

    if (loadingLocal || loading) return <Loading/>;

    return (
        <Fragment>
            <Button
                variant="contained"
                fullWidth
                onClick={handleOpen}
            >
                Prehľad súm dobierok
            </Button>
            <Dialog open={state.open} onClose={handleClose}>
                <Fragment>
                    <DialogTitle>
                        Prehľad súm dobierok platených v
                        hotovosti <br/>od {format(from, 'd.M.yyyy')} do {format(to, 'd.M.yyyy')}
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {state.data.codSummaryDiff[0] && (
                            <Fragment>Celkový rozdiel zaokruhlovania od {format(new Date(state.data.codSummaryDiff[0].FromDate), 'd.M.yyyy')}: </Fragment>
                        )}
                        {state.data.codSummaryDiff.filter(row=> row.Diff !== null).map(
                            (row) => (
                                <Fragment key={row.CurrencyId}><span key={row.CurrencySymbol}>{row.Diff} <CurrencySymbol currencySymbol={row.CurrencySymbol}/></span>{row.Diff < 0 && ' (preplatok)'}{row.Diff > 0 && ' (nedoplatok)'}<br/></Fragment>
                            )
                        )}
                        <TableContainer component={Paper}>
                            <Table size="small">
                                {state.data.codSummary === null ?
                                    <p>žiadne v hotovosti platené dobierky
                                        medzi {format(from, 'dd.MM.yyyy')} - {format(to, 'dd.MM.yyyy')}</p>
                                    : <Fragment>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell padding="none" className={classes.cellPadding}>Datum</TableCell>
                                                <TableCell padding="none" className={classes.cellPadding}>VS</TableCell>
                                                <TableCell padding="none" className={classes.cellPadding}>Suma zaokruhlena</TableCell>
                                                <TableCell padding="none" className={classes.cellPadding}>Suma</TableCell>
                                                <TableCell padding="none" className={classes.cellPadding}>Pripisana platba</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.data.codSummary.map((row) => (
                                                <Row key={row.Date} row={row} codDetail={state.shipmentByDate[format(parseISO(row.Date), 'yyyyMMdd')] || []} userCode={dataLocal.user.kod}/>
                                            ))}
                                        </TableBody>
                                    </Fragment>}
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Fragment>
            </Dialog>
        </Fragment>

    );
}
