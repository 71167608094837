import React from 'react';
import Button from '@material-ui/core/Button';

export default (message, enqueueSnackbar, closeSnackbar, options) => {
    enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: '1000',
        action: (key) => (
            <Button  style={{color: "white"}} onClick={() => {
                closeSnackbar(key);
            }}>OK</Button>
        ),
        ...options,
    });
}
