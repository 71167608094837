import React, {Fragment} from 'react';
import {format} from "date-fns";
import {formatTimeToNextStop} from "./utils/formatTimeToNextStop";

export default function DeliveryData({shipment}) {

    let stack = [];

    if (shipment.ETADelivery) {
        stack.push((<span>⇣ {format(shipment.ETADelivery, "HH:mm")}</span>));
    }

    if (shipment?.ShipmentRoute[0]?.timeToNextStop) {
        stack.push((<span>->{formatTimeToNextStop(shipment.ShipmentRoute[0].timeToNextStop)}</span>));
    }

    if (shipment?.ShipmentRoute[0]?.distanceToNextStop) {
        stack.push((<span>{shipment.ShipmentRoute[0].distanceToNextStop.toFixed(2)}km</span>));
    }

    return (
        <Fragment>
            {
                stack.map((info, index, array) => (
                    <Fragment key={index}>{info}
                        {(array.length -1 !== index && (
                            <Fragment>, </Fragment>
                        ))}
                    </Fragment>
                ))
            }
        </Fragment>
    );
};
