import {createTheme, responsiveFontSizes} from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from "@material-ui/core/colors/red";

let theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
            'sans-serif'
        ],
        fontSize: 13,
    },
    spacing: 4,
    palette: {
        primary: grey,
        secondary: grey,
    },
    shadows: Array(25).fill('none'),
    colors: {
        header: 'grey',
        href: 'grey',
        greenDomca: '#31ca5c',
        redDomca: '#f63e3f',
        pinkDomca: '#fe82fb',
        blueDomca: '#32abf9',
        done: '#828282',
        white: 'white',
    },
    overrides: {
        MuiPickersDay: {
            current: {
                color: red["900"],
            },
        },
    },
    custom: {
        main: {
            drawerWidth: 230,
        }
    }
});
theme = responsiveFontSizes(theme);

export default theme;
