import React, { useEffect, useState } from "react";

const CountDown = function CountdownTimer({date, updateInterval}) {
    const calculateTimeLeft = () => {
        const difference = +date - +new Date();
        let timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        let timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, updateInterval);
        return () => {
            clearTimeout(timer);
        }
    });

    const timerComponents = [];

    if (timeLeft.days !== 0) {
        timerComponents.push(<span key="days">{timeLeft.days}d</span>);
        timerComponents.push(<span key="hours"> {timeLeft.hours}h</span>);
    } else if (timeLeft.hours !== 0){
        timerComponents.push(<span key="hours">{timeLeft.hours}h</span>);
        timerComponents.push(<span key="minutes"> {timeLeft.minutes}m</span>);
    } else if (timeLeft.minutes !== 0) {
        timerComponents.push(<span key="minutes">{timeLeft.minutes}m</span>);
    } else {
        timerComponents.push(<span key="default">00:00</span>);
    }

    return (
        <span>
            {timerComponents.length ? timerComponents : <span>00:00</span>}
        </span>
    );
};

CountDown.defaultProps = {
    updateInterval: 60000,
};

export default CountDown;