import Button from "@material-ui/core/Button";
import SyncIcon from "@material-ui/icons/Sync";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {gql, useQuery} from "@apollo/client";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    '@keyframes rotate': {
        from: {transform: 'rotate(0deg)'},
        to: {transform: 'rotate(360deg)'}
    },
    rotate: {
        animationName: '$rotate',
        animationDuration: '2s',
        animationIterationCount: 'infinite',
    },
}));

const RefreshButton = ({children, handleClick}) => {
    const {data} = useQuery(gql`
        {
            loading @client
        }
    `);
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            size="medium"
            fullWidth
            onClick={() => handleClick()}
        >
            <SyncIcon className={clsx(typeof data !== "undefined" && data.loading && classes.rotate)}/>
            {children}
        </Button>
    );
}

export default RefreshButton;