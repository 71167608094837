import React from 'react';
import Button from '@material-ui/core/Button';

const ErrorMessage = (message, enqueueSnackbar, closeSnackbar, options) => {
    enqueueSnackbar(message, {
        variant: 'error',
        persist: true,
        action: (key) => (
            <Button  style={{color: "white"}} onClick={() => {
                closeSnackbar(key);
            }}>OK</Button>
        ),
        ...options,
    });
};

export default ErrorMessage;
