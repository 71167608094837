import {addWorkingDays, isFistTimeSlot, isHoliday, isThirdTimeSlot} from "../../../utils/datetime/utils";
import {isSameDay, isSaturday, isSunday, isToday} from "date-fns";

const disabledWindowSlots = (date) => {
    let slots = {
        disabledWindowSlot0: false,
        disabledWindowSlot1: false,
        disabledWindowSlot2: false,
        disabledWindowSlot3: false,
    };

    const tomorrowWorking = addWorkingDays(new Date(), 1);

    if (isSunday(date) || isSaturday(date) || isHoliday(date)) {
        slots.disabledWindowSlot0 = true;
        slots.disabledWindowSlot1 = true;
        slots.disabledWindowSlot2 = true;
        slots.disabledWindowSlot3 = true;
    } else if (isToday(date)) {
        console.log(isFistTimeSlot(date))
        if (isFistTimeSlot(date)) {
            slots.disabledWindowSlot0 = false;
            slots.disabledWindowSlot1 = true;
            slots.disabledWindowSlot2 = true;
            slots.disabledWindowSlot3 = false;
        } else {
            slots.disabledWindowSlot0 = true;
            slots.disabledWindowSlot1 = true;
            slots.disabledWindowSlot2 = true;
            slots.disabledWindowSlot3 = true;
        }
    } else if (isSameDay(tomorrowWorking, date)) {
        slots.disabledWindowSlot0 = false;
        slots.disabledWindowSlot1 = false;
        slots.disabledWindowSlot2 = false;
        slots.disabledWindowSlot3 = false;
        if (isThirdTimeSlot(date)) {
            slots.disabledWindowSlot1 = true;
        }
    }

    return {...slots};
};

export default disabledWindowSlots;
