import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {useQuery} from "@apollo/client";
import {loader} from 'graphql.macro';
import {makeStyles} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";
import {getAllSMS} from "../../../utils/sms";

const ALL = loader('../../../gql/query/client/all.graphql');
const SETTINGS = loader('../../../gql/query/client/settings.graphql');

const useStyles = makeStyles(theme => ({
    menu: {
        whiteSpace: 'normal',
    },
    textField: {
        margin: 0
    }
}));

export default function SmsSelect({shipment, isPickup}) {
    const classes = useStyles();
    const {data: dataLocal} = useQuery(ALL);
    const {data, client} = useQuery(SETTINGS);

    const sms = getAllSMS(shipment, dataLocal.user, isPickup);

    const handleChange = (value) => {
        const key = isPickup ? 'smsPickup' : 'smsDelivery';
        client.writeQuery({
            query: SETTINGS,
            data: {
                settings: {
                    ...data.settings,
                    [key]: value.target.value,
                }
            },
        })
    }

    return (

            <TextField
                id="standard-select-currency"
                select
                className={classes.textField}
                value={isPickup ? data?.settings?.smsPickup : data?.settings?.smsDelivery}
                onChange={handleChange}
                margin={'none'}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                    renderValue: option => {
                        const splitted = option.split(' - ');
                        return splitted.length === 2 ? splitted[0] : option;
                    }
                }}
            >
                {sms.map(option => (
                    <MenuItem className={classes.menu} key={option.value} value={option.label}>
                        {option.label} - {option.value}
                    </MenuItem>
                ))}
            </TextField>
    );
}
