import {format, subDays} from "date-fns";

export const isStringValidInteger = str => {
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str;
};

export const parseTime = time => {
    let splitTime = time.split(':');
    if (splitTime.length !== 3) {
        return 0;
    }

    return Math.round(parseInt(splitTime[0]) * 60 + parseInt(splitTime[1]) + parseInt(splitTime[2])/60);
};

export const convertMinutesToTime = minutes => {
    let realMin = minutes % 60;
    let hours = Math.floor(minutes / 60);
    realMin = realMin < 10 ? '0' + realMin : realMin;
    hours = hours < 10 ? '0' + hours : hours;

    return hours + ':' + realMin + ':00';
};

export const splitNames = name => {
    let firstName;
    let secondName;
    const nameSenderSplit = name.trim().split(' ');
    if (nameSenderSplit.length === 1) {
        firstName = '';
        secondName = nameSenderSplit[0];
    } else if (nameSenderSplit.length === 2) {
        firstName = nameSenderSplit[0];
        secondName = nameSenderSplit[1];
    } else {
        firstName = nameSenderSplit[0];
        nameSenderSplit.splice(0, 1);
        secondName = nameSenderSplit.join(' ');
    }

    return [firstName, secondName];
};

export const wasAssign = shipment => {
    return (typeof shipment.Priradene !== "undefined" && shipment.Priradene != null) || shipment.Prevzal != null || shipment.Dorucil != null || wasPickUp(shipment);
};

/** without legacy assign */
export const wasAssignNew = shipment => {
    return typeof shipment.Priradene !== "undefined" && shipment.Priradene !== null;
};

export const isNotAssign = shipment => {
    return shipment.Priradene === null;
};

export const isSystemCourier = shipment => {
    const systemCourierCode = process.env.REACT_APP_SYSTEM_COURIER_CODE.split(",");
    if (!shipment.Priradene) {
        return false;
    }
    return systemCourierCode.includes(String(shipment.Priradene.Kurier_Kod));
};

export const wasPickUpByMe = (shipment, courierCode) => {
    return wasPickUp(shipment) && (
        (shipment.Prevzal !== null && courierCode === shipment.Prevzal?.Kurier_Kod) ||
        (shipment.LastExchange?.To.Kurier_Kod === courierCode && shipment.LastExchange?.Approved) ||
        (shipment?.InternPickUpCourier?.Kurier_Kod === courierCode)
    );
}

export const wasPickUp = shipment => {
    return (
        (shipment.ZStav_popis && shipment.ZStav && (
            (shipment.ZStav.ZStav_Kod === 'PU' && shipment.ZStav_popis.ZStav_Popis_Kod === 'PGC') || // legacy state for pick-up
            (shipment.ZStav.ZStav_Kod === 'IN' && shipment.ZStav_popis.ZStav_Popis_Kod === 'PUP') || // using state for pick-up
           // (shipment.ZStav.ZStav_Kod === 'IN' && shipment.ZStav_popis.ZStav_Popis_Kod === 'IRW' && shipment.DatumPrevz !== null) || // after return to warehouse
            (shipment.ZStav.ZStav_Kod === 'UD')
            )) || wasDelivery(shipment)) || (shipment.Prevzal !== null && !shipment.InternPickUp);
};

export const wasDelivery = shipment => {
    return shipment.ZStav && shipment.ZStav.ZStav_Kod === "D";
};

export const waitingToDeliveryToBox = shipment => {
    return shipment?.ZStav?.ZStav_Kod === 'IN' && shipment?.ZStav_popis?.ZStav_Popis_Kod === 'PBT';
};

export const isInBox = shipment => {
    return shipment?.ZStav?.ZStav_Kod === 'IN' && (shipment?.ZStav_popis?.ZStav_Popis_Kod === 'PBB' || shipment?.ZStav_popis?.ZStav_Popis_Kod === 'PBM');
};

export const removeAccents = str => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
};

export const isCodeInServices = (services, serviceCode) => {
    if (services != null && Array.isArray(services) && services.length > 0 ) {

        let returnValue = false;
        services.forEach(function (value) {
            if (value.Doplnok_Sluzba_Kod === serviceCode) {
                returnValue = true;
            }
        });

        return returnValue;
    }
    return false;
};

export const getInitFromDate = () => {
    return format(subDays(new Date(), 3), 'dd.MM.yyyy');
}

export const getInitToDate = () => {
    return format(new Date(), 'dd.MM.yyyy');
}

export const getFlagVariant = (iso, city, street, streetNumber, depotFlag) => {
    let variant = null;
    let streetFormatted = null;

    if (street && typeof street !== "undefined") {
        streetFormatted = street.trim().toLocaleLowerCase();
    }

    if (
        depotFlag ||
        (streetFormatted === 'prievozska 34') ||
        (streetFormatted === 'prievozská 34') ||
        (streetNumber && typeof streetNumber !== 'undefined' && (streetFormatted === 'prievozska' || streetFormatted === 'prievozská') && streetNumber.trim() === '34')
    ) {
        variant = 'depo';
    } else if (iso === 'SK') {
        if (city.trim().toLowerCase() === 'bratislava') {
            //variant = 'ba';
            variant = null;
        } else {
            variant = 'sk';
        }

    } else if (iso === 'CZ') {
        variant = 'cz';
    } else if (typeof iso !== "undefined" && iso !== null) {
        variant = 'eu';
    }

    return variant;
}
