import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        '@global': {
            a: {
                'text-decoration': 'none',
            },
        }
    })
);

const GlobalStyles = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
