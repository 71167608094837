import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {Loading} from "./index";
import {errorMessage} from "../utils/messages";

const ALL = loader('../gql/query/client/all.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 16,
    },
}));


export default function CountShipment() {
    const {data, loading} = useQuery(ALL, {
        onError: error => errorMessage('Chyba pri nacitavani: ' + error, enqueueSnackbar, closeSnackbar),
    });
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    if (loading) return <Loading/>;

    let count = null;
    switch (data?.filter.shipingFilter) {
        case 'all':
            count = data?.counts.countAllFilter;
            break;
        case 'not':
            count = data?.counts.countNotFilter;
            break;
        case 'notAndmyToDelivery':
            count = data?.counts.countMyToDeliveryFilter + data?.counts.countNotFilter;
            break;
        case 'my':
            count = data?.counts.countMyFilter;
            break;
        case 'myDelivered':
            count = data?.counts.countMyDeliveredFilter;
            break;
        case 'myToDelivery':
            count = data?.counts.countMyToDeliveryFilter;
            break;
        default:
            console.log('Unknown shipment filter: ', data?.filter.shipingFilter);
    }

    return (
        <div className={classes.root}>
            {count}
        </div>
    );
}
