import React from "react";
import {format, parseISO, isToday} from "date-fns";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 10,
        marginLeft: 'auto',
    },
    differentDate: {
        color: 'red',
    }
}));

export default function TimeSlot({shipment, className}) {
    const classes = useStyles();

    const timeSlotDate = parseISO(shipment.TimeSlot.Date);
    const isNotToday = !isToday(timeSlotDate);
    const dateString = format(new Date(), 'yyyy-MM-dd') + 'T';

    return (
        <div className={clsx(classes.root, className)}>
            {shipment.TimeSlot.Code}
            {shipment.TimeSlot.Date && (
                <span className={clsx(isNotToday && classes.differentDate)}>{format(timeSlotDate, ' d.M.')}</span>
            )}

            {shipment.TimeSlot.TimeFrom && shipment.TimeSlot.TimeTo && (
                format(parseISO(dateString + shipment.TimeSlot.TimeFrom), ' H:mm') + "-" + format(parseISO(dateString + shipment.TimeSlot.TimeTo), 'H:mm')
            )}
        </div>
    );
};
