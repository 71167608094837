import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    root: {
        color: 'black',
        display: 'inline-block',
        position: 'relative',
        padding: '0.5em 0.4em 0.5em 0',
        margin: '-0.5em 0',
        textDecoration: 'none',
    }
}));

export default function A(props) {
    const classes = useStyles();

    return (
        <a className={clsx(props.className, classes.root)} href={props.href} onClick={props.onClick}>
            {props.children}
        </a>
    );
};
