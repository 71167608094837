import React from 'react';
import {useApolloClient, useMutation, gql} from '@apollo/client';
import jwtDecode from 'jwt-decode';
import {LoginForm} from '../components';
import {loader} from "graphql.macro";
import * as Sentry from "@sentry/react";

const LOGIN_USER = loader('../gql/mutations/login.graphql');

export default function Login() {
    const client = useApolloClient();
    const [login, {error}] = useMutation(
        LOGIN_USER,
        {
            errorPolicy: 'all',
            onCompleted({login: token}) {
                if (typeof token !== "undefined" && token !== null) {
                    let decodedToken = jwtDecode(token);

                    if (process.env.REACT_APP_PRODUCTION === 'TRUE' && decodedToken) {
                        Sentry.setUser({...decodedToken, id: decodedToken.uid});
                    }

                    client.writeQuery({
                        query: gql` {
                            isLoggedIn @client
                            token @client
                            user @client {
                                uid
                                kod
                                isAdmin
                                isDispatcher
                                isStockIn
                                vehicleType
                            }
                        }`,
                        data: {
                            isLoggedIn: true,
                            token: token,
                            user: {
                                uid: decodedToken.uid,
                                kod: decodedToken.kod,
                                isAdmin: !!decodedToken.a,
                                isDispatcher: !!decodedToken.d,
                                isStockIn: !!decodedToken.s,
                                vehicleType: decodedToken.v || null,
                                __typename: 'User',
                            }
                        }
                    });
                }
            },
        }
    );

    return <LoginForm login={login} error={error}/>;
}
