import React, { useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {useMutation} from '@apollo/client';
import {Loading} from '../../components/index';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {getInitExchangeState, getExchangeValidationRules, isFormValidated, formatShipmentToExchangeMutation} from "./utils";
import IconButton from "@material-ui/core/IconButton";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MyLocationIcon from '@material-ui/icons/MyLocation';
import MapIcon from '@material-ui/icons/Map';
import {errorMessage, successMessage} from "../../utils/messages";
import {navigate} from "@gatsbyjs/reach-router";
import GeolocationFromLatLng from "../../functions/map/geolocationFromLatLng";
import LocationPickerModal from "../../components/map/locationPickerModal";

const EXCHANGE_MUTATION = loader('../../gql/mutations/exchangeShipment.graphql');
const DELIVERY_EXCHANGE_MUTATION = loader('../../gql/mutations/deliveryExchange.graphql');
const CANCEL_EXCHANGE_MUTATION = loader('../../gql/mutations/cancelExchange.graphql');

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(2),
        maxWidth: 350,
    },
    selectField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(2),
        maxWidth: 350,
        minWidth: 220,
    },
    buttonSuccess: {
        color: theme.colors.greenDomca,
        paddingBottom: 0,
        display: 'inline-block',
    },
    buttonFail: {
        color: theme.colors.redDomca,
        paddingBottom: 0,
        display: 'inline-block',
    },
    icons: {
        fontSize: '1.5em',
    },
}));

export default function ExchangeTab({shipment, dataLocal, callCallbackForSend}) {
    let initValue = getInitExchangeState(shipment, dataLocal?.user?.kod);
    const [values, setValues] = React.useState(initValue);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [sendExchange, {loading}] = useMutation(EXCHANGE_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: data => {
            successMessage('Uspešne preložené.', enqueueSnackbar, closeSnackbar);
        },
    });
    const [sendDeliveryExchange] = useMutation(DELIVERY_EXCHANGE_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: data => {
            successMessage('Uspešne vyložené preloženie.', enqueueSnackbar, closeSnackbar);
            navigate('/');
        },
    });
    const [sendCancelExchange] = useMutation(CANCEL_EXCHANGE_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: data => {
            successMessage('Uspešne zrušené preloženie.', enqueueSnackbar, closeSnackbar);
            navigate('/');
        },
    });
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();

    const validationRules = getExchangeValidationRules(values);

    const handleChange = prop => event => {
        setValues({...values, [prop]: event.target.value});
    };
    const handleDelivery = event => {
        sendDeliveryExchange({
            variables: {
                shipmentId: shipment.Zasielka_Kod,
            },
        });
    };
    const handleCancel = event => {
        sendCancelExchange({
            variables: {
                shipmentId: shipment.Zasielka_Kod,
            },
        });
    };
    const handleFocus = event => event.target.select();

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        const data = formatShipmentToExchangeMutation(values, shipment);
        if (typeof callCallbackForSend === 'function') {
            callCallbackForSend(data, 'exchange');
            return;
        }
        sendExchange({
            variables: data,
        });
    };

    const updateLocation = (address) => {
        let newValue = {
            ...values
        };

        if (address.street) {
            newValue.street = address.street;
        } else {
            newValue.street = '';
        }

        if (address.street_number) {
            newValue.streetNumber = address.street_number;
        } else {
            newValue.streetNumber = '';
        }

        if (address.locality) {
            newValue.city = address.locality;
        } else if (address.admin_2) {
            newValue.city = address.admin_2;
        } else if (address.political) {
            newValue.city = address.political;
        } else {
            newValue.city = '';
        }

        if (address.postal_code) {
            newValue.zip = address.postal_code.replace(/ /g,'');
        } else {
            newValue.zip = '';
        }

        setValues(newValue);
    };

    const handleLocation = (event) => {
        navigator.geolocation.getCurrentPosition(async function(position) {
            const address = await GeolocationFromLatLng(position.coords.latitude, position.coords.longitude);
            updateLocation(address);
        });
    }

    const handleMap = async (position) => {
        const address = await GeolocationFromLatLng(position.lat, position.lng);
        updateLocation(address);
        setOpen(false);
    }

    return (
        <form className={classes.container} autoComplete="on" noValidate>
            {loading && <Loading/>}
            <Grid container spacing={0}>
                <Grid item xs={6} style={{textAlign: 'center'}}>
                    <IconButton onClick={handleCancel} className={classes.buttonFail} disabled={values.disabled || shipment.LastExchange?.Delivered || shipment.LastExchange?.Canceled || shipment.LastExchange === null }>
                        <MailOutlineIcon className={classes.icons}/>
                        Zrušiť
                    </IconButton>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'center'}}>
                    <IconButton onClick={handleDelivery} className={classes.buttonSuccess} disabled={values.disabled || shipment.LastExchange?.Delivered || shipment.LastExchange === null}>
                        <MailOutlineIcon className={classes.icons}/>
                        Vyložiť
                    </IconButton>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <IconButton onClick={handleSubmit} disabled={values.disabled || !isFormValidated(validationRules) || (shipment.LastExchange?.From.Kurier_Kod === dataLocal?.user?.kod && !shipment.LastExchange?.Rejected && !shipment.LastExchange?.Approved)} className={classes.buttonSuccess}>
                        <MailOutlineIcon className={classes.icons}/>
                        Vytvoriť prehadzovanie
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label="Kód kuriera"
                        margin="dense"
                        value={values.messengerCode}
                        onChange={handleChange('messengerCode')}
                        onFocus={handleFocus}
                        disabled={values.disabled}
                        error={validationRules.messengerCode}
                    />
                </Grid>
                <Grid item xs={9} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        required
                        label="Ulica"
                        margin="dense"
                        value={values.street}
                        onChange={handleChange('street')}
                        onFocus={handleFocus}
                        disabled={values.disabled}
                        error={validationRules.street}
                    />
                </Grid>
                <Grid item xs={3} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        required
                        label="popisné č."
                        margin="dense"
                        value={values.streetNumber}
                        onChange={handleChange('streetNumber')}
                        onFocus={handleFocus}
                        disabled={values.disabled}
                        error={validationRules.streetNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        required
                        label="Obec"
                        margin="dense"
                        value={values.city}
                        onChange={handleChange('city')}
                        onFocus={handleFocus}
                        disabled={values.disabled}
                        error={validationRules.city}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        required
                        label="PSC"
                        margin="dense"
                        value={values.zip}
                        onChange={handleChange('zip')}
                        onFocus={handleFocus}
                        disabled={values.disabled}
                        error={validationRules.zip}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <IconButton onClick={handleLocation} className={classes.icons} disabled={values.disabled}>
                        <MyLocationIcon className={classes.icons}/>
                        Moja poloha
                    </IconButton>
                    <IconButton onClick={()=>setOpen(true)} className={classes.icons} disabled={values.disabled}>
                        <MapIcon className={classes.icons}/>
                        Mapa
                    </IconButton>
                    <LocationPickerModal
                        open={open}
                        handleClose={()=>setOpen(false)}
                        handlePosition={handleMap}
                    />
                </Grid>
            </Grid>
        </form>
    );
}
