import React, {Fragment} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {makeStyles} from '@material-ui/core/styles';
import {useQuery} from "@apollo/client";
import {Loading} from '../components/index';
import MySwitch from '../components/switch';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import {errorMessage} from "../utils/messages";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3) + 'px 2px',
    },
    order: {
        width: 176,
        margin: '5px 0',
    },
}));

const GET_FILTER = loader('../gql/query/client/all.graphql');

export default function ShippingSelector() {
    const {data, client, loading, error} = useQuery(GET_FILTER);
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    if (loading) return <Loading/>;
    if (error) {
        errorMessage('Chyba: ' + error, enqueueSnackbar, closeSnackbar);
    }

    const handleSwitch = name => event => {

        let newData = {};
        if (name === 'multiSelect' && !event.target.checked) { // reset selected shipment if turn off multiselect
            newData.multiSelectedShipments = [];
        }

        client.writeQuery({
            query: GET_FILTER,
            data: {
                filter: {
                    ...data.filter,
                    ...newData,
                    [name]: event.target.checked,
                    __typename: 'Filter',
                }
            },
        })
    };

    const handleValue = name => event => {
        client.writeQuery({
            query: GET_FILTER,
            data: {
                filter: {
                    ...data.filter,
                    [name]: event.target.value,
                    __typename: 'Filter',
                }
            },
        });
    };

    return (
        <div>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Filter</FormLabel>
                <RadioGroup aria-label="filterShipment" name="filterShipment" value={data?.filter.shipingFilter}
                            onChange={handleValue('shipingFilter')}>
                    <FormControlLabel value="myDelivered" control={<Radio/>}
                                      label={"Doručené (" + data?.counts.countMyDeliveredFilter + ")"}/>
                    <FormControlLabel value="myToDelivery" control={<Radio/>}
                                      label={"Na doručenie (" + data?.counts.countMyToDeliveryFilter + ")"}/>
                </RadioGroup>

                <FormControl className={classes.order}>
                    <InputLabel>Radenie</InputLabel>
                    {data?.filter.view ? (
                        <Select
                            value={data?.filter.orderSingle}
                            onChange={handleValue('orderSingle')}
                        >
                            <MenuItem value="deliveryTimeNew">Času doručenia najneskoršieho</MenuItem>
                            <MenuItem value="deliveryTimeOld">Času doručenia najskoršieho</MenuItem>
                            <MenuItem value="etaTimeOld">Predpokladaný čas vyzdvihnutia/doručenia od najstarších</MenuItem>
                            <MenuItem value="streetA">Ulica A-Z</MenuItem>
                            <MenuItem value="streetZ">Ulica Z-A</MenuItem>
                            <MenuItem value="nameA">Nazov A-Z</MenuItem>
                            <MenuItem value="nameZ">Nazov Z-A</MenuItem>
                            <MenuItem value="custom">Vlastné</MenuItem>
                            <MenuItem value="route">Trasa</MenuItem>
                        </Select>
                    ) : (
                        <Select
                            value={data?.filter.order}
                            onChange={handleValue('order')}
                        >
                            <MenuItem value="deliveryTimeNew">Času doručenia  od najnovšísch</MenuItem>
                            <MenuItem value="deliveryTimeOld">Času doručenia od najstarších</MenuItem>
                            <MenuItem value="pickupTimeNew">Času vyzdvihnutia od najnovšísch</MenuItem>
                            <MenuItem value="pickupTimeOld">Času vyzdvihnutia od najstarších</MenuItem>
                            <MenuItem value="pickupETAOld">Predpokladaný čas vyzdvihnutia od najstarších</MenuItem>
                            <MenuItem value="deliveryETAOld">Predpokladaný čas doručenia od najstarších</MenuItem>
                            <MenuItem value="streetPA">Ulica vyzdvihnutia A-Z</MenuItem>
                            <MenuItem value="streetPZ">Ulica vyzdvihnutia Z-A</MenuItem>
                            <MenuItem value="streetDA">Ulica doručenia A-Z</MenuItem>
                            <MenuItem value="streetDZ">Ulica doručenia Z-A</MenuItem>
                            <MenuItem value="namePA">Nazov odosieate A-Z</MenuItem>
                            <MenuItem value="namePZ">Nazov odosielate Z-A</MenuItem>
                            <MenuItem value="nameDA">Nazov príjemcu A-Z</MenuItem>
                            <MenuItem value="nameDZ">Nazov príjemcu Z-A</MenuItem>
                            <MenuItem value="nameA">Nazov aktuálny A-Z</MenuItem>
                            <MenuItem value="nameZ">Nazov aktuálny Z-A</MenuItem>
                            <MenuItem value="route">Trasa</MenuItem>
                        </Select>
                    )}
                </FormControl>

                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Zásielky</Grid>
                    <Grid item>
                        <Switch
                            checked={data?.filter.view}
                            onChange={handleSwitch('view')}
                            value="single"/>
                    </Grid>
                    <Grid item>Stopy</Grid>
                </Grid>

                {!data?.filter.view && (
                    <Fragment>
                        <MySwitch label="Multi-select" value="multiSelect"
                                  checked={data?.filter.multiSelect}
                                  onChange={handleSwitch('multiSelect')}/>

                        <MySwitch label="Zgrupovanie" value="grouped"
                                checked={data?.filter.group}
                                onChange={handleSwitch('group')}/>
                    </Fragment>
                )}
            </FormControl>
        </div>
    );
}
