import React, {Fragment} from 'react';
import {useSnackbar} from "notistack";
import {useQuery} from "@apollo/client";
import ShipmentCore from "./shipment/shipmentCore";
import {loader} from "graphql.macro";
import TopBar from "../components/TopBar";
import BackButton from "../components/BackButton";
import MyPaper from "../components/shipment/paper";
import Typography from "@material-ui/core/Typography";
import Value from "../components/shipment/value";
import {makeStyles} from "@material-ui/core/styles";
import {errorMessage} from "../utils/messages";
import {getLastPersistUrl} from "../utils/history/lastUrlPersist";

const ALL = loader('../gql/query/client/all.graphql');
const GET_SHIPMENT_DETAILS = loader('../gql/query/shipmentDetail.graphql');

const useStyles = makeStyles(theme => ({
    id: {
        textAlign: 'center',
        margin: '0 20px'
    },
}));

const ScannerDetail = ({shipmentId, note}) => {
    const classes = useStyles();
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const {data: dataLocal} = useQuery(ALL, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
    });
    const {data, error} = useQuery(
        GET_SHIPMENT_DETAILS,
        {
            variables: {zasielka_kod: shipmentId},
            fetchPolicy: 'cache-and-network',
        }
    );
    if (error) {
        errorMessage('Chyba: '+error, enqueueSnackbar, closeSnackbar);
    }

    let header = <Fragment>Scanner <Value>{shipmentId}</Value></Fragment>;
    if (typeof note !== "undefined") {
        header = <Fragment>ID <Value>{data.shipment.Zasielka_Kod}</Value> Scan <Value>{note}</Value></Fragment>
    }

    let lastUrl = getLastPersistUrl();
    if (typeof lastUrl === "undefined") {
        lastUrl = "/scanner";
    }

    return (
        <div>
            <TopBar showIcon={false}>
                <BackButton path={lastUrl}/>
                <MyPaper className={classes.id}>
                    <Typography variant="h6">
                        {header}
                    </Typography>
                </MyPaper>
            </TopBar>

            {data && data.shipment && <ShipmentCore data={data} dataLocal={dataLocal}/>}
        </div>
    );
};

export default ScannerDetail;
