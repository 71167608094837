import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useMutation} from '@apollo/client';
import {Loading} from '../../components/index';
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {navigate, useMatch} from "@gatsbyjs/reach-router";
import Line from "../../components/shipment/line";
import Button from "@material-ui/core/Button";
import {errorMessage, successMessage} from "../../utils/messages";
import {wasAssignNew, wasPickUp} from "../../utils";


const REJECT_MUTATION = loader('../../gql/mutations/rejectShipment.graphql');

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

export default function RejectTab({shipment}) {
    const classes = useStyles();
    const isScannerUrl = useMatch('/scanner/*');
    const isScannerNoteUrl = useMatch('/scannernote/*');
    const [sendReject, {loading}] = useMutation(REJECT_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: _ => {
            successMessage('Uspešne odmietnuté.', enqueueSnackbar, closeSnackbar);
            if (isScannerUrl || isScannerNoteUrl) {
                navigate('/scanner');
            } else {
                navigate('/');
            }
        },
    });

    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const handleReject = () => {
        sendReject(
            {
                variables: {zasielkaKod: shipment.Zasielka_Kod}
            }
        );
    };

    const disabled = !wasAssignNew(shipment) || wasPickUp(shipment) || shipment.Exchanging;
    return (
        <form className={classes.container} autoComplete="on" noValidate>
            {loading && <Loading/>}
            <Grid container spacing={0}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Line>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            onClick={handleReject}
                            disabled={disabled}
                        >
                            Odmietnutie zasielky (späť dispečerovi)
                        </Button>
                    </Line>
                </Grid>
            </Grid>
        </form>
    );
}
