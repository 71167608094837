import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import clsx from 'clsx';
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";

const ALL = loader('../../gql/query/client/all.graphql');

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',
        display: 'block',
        fontSize: 13,
        //padding: padding * 2 + 'px ' + padding * 10 + 'px',
        padding: '2px 20px 2px 20px',
        boxSizing: 'border-box',
        margin: '1px 0',
        color: 'black',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    selected: {
        boxShadow:'inset 0px 0px 0px 3px #999',
        //boxShadow: 'inset 0px 0px 4px 2px rgba(50, 50, 50, 0.75)',
    },
    big: {
        fontSize: [[18], '!important'],
    },
}));

export default function Wrapper({className, isSelected, ...props}) {
    const classes = useStyles();
    const {data: dataLocal} = useQuery(ALL);

    return (
        <div
            className={clsx(classes.root, className, isSelected && classes.selected, dataLocal.settings.fontSize === 'big' && classes.big)}
            {...props}
        >
            {props.children}
        </div>
    );
};
