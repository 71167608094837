import React, {Fragment, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {navigate} from '@gatsbyjs/reach-router';
import {format, parseISO} from "date-fns";
import TableHead from "@material-ui/core/TableHead";
import CurrencySymbol from "./currencySymbol";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    cellPadding: {
        padding: '2px 10px 2px 6px',
    }
});

export default function Row({row, codDetail, userCode}) {
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    const handleShipmentDetail = (event) => {
        event.preventDefault();
        setOpen(!open);
        navigate(event.currentTarget.href);
    }

    const variableSymbol = format(parseISO(row.Date), 'yyMMdd') + ('00'+userCode).slice(-3); // format RRMMDDKKK

    let sentPaymentArray = [];
    for (const shipment of codDetail) {
        sentPaymentArray.push(shipment.Dobierka.SentPayment);
    }
    const paymentHasTrue = sentPaymentArray.includes(true);
    const paymentHasFalse = sentPaymentArray.includes(false);

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell padding="none" className={classes.cellPadding}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" className={classes.cellPadding}>
                    { format(parseISO(row.Date), 'd.M.yyyy')}
                </TableCell>
                <TableCell component="th" scope="row" padding="none" className={classes.cellPadding}>
                    {variableSymbol}
                </TableCell>
                <TableCell padding="none" className={classes.cellPadding}>
                    {row.Summaries.map(item => {
                        return (
                            <Fragment key={item.Currency}>
                                {Math.round(item.Sum / 5) * 5}<CurrencySymbol currencySymbol={item.Currency}/><br/>
                            </Fragment>
                        )
                    })}
                </TableCell>
                <TableCell padding="none" className={classes.cellPadding}>
                    {row.Summaries.map(item => {
                        return (
                            <Fragment key={item.Currency}>
                                {item.Sum}<CurrencySymbol currencySymbol={item.Currency}/><br/>
                            </Fragment>
                        )
                    })}
                </TableCell>
                <TableCell component="th" scope="row" padding="none" className={classes.cellPadding}>
                    {paymentHasTrue && paymentHasFalse && <span>čiastočne</span>}
                    {paymentHasTrue && !paymentHasFalse && <span>ano</span>}
                    {!paymentHasTrue && paymentHasFalse && <span>nie</span>}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {codDetail && codDetail.length > 0 && (
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="none" className={classes.cellPadding}>Kod</TableCell>
                                            <TableCell padding="none" className={classes.cellPadding}>Platca zasielky</TableCell>
                                            <TableCell padding="none" className={classes.cellPadding}>Suma</TableCell>
                                            <TableCell padding="none" className={classes.cellPadding}>Pripisana platba</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {codDetail.map((shipment) => (
                                            <TableRow key={shipment.Zasielka_Kod}>
                                                <TableCell component="th" scope="row" padding="none" className={classes.cellPadding}>
                                                    <a href={`/shipment/${shipment.Zasielka_Kod}`}
                                                       onClick={handleShipmentDetail}>
                                                        {shipment.Zasielka_Kod}
                                                    </a>
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none" className={classes.cellPadding}>
                                                    {shipment.PayerCompany && (shipment.PayerCompany.Nazov)} {shipment.PayerContactPerson && (
                                                    <Fragment>{shipment.PayerContactPerson.Nazov} {shipment.PayerContactPerson.Priezvisko}</Fragment>)}
                                                </TableCell>
                                                <TableCell padding="none" className={classes.cellPadding}>
                                                    {shipment.Dobierka.Amount}{shipment.Dobierka.Currency && (shipment.Dobierka.Currency)}
                                                </TableCell>
                                                <TableCell padding="none" className={classes.cellPadding}>
                                                    {shipment.Dobierka.SentPayment ? 'Ano' : 'Nie'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
