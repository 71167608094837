import { Component } from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends Component {
    componentDidCatch(err, info) {
        if (process.env.REACT_APP_PRODUCTION === 'TRUE') {
            Sentry.withScope((scope) => {
                scope.setExtras(info);
                Sentry.captureException(err);
            });
        }
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
