import React, {Fragment} from "react";
import {isToday} from "date-fns";


export default function SmsTimeWrapper({shipment, children}) {

    if (shipment.DeliveryTimeFrom === null || shipment.DeliveryTimeTo === null || !isToday(shipment.DeliveryTimeFrom) || !isToday(shipment.DeliveryTimeTo)) {
        return (null);
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    );
};