import {makeStyles} from '@material-ui/core/styles';
import React from "react";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        margin: '0px 0 7px 0',
        color: theme.colors.header,
        textTransform: 'uppercase',
        width: '100%',
    }
}));

export default function Header(props) {
    const classes = useStyles();

    return (
        <Typography variant="h6" className={classes.root}>
                {props.children}
        </Typography>
    );
};