import React, {Fragment, useState} from 'react';
import {loader} from "graphql.macro";
import {makeStyles} from "@material-ui/core/styles";
import {Loading} from "../../components";
import {useQuery} from "@apollo/client";
import {format, parse, parseISO, isToday, isYesterday, subDays} from "date-fns";
import MyPaper from "../../components/shipment/paper";
import ShiftsData from "./shiftsData";
import ShiftDetail from "./shiftDetail";
import Fab from "@material-ui/core/Fab";
import BackButton from "../../components/BackButton";
import Wrapper from "../../components/route/Wrapper";

const GET_SHIFTS = loader('../../gql/query/shifts.graphql');


const useStyles = makeStyles(theme => ({
    wrapping: {
        marginTop: -92,
    }
}));


const Shifts = () => {
    const classes = useStyles();
    const [shiftForEdit, setShiftForEdit] = useState(null);

    const from = format(subDays(new Date(), 14), 'yyyy-MM-dd');
    const to = format(new Date(), 'yyyy-MM-dd');

    const {data, loading} = useQuery(GET_SHIFTS, {
        fetchPolicy: "cache-and-network", variables: {
            fromDate: from, toDate: to,
        }, //skip: loadingLocal,
        //onError: error => errorMessage('Chyba pri načítavaní: ' + error, enqueueSnackbar, closeSnackbar),
    });

    const handleClick = (e, shift) => {
        e.stopPropagation();
        e.preventDefault();
        const shiftDate = parseISO(shift.from);
        if (isToday(shiftDate) || isYesterday(shiftDate)) {
            setShiftForEdit(shift);
        }
    }

    if (shiftForEdit) {
        return <ShiftDetail
            shift={shiftForEdit}
            onCancel={() => setShiftForEdit(null)}
            onSubmit={() => setShiftForEdit(null)}
        />;
    }

    return (<Fragment>
        <Wrapper className={classes.wrapping}>
            {(loading) && <Loading/>}
            <MyPaper className={classes.block}>

                {data?.shifts && (<ShiftsData
                    shifts={data?.shifts}
                    onShiftClick={handleClick}>
                </ShiftsData>)}

            </MyPaper>
            <Fab color="primary" style={{position: 'fixed', top: '4%', right: '6%', zIndex: 100}}>
                <BackButton/>
            </Fab>
        </Wrapper>
    </Fragment>)
};

export default Shifts;

