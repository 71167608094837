import actions from "./actions";
import {have18} from "./utils";
import disabledSlots from "./disabledWindowSlots";
import {addWorkingDays} from "../../../utils/datetime/utils";
import successStatus from "./successStatus";

const successDeliveryButtonClick = (state, values) => {
    if (state.values.isZS) {
        return {
            ...state,
            values: {
                ...state.values,
                ...values,
            },
            openSuccessDeliveryAdditional: true,
        }
    } else {
        return {
            ...state,
            values: {
                ...state.values,
                ...values,
                deliveryCode: 'DGC',
                delivered: true,
            },
            openFailDeliveryModal: false,
            canSend: true,
            openDeliveryAdditional: false,
            openDateModal: false,
        };
    }
};
const deliveryReducer = (state, action) => {
    switch (action.type) {

        case actions.successDeliveryButtonClick: {
            return successDeliveryButtonClick(state);
        }

        case actions.handleInputText: {

            let noteClientBeforeEdit = state.values.noteClientBeforeEdit;
            let wasClientNoteEdited = state.values.wasClientNoteEdited;

            if (action.field === 'noteClient') {
                if (!state.values.wasClientNoteEdited) {
                    noteClientBeforeEdit = state.values.noteClient;
                    wasClientNoteEdited = true;
                }
            }

            return {
                ...state,
                values: {
                    ...state.values,
                    [action.field]: action.payload,
                    wasClientNoteEdited,
                    noteClientBeforeEdit,
                }
            }
        }

        case actions.handlePinCodeInput: {
            if (action.payload.length >= 4) {
                return successDeliveryButtonClick(state, {pinCode: action.payload});
            }

            return {
                ...state,
                values: {
                    ...state.values,
                    pinCode: action.payload,
                }
            }
        }

        case actions.handleAdditionalTextInfo: {
            return {
                ...state,
                values: {
                    ...state.values,
                    additionalInfo: {
                        ...state.values.additionalInfo,
                        text: action.payload,
                    },
                }
            }
        }

        case actions.openFailDelivery: {
            return {
                ...state,
                values: {...state.values, 'delivered': false, 'deliveryCode': 'COF', 'additionalInfo': {}},
                openFailDeliveryModal: true
            }
        }

        case actions.closeFailDelivery: {
            return {
                ...state,
                values: {
                    ...state.values,
                    deliveryCode: 'DGC',
                    delivered: true,
                },
                openFailDeliveryModal: false
            }
        }

        case actions.closeAdditionalModal: {
            return {
                ...state,
                values: {
                    ...state.values,
                    deliveryCode: 'DGC',
                    delivered: true,
                    additionalInfo: {},
                },
                openDeliveryAdditional: false
            }
        }

        case actions.helpClickModal: {
            return {
                ...state,
                values: {
                    ...state.values,
                    additionalInfo: {
                        ...state.values.additionalInfo,
                        text: 'nezadane',
                    },
                },
                openDeliveryAdditional: false,
                openDateModal: true,
                canSend: false,
            }
        }

        case actions.handleCOD: {
            return {
                ...state,
                values: {...state.values, 'CODPaymentType': action.payload},
                additionalConditions: {...state.additionalConditions, 'COD': action.payload},
            }
        }

        case actions.handleAdditionalConditions: {
            return {
                ...state,
                additionalConditions: {
                    ...state.additionalConditions,
                    [action.field]: action.payload,
                },
            }
        }

        case actions.sent: {
            return {
                ...state,
                canSend: false,
            }
        }

        case actions.okClickDateModal: {
            return {
                ...state,
                openDateModal: false,
                canSend: true,
            }
        }

        case actions.okClickModal: {

            let values = state.values;
            if (state.values.deliveryCode === 'WA') {
                values.additionalInfo.slot = 'TSA0Z';
                values.additionalInfo.date = addWorkingDays(new Date(), 1);
            }

            return {
                ...state,
                values,
                openDeliveryAdditional: false,
                canSend: state.values.deliveryCode !== 'WA',
                openDateModal: state.values.deliveryCode === 'WA',
            }
        }

        case actions.setDate: {
            if (have18(action.payload)) {
                return {
                    ...state,
                    values: {...state.values, birthDate: action.payload},
                    additionalConditions: {...state.additionalConditions, 'OV': true},
                }
            }

            return {
                ...state,
                values: {...state.values, birthDate: action.payload},
                additionalConditions: {...state.additionalConditions, 'OV': false},
            };
        }

        case actions.closeDateModal: {
            return {
                ...state,
                values: {
                    ...state.values,
                    deliveryCode: 'DGC',
                    delivered: 'D',
                    additionalInfo: {},
                },
                openDateModal: false
            }
        }

        case actions.changeDateModal: {
            const date = action.payload;
            const slots = disabledSlots(date);
            return {
                ...state,
                ...slots,
                values: {
                    ...state.values,
                    additionalInfo: {
                        ...state.values.additionalInfo,
                        date: action.payload,
                        slot: null,
                    },
                },
            }
        }

        case actions.setDeliveryCode: {
            let canSend = true;
            let openDeliveryAdditional = false;
            let openDateModal = false;
            let additionalInfo = {
                ...state.values.additionalInfo
            };

            if (['OTH', 'WA'].includes(action.payload)) {
                openDeliveryAdditional = true;
                canSend = false;
            } else if ('UCD' === action.payload) {
                openDateModal = true;
                canSend = false;
                additionalInfo.date = addWorkingDays(new Date(), 1);
                additionalInfo.slot = 'TSA0Z';
            }

            return {
                ...state,
                values: {
                    ...state.values,
                    additionalInfo,
                    deliveryCode: action.payload,
                    delivered: !!successStatus.find(status => status.code === action.payload),
                },
                openFailDeliveryModal: false,
                canSend,
                openDeliveryAdditional,
                openDateModal,
            };
        }

        case actions.setDeliveryTimeSlot: {
            return {
                ...state,
                values: {
                    ...state.values,
                    additionalInfo: {
                        ...state.values.additionalInfo,
                        slot: action.payload,
                    },
                },
            }
        }

        case actions.closeSuccessDeliveryAdditionalModal: {
            return {
                ...state,
                openSuccessDeliveryAdditional: false,
                values: {
                    ...state.values,
                    deliveryCode: 'DGC',
                    delivered: 'D',
                    additionalInfo: {},
                },
            }
        }

        case actions.yesClickEditClientNoteModal: {
            return {
                ...state,
                openEditClientNoteModal: false,
                values: {
                    ...state.values,
                    noteClientBeforeEdit: null,
                    wasClientNoteEdited: false,
                }
            }
        }

        case actions.openEditClientNoteModal: {
            return {
                ...state,
                openEditClientNoteModal: state.values.wasClientNoteEdited, //not open dialog if note was not changed
            }
        }

        case actions.closeEditClientNoteModal: {
            return {
                ...state,
                values: {
                    ...state.values,
                    noteClientBeforeEdit: null,
                    wasClientNoteEdited: false,
                    noteClient: state.values.noteClientBeforeEdit,
                },
                openEditClientNoteModal: false,
            }
        }

        default: {
            throw new Error(`Unhandled type: ${action.type}`)
        }
    }
};

export default deliveryReducer;
