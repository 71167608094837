import React from 'react';
import TabPanel from '../../../components/tabPanel';
import DeliveryTab from '../delivery';

export default function DeliveryTabWrapper({data, dataLocal, value}) {
    return (
        <TabPanel value={value} index={2}>
            <DeliveryTab shipment={data.shipment} dataLocal={dataLocal}/>
        </TabPanel>
    );
}