import React, {Fragment} from "react";
import {format} from "date-fns";


export default function SmsTime({shipment, addSpaces = false}) {

    let spaces = '-';
    if (addSpaces) {
        spaces = ' - ';
    }

    return (
        <Fragment>
            {format(shipment.DeliveryTimeFrom, 'H:mm')}{spaces}{format(shipment.DeliveryTimeTo, 'H:mm')}
        </Fragment>
    );
};