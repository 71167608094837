import Button from "@material-ui/core/Button";
import React from "react";

const SuccessfulMessage = (message, enqueueSnackbar, closeSnackbar, options) => {
        enqueueSnackbar(message, {
        variant: 'success',
        autoHideDuration: '1000',
        action: (key) => (
            <Button  style={{color: "white"}} onClick={() => {
                closeSnackbar(key);
            }}>X</Button>
        ),
        ...options,
    });
}

export default SuccessfulMessage;
