import { Link } from "@gatsbyjs/reach-router";
import React from "react";
import Wrapper from "./Wrapper";

export default function Finished() {
    return (
        <Wrapper>
            Trasa na daný deň bola uzavretá a nie je možné ju meniť. Mozete vytvorit <Link to="/route/step1">novu trasu</Link>.
        </Wrapper>
    );
};
