import React from "react";
import Button from "@material-ui/core/Button";
import {useMutation} from "@apollo/client";
import {loader} from "graphql.macro";
import {useSnackbar} from "notistack";
import {errorMessage, successMessage} from "../../utils/messages";


const ACK_EXCHANGE_MUTATION = loader('../../gql/mutations/ackExchangeShipment.graphql');

export default function AckButton({shipmentCode}) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [sendAckExchange] = useMutation(ACK_EXCHANGE_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: () => successMessage('Prekladanie potvrdené.', enqueueSnackbar, closeSnackbar),
        refetchQueries: () => ['shipmentList'],
    });

    return (
        <Button
            variant="contained"
            onClick={() => sendAckExchange({
                variables: {zasielkaKod: shipmentCode},
            })}
        >
            Akceptovať & Vyzdvihnúť
        </Button>
    );
};
