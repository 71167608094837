import React, {Fragment} from 'react';
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Loading, ShippingTile} from "../components";
import Divider from "@material-ui/core/Divider";
import TopBar from "../components/TopBar";
import BackButton from "../components/BackButton";
import MyPaper from "../components/shipment/paper";
import Typography from "@material-ui/core/Typography";
import Value from "../components/shipment/value";
import {persistActualUrl} from "../utils/history/lastUrlPersist";

const GET_SHIPMENT_SCANNER_NOTE = loader('../gql/query/shipmentScannerNote.graphql');
const GET_FILTER = loader('../gql/query/client/filter.graphql');

const useStyles = makeStyles(theme => ({
    code: {
        textAlign: 'center',
        fontSize: '20px',
    },
    id: {
        textAlign: 'center',
        margin: '0 20px'
    },
}));

const ScannerSearchNote = ({note}) => {
    const classes = useStyles();
    const {data: dataLocal} = useQuery(GET_FILTER);
    const {data, loading} = useQuery(GET_SHIPMENT_SCANNER_NOTE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            note: note,
            fromDate: dataLocal.fromDate,
            toDate: dataLocal.toDate,
        },
    });

    persistActualUrl();

    return (
        <Fragment>
            <TopBar showIcon={false}>
                <BackButton path="/scanner"/>
                <MyPaper className={classes.id}>
                    <Typography variant="h6">
                        Scanner <Value>{note}</Value>
                    </Typography>
                </MyPaper>
            </TopBar>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
            >
                {loading && <Loading/>}
                {!loading && (!data || !data.shipmentNoteSearch || data.shipmentNoteSearch.length === 0) &&
                <p>ziadne zasielky sa nenasli</p>}
                {data && data.shipmentNoteSearch &&
                data.shipmentNoteSearch.map(shipment => (
                    <Grid
                        className={classes.gridCell}
                        container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={2} key={shipment.Zasielka_Kod}>
                        <ShippingTile
                            shipment={shipment}
                            key={shipment.Zasielka_Kod}
                        />
                        <Divider className={classes.divider}/>
                    </Grid>
                ))
                }
            </Grid>
        </Fragment>
    );
};

export default ScannerSearchNote;
