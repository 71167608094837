import actions from "./actions";

const deliveryBBReducer = (state, action) => {
    switch (action.type) {
        case actions.handleAdditionalConditions: {
            return {
                ...state,
                additionalConditions: {
                    ...state.additionalConditions,
                    [action.field]: action.payload,
                },
            }
        }

        case actions.successDeliveryButtonClick: {
            return {
                ...state,
                values: {
                    ...state.values,
                    delivered: true,
                    deliveryCode: 'PBL',
                },
            }
        }

        case actions.handleSelectBox: {
            return {
                ...state,
                selectedBox: action.payload,
            }
        }

        case actions.handleSelectSlot: {
            return {
                ...state,
                selectedSlot: action.payload === state.selectedSlot ? null : action.payload,
            }
        }

        case actions.handleChangeDate: {
            return {
                ...state,
                reservationDate: action.payload,
            }
        }

        case actions.handleChangeTime: {
            return {
                ...state,
                reservationDate: action.payload,
            }
        }

        default: {
            throw new Error(`Unhandled type: ${action.type}`)
        }
    }
};

export default deliveryBBReducer;
