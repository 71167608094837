import smsImported from "./sms.json";

const replace = [
    {
        'placeHolder': 'ULICAD',
        'variable': 'shipment',
        'value': 'UlicaD',
    },
    {
        'placeHolder': 'ULICAO',
        'variable': 'shipment',
        'value': 'UlicaO',
    },
    {
        'placeHolder': 'KURIER_SIGN',
        'variable': 'user',
        'value': 'vehicleType',
    },
    {
        'placeHolder': 'PAYERCOMPANYNAZOV',
        'value': 'PayerCompany.Nazov',
    },
];

const toBasicLatin = (str) => {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '');
}

const replaceText = (text, shipment, user) => {
    for (const key in replace){
        const smsReplaceObject = replace[key];
        const valueKey = smsReplaceObject.value.split('.');
        const regex = new RegExp("\\${" + smsReplaceObject.placeHolder + "}", 'g');

        if (smsReplaceObject.placeHolder === 'KURIER_SIGN') {
            let courier = 'Kurier';
            if (user && user?.vehicleType) {
                courier = user.vehicleType === 'Car' ? 'GO4 kurier' : 'Cyklokurier Svihaj Suhaj';
            }

            text = text.replace(regex, courier);
        } else {
            text = text.replace(regex, toBasicLatin(valueKey.reduce((acc, current) => acc[current], shipment) || ''));
        }
    }

    return text;
}

const getAllSMS = (shipment, user, isPickup) => {
    let sms = smsImported.smsDelivery;
    if (isPickup) {
        sms = smsImported.smsPickup;
    }

    return sms.map((sms) => {
        return {...sms, value:  replaceText(sms.value,shipment, user)};
    });
}

const getSMSByKey = (key, shipment, user, isPickUp) => {
    let sms = smsImported.smsDelivery;
    if (isPickUp) {
        sms = smsImported.smsPickup;
    }

    const smsObject = sms.find(sms => sms.label === key);
    return replaceText(smsObject.value,shipment, user);
}

export {
    getAllSMS,
    getSMSByKey
};
