import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useMatch} from "@gatsbyjs/reach-router";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    leftDrawer: {
        padding: '15px',
    },
    toolbar: theme.mixins.toolbar,
}));

export default function LeftMenu({handleReloadClick}) {
    const classes = useStyles();
    const isStep1 = useMatch('step1');
    const isStep2 = useMatch('step2');
    const isStep3 = useMatch('step3');

    return (
        <div className={`${classes.root} ${classes.leftDrawer}`}>
            <div>
                <Typography variant="h5">Napoveda</Typography>
                {isStep1 && (
                    <Fragment>
                        <p>V tomto kroku vyberte kedy chcete zacat dorucovat trasu. Cas mozete este v poslednom kroku menit.</p>
                        <p>Pocet minut sa odporuca zacinajucim kurierom nastavit na 3-4min, pre najskusenejsich na -1</p>
                        <p>Indidualny koeficient kuriera nastavuje rychlost presunu medzi stopmi</p>
                    </Fragment>
                )}

                {isStep2 && (
                    <Fragment>
                        <p>Vyberte zasielky ktore maju byt v trase. Zasielku nie je mozne vybrat v pripadne ak casove okno uz bolo resp nestiha sa dorucit.</p>
                        <p>Ak zasielka nema geolokaciu, pripadne je chybna je mozne kliknutim na ulicu nastavit presny bod kde sa zasielka nachadza, pripadne ho zmenit.</p>
                    </Fragment>
                )}

                {isStep3 && (
                    <Fragment>
                        <p>V tomto kroku uvidite zasielky naplanovane podla poradia ako pojdu v trase. Zaroven vidite ETD (estimated time delivery) - cas dorucovania podla trasy. Mozete si poradie zasielok lubovolne prehadzovat alebo individualne menit dobu prestojov napr na obed.</p>
                        <p>V pripade ak chcete robit viac zmien v trase odporucame vypnut automaticke preratavanie po kazdej zmene v menu, urobit vsetky zmeny a nasledne spustit preratanie manualne.</p>
                        <p>Ak ste s trasou spokojny mozete dat ukoncit trasu a ta sa ulozi.</p>
                    </Fragment>
                )}
            </div>
            <div className={classes.toolbar}/>
        </div>
    );
}
