import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useState} from "react";
import Tab from "@material-ui/core/Tab";
import {PackageUp} from "mdi-material-ui";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {wasDelivery, wasAssign, wasPickUp, isSystemCourier} from '../../utils';
import DeliveryTab from "../../pages/shipment/delivery";
import {loader} from "graphql.macro";
import {useMutation, useLazyQuery, useQuery} from "@apollo/client";
import {
    checkAdditionalConditions,
    isFormValidated,
    initPickUpState,
    getPickUpValidationRules,
    getDefaultPickUpCondition,
    formatShipmentToPickUpMutation,
    getInitExchangeState,
    getExchangeValidationRules,
    formatShipmentToExchangeMutation,
} from "../../pages/shipment/utils";
import {
    formatShipmentToDeliveryMutation,
    getInitDeliveryState,
    getDeliveryValidationRules, getDefaultDeliveryCondition
} from "../../pages/shipment/delivery/utils";
import PickUpTab from "../../pages/shipment/pickUpTab";
import {useSnackbar} from "notistack";
import Header from "../shipment/header";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PickUp from "../icons/pickUp";
import Delivery from "../icons/delivery";
import {errorAutoHideMessage as errorMessage, successMessage} from "../../utils/messages";
import ExchangeTab from "../../pages/shipment/exchangeTab";
import Reject from "../icons/reject";
import Tabs from "@material-ui/core/Tabs";
import DepotDispersing from "../icons/depotDispersing";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as Sentry from "@sentry/react";
import SelectAll from "./selectAll";
import UnselectAll from "./unselectAll";
import StockIn from "../icons/stockIn";
import {isMallPayer} from "../../utils/shipments";

const ABOUT_USER = loader('../../gql/query/client/aboutUser.graphql');
const DELIVERY_BATCH = loader('../../gql/query/shipmentsBatchDelivery.graphql');
const PICKUP_BATCH = loader('../../gql/query/shipmentsBatchPickUp.graphql');
const ASSIGN_BATCH = loader('../../gql/query/shipmentsBatchAssign.graphql');
const REJECT_BATCH = loader('../../gql/query/shipmentsBatchReject.graphql');
const EXCHANGE_BATCH = loader('../../gql/query/shipmentsBatchExchange.graphql');
const DEPO_BATCH = loader('../../gql/query/shipmentsBatchDepo.graphql');

const DELIVERY_MUTATION = loader('../../gql/mutations/deliveryShipment.graphql');
const PICKUP_MUTATION = loader('../../gql/mutations/pickUpShipment.graphql');
const ASSIGN_MUTATION = loader('../../gql/mutations/assignShipment.graphql');
const REJECT_MUTATION = loader('../../gql/mutations/rejectShipment.graphql');
const EXCHANGE_MUTATION = loader('../../gql/mutations/exchangeShipment.graphql');
const DEPOT_DISPERSING_MUTATION = loader('../../gql/mutations/returnDispersingShipmentToDepot.graphql');
const RECEIVE_DEPOT_MUTATION = loader('../../gql/mutations/receiveDepot.graphql');

const useStyles = makeStyles(theme => ({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        [theme.breakpoints.up('sm')]: {
            left: theme.custom.main.drawerWidth,
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    okDialogButton: {
        color: theme.colors.greenDomca,
    },
    cancelDialogButton: {
        color: theme.colors.redDomca,
    },
    selectButtons: {
        margin: '0 3px',
        fontSize: '0.7rem',
        padding: '1px 8px',
        lineHeight: 1.45,
    }
}));

export default function MultiSelectPanel({selectedShipments, allowAssign = false, removeFromMultiselect}) {
    const defaultState = {
        openDialog: false,
        action: null,
        id: null,
        idsToProcess: [],
        dataToSend: {},
        open: false,
        dataByCode: null,
        exchangeToMessenger: null,
        exchangeStreet: '',
        exchangeStreetNumber: '',
        exchangeCity: '',
        exchangeZip: '',
    };

    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {data} = useQuery(ABOUT_USER, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
    });
    const [state, setState] = useState(defaultState);
    const context = {
        batch: true,
    };

    const onSuccessProcess = async (shipment) => {
        if (typeof removeFromMultiselect !== "undefined") {
            if (isMallPayer(shipment.PayerCompany.Firma_Kod)) {
                await removeFromMultiselect(new RegExp("^" + shipment.customerIdentification + "([0-9]{3})$"));
            } else {
                await removeFromMultiselect(shipment.Zasielka_Kod);
                await removeFromMultiselect(shipment.customerIdentification);
            }
        }

        //setState(defaultState);
    };

    const [sendDelivery] = useMutation(DELIVERY_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: async (data) => {
            successMessage('Uspešne doručená zásielka ' + data.deliverypShipment.Zasielka_Kod, enqueueSnackbar, closeSnackbar);
            await onSuccessProcess(data.deliverypShipment);
        },
        refetchQueries: () => ['shipmentList'],
        awaitRefetchQueries: true,
        context,
    });
    const [sendPickUp] = useMutation(PICKUP_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted:  async (data) => {
            successMessage('Uspešne vyzdvihnutá zásielka ' + data.pickUpShipment.Zasielka_Kod, enqueueSnackbar, closeSnackbar);
            await onSuccessProcess(data.pickUpShipment);
        },
        refetchQueries: () => ['shipmentList'],
        awaitRefetchQueries: true,
        context,
    });
    const [sendAssign] = useMutation(ASSIGN_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: async (data) => {
            successMessage('Uspešne priradená zásielka ' + data.assignShipment.Zasielka_Kod, enqueueSnackbar, closeSnackbar);
            await onSuccessProcess(data.assignShipment);
        },
        refetchQueries: () => ['shipmentList'],
        awaitRefetchQueries: true,
        context,
    });
    const [sendReject] = useMutation(REJECT_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: async (data) => {
            successMessage('Uspešne odmietnutá zásielka.', enqueueSnackbar, closeSnackbar);
        },
        refetchQueries: () => ['shipmentList'],
        awaitRefetchQueries: true,
        context,
    });
    const [sendExchange] = useMutation(EXCHANGE_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: async (data) => {
            successMessage('Uspešne preložená zásielka ' + data.exchangeShipment.Zasielka_Kod, enqueueSnackbar, closeSnackbar);
            await onSuccessProcess(data.exchangeShipment);
        },
        refetchQueries: () => ['shipmentList'],
        awaitRefetchQueries: true,
        context,
    });
    const [sendDepotDispersing] = useMutation(DEPOT_DISPERSING_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: async (data) => {
            if (!data.returnDispersingShipmentToDepot) {
                Sentry.captureException(JSON.stringify(data));

                successMessage('Rozvozová zasielka bola vratená do depa', enqueueSnackbar, closeSnackbar);
            } else {
                successMessage('Rozvozová zasielka ' + data.returnDispersingShipmentToDepot.Zasielka_Kod + ' vratená do depa', enqueueSnackbar, closeSnackbar);
            }

            await onSuccessProcess(data?.returnDispersingShipmentToDepot);
        },
        refetchQueries: () => ['shipmentList'],
        awaitRefetchQueries: true,
        context,
    });
    const [receiveDepot] = useMutation(RECEIVE_DEPOT_MUTATION, {
        onError: error => errorMessage('Chyba pri odosielaní: ' + error, enqueueSnackbar, closeSnackbar),
        onCompleted: async (data) => {
            successMessage('Zasielka ' + data.receiveDepot.Zasielka_Kod + ' prijatá na sklad', enqueueSnackbar, closeSnackbar);
            await onSuccessProcess(data.receiveDepot);
        },
        refetchQueries: () => ['shipmentList'],
        awaitRefetchQueries: true,
        context,
    });

    const prepareAndCheckDataForMutation = () => {
        const idsToProcess = [...state.idsToProcess];
        let id = null;
        let dataToSend = {};
        let initState = null;
        let couldSend = true;
        let shipment = null;
        let validationRules = null;

        while ((id = idsToProcess.pop()) != null && couldSend === true) {
            shipment = state.dataByCode[id];
            if (!shipment) {
                errorMessage('Zasielka ' + id + ' nebola najdena.', enqueueSnackbar, closeSnackbar);
                continue;
            }

            switch (state.action) {
                case "delivery":
                    if (!wasPickUp(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' ešte nebola vyzdvihnutá.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    if (wasDelivery(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' už bola doručená.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }

                    initState = getInitDeliveryState(shipment, data?.user?.kod);
                    validationRules = getDeliveryValidationRules(initState);
                    couldSend = !initState.disabled && checkAdditionalConditions(getDefaultDeliveryCondition(shipment)) && isFormValidated(validationRules) && !initState.isZS;
                    if (couldSend) {
                        dataToSend[id] = formatShipmentToDeliveryMutation(initState, shipment, true, 'DGC');
                    }

                    break;

                case "pickUp":
                    if (!wasAssign(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' nie je priradená, preto nemože byť vyzdvihnutá.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    if (wasPickUp(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' už bola vyzdvihnuta.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    if (wasDelivery(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' už bola doručená.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }

                    initState = initPickUpState(shipment);
                    validationRules = getPickUpValidationRules(initState);
                    couldSend = !initState.disabled && checkAdditionalConditions(getDefaultPickUpCondition(shipment)) && isFormValidated(validationRules);
                    if (couldSend) {
                        dataToSend[id] = formatShipmentToPickUpMutation(initState, shipment, 'PGC');
                    }
                    break;

                case "assign":
                    if (wasAssign(shipment) && !isSystemCourier(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' už bola priradená.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    dataToSend[shipment.Zasielka_Kod] = shipment.Zasielka_Kod;
                    couldSend = true;
                    break;

                case "reject":
                    if (!wasAssign(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' ešte nebola priradená.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    if (wasPickUp(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' už bola vyzdvihnuta.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    if (shipment.Exchanging) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' je v procese prekladania.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    dataToSend[shipment.Zasielka_Kod] = shipment.Zasielka_Kod;
                    couldSend = true;
                    break;

                case "exchange":
                    if (!wasPickUp(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' ešte nebola vyzdvihnutá.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    if (wasDelivery(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' už bola doručená.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }

                    initState = getInitExchangeState(shipment, data?.user?.kod);
                    initState['messengerCode'] = state.exchangeToMessenger; // we want show only one modal window for get messenger code to exchange for all shipments
                    initState['street'] = state.exchangeStreet;
                    initState['streetNumber'] = state.exchangeStreetNumber;
                    initState['city'] = state.exchangeCity;
                    initState['zip'] = state.exchangeZip;

                    validationRules = getExchangeValidationRules(initState);
                    couldSend = !initState.disabled && isFormValidated(validationRules);
                    if (couldSend) {
                        dataToSend[id] = formatShipmentToExchangeMutation(initState, shipment);
                    }
                    break;

                case "depotDispersing":
                    if (!wasPickUp(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' ešte nebola vyzdvihnutá.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    if (wasDelivery(shipment)) {
                        errorMessage('Zasielka ' + shipment.Zasielka_Kod + ' už bola doručená.', enqueueSnackbar, closeSnackbar);
                        continue;
                    }
                    dataToSend[shipment.Zasielka_Kod] = shipment.Zasielka_Kod;
                    couldSend = true;
                    break;
                case "receiveDepot":
                    dataToSend[shipment.Zasielka_Kod] = shipment.Zasielka_Kod;
                    couldSend = true;
                    break;

                default:
                    console.error(state);
                    throw new Error("Invalid action");
            }

            if (!couldSend) {

                setState((previousState) => {
                    return {
                        ...previousState,
                        id,
                        idsToProcess,
                        dataToSend: {...previousState.dataToSend, ...dataToSend},
                        open: true,
                    }
                });

                return;
            }
        }

        // merge actual dataToSend with data from state - in case when dont need open additional popup
        dataToSend = {...state.dataToSend, ...dataToSend};

        if (idsToProcess.length === 0 && Object.keys(dataToSend).length > 0) {
            switch (state.action) {
                case "delivery":
                    Object.keys(dataToSend).forEach(function (key) {
                            sendDelivery({
                            variables: dataToSend[key],
                        });
                    });
                    break;

                case "pickUp":
                    Object.keys(dataToSend).forEach(function (key) {
                        sendPickUp({
                            variables: dataToSend[key],
                        });
                    });
                    break;
                case "assign":
                    Object.keys(dataToSend).forEach(function (key) {
                        sendAssign(
                            {
                                variables: {
                                    zasielkaKod: dataToSend[key]
                                },
                            });
                    });
                    break;
                case "reject":
                    Object.keys(dataToSend).forEach(async function (key) {
                        sendReject(
                            {
                                variables: {
                                    zasielkaKod: dataToSend[key]
                                },
                            });
                        // clean selected shipment, because in reject shipment dont delete shipment from selection after succ response because dont shipment dont have id and permission after reject
                        await onSuccessProcess(state.dataByCode[key]);
                    });
                    break;
                case "exchange":
                    Object.keys(dataToSend).forEach(function (key) {
                        sendExchange({
                            variables: dataToSend[key],
                        });
                    });
                    break;
                case "depotDispersing":
                    Object.keys(dataToSend).forEach(function (key) {
                        sendDepotDispersing(
                            {
                                variables: {
                                    zasielkaKod: dataToSend[key]
                                },
                            });
                    });
                    break;
                case "receiveDepot":
                    Object.keys(dataToSend).forEach(function (key) {
                        receiveDepot(
                            {
                                variables: {
                                    shipmentId: dataToSend[key]
                                },
                            });
                    });
                    break;
                default:
                    console.error(state);
                    throw new Error("Invalid action");
            }
        }
    };

    const lazyOptions = {
        fetchPolicy: 'network-only',
        onCompleted: function (data) {
            if (data.shipmentsByCode && state.dataByCode === null) {
                let dataByCode = {};
                data.shipmentsByCode.forEach(function (shipment) {
                    dataByCode[shipment.Zasielka_Kod] = shipment;
                });
                setState((previousState) => {
                    return {
                        ...previousState,
                        openDialog: false,
                        dataByCode,
                        idsToProcess: data.shipmentsByCode.map(shipment => shipment.Zasielka_Kod),
                    }
                });
            }
        },
        onError: error => errorMessage('Chyba pri ziskavani dat: ' + error, enqueueSnackbar, closeSnackbar),
    };
    const [getDeliveryData] = useLazyQuery(DELIVERY_BATCH, lazyOptions);
    const [getPickUpData] = useLazyQuery(PICKUP_BATCH, lazyOptions);
    const [getAssignData] = useLazyQuery(ASSIGN_BATCH, lazyOptions);
    const [getRejectData] = useLazyQuery(REJECT_BATCH, lazyOptions);
    const [getExchangeData] = useLazyQuery(EXCHANGE_BATCH, lazyOptions);
    const [getDepoData] = useLazyQuery(DEPO_BATCH, lazyOptions);

    const handleFormSent = (data, type) => {
        let additionalOptions = {};
        if (typeof type !== 'undefined' && type === 'exchange') {
            additionalOptions = {
                'exchangeToMessengerFromInput': String(data.messengerCode),
                'exchangeStreetFromInput': String(data.street),
                'exchangeStreetNumberFromInput': String(data.streetNumber),
                'exchangeCityFromInput': String(data.city),
                'exchangeZipFromInput': String(data.zip),
            };
        }
        setState((previousState) => {
            return {
                ...previousState,
                ...additionalOptions,
                open: false,
                id: null,
                dataToSend: {...previousState.dataToSend, [data.zasielkaKod]:  data},
            }
        });
    };

    const handleAction = action => () => {
        if (selectedShipments.length < 1) {
            errorMessage('Žiadna zásielka nebola vybratá pre hromadné spracovanie.', enqueueSnackbar, closeSnackbar);
            return;
        }
        setState((previousState) => {
            return {
                ...previousState,
                dataByCode: null,
                openDialog: true,
                action: action,
            }
        });
    };

    const startMultiselect = () => {
        const queryOptions = {
            variables: {shipmentCodes: selectedShipments}
        };

        switch (state.action) {
            case "delivery":
               getDeliveryData(queryOptions);
                break;
            case "pickUp":
                getPickUpData(queryOptions);
                break;
            case "assign":
                getAssignData(queryOptions);
                break;
            case "reject":
                getRejectData(queryOptions);
                break;
            case "exchange":
                getExchangeData(queryOptions);
                break;
            case "receiveDepot":
            case "depotDispersing":
                getDepoData(queryOptions);
                break;
            default:
                console.error(state);
                throw new Error("Invalid action");
        }
    }

    const handleClose = () => {
        setState((previousState) => {
            return {
                ...previousState,
                open: false,
                id: null,
            }
        });
    };

    const handleCloseDialog = () => {
        setState((previousState) => {
            return {
                ...previousState,
                openDialog: false,
            }
        });
    }

    const handleApproveDialog = () => {
        startMultiselect();
    }

    const getActionLabel = (state) => {
        let label = '';
        switch (state) {
            case "delivery":
                label = "doručiť";
                break;
            case "pickUp":
                label = "vyzdvihnúť";
                break;
            case "assign":
                label = "priradiť";
                break;
            case "reject":
                label = "odmietnuť";
                break;
            case "exchange":
                label = "prehodiť";
                break;
            case "depotDispersing":
                label = "vratiť na depo - rozvozové";
                break;
            case "receiveDepot":
                label = "príjem na sklad";
                break;
            default:
                console.info('Unknown multiselect state: ', state);
        }

        return label;
    }

    React.useEffect(() => {
        if (state.id === null && state.dataByCode) {
            prepareAndCheckDataForMutation();
        }
    }, [state.dataByCode, state.open, state.dataToSend]);

    return (
        <Fragment>
            <Dialog
                open={state.openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>{"Potvrdenie hromadnej operácie"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Naozaj chcete označené zásielky ({selectedShipments.length}) {getActionLabel(state.action)} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" className={classes.cancelDialogButton}>
                        Zrušiť
                    </Button>
                    <Button onClick={handleApproveDialog} color="primary" className={classes.okDialogButton} autoFocus>
                        Odoslať
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper className={classes.stickToBottom}>
                <div style={{display: 'flex'}}>
                    <SelectAll className={classes.selectButtons}>oznaciť všetky</SelectAll>
                    <UnselectAll className={classes.selectButtons}>odznačiť všetky</UnselectAll>
                    <Header>
                        Hromadné akcie ({selectedShipments.length})
                    </Header>
                </div>
                <Tabs
                    value={false}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                >
                    {((data && data.user && data.user.isAdmin ) || allowAssign) &&
                        <Tab label="Priradenie" icon={<PackageUp/>} onClick={handleAction('assign')}/>
                    }
                    <Tab label="Vyzdvihnutie" icon={<PickUp/>} onClick={handleAction('pickUp')}/>
                    <Tab label="Doručenie" icon={<Delivery/>} onClick={handleAction('delivery')}/>
                    <Tab label="Depo rozvoz" icon={<DepotDispersing/>} onClick={handleAction('depotDispersing')}/>
                    <Tab label="Odmietnutie" icon={<Reject/>} onClick={handleAction('reject')}/>
                    {data && data.user?.isStockIn && (
                        <Tab label="Príjem sklad" icon={<StockIn/>} onClick={handleAction('receiveDepot')}/>
                    )}
                </Tabs>
            </Paper>
            <Dialog open={state.open} onClose={handleClose}>
                {state.id && (
                    <Fragment>
                        <DialogTitle>
                            Zásielka {state.id}
                            <IconButton className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            {state.action === 'delivery' && (
                                <DeliveryTab
                                    key={state.id}
                                    shipment={state.dataByCode[state.id]}
                                    dataLocal={data}
                                    callCallbackForSend={handleFormSent}
                                />
                            )}
                            {state.action === 'pickUp' && (
                                <PickUpTab
                                    key={state.id}
                                    shipment={state.dataByCode[state.id]}
                                    dataLocal={data}
                                    callCallbackForSend={handleFormSent}
                                />
                            )}
                            {state.action === 'exchange' && (
                                <ExchangeTab
                                    key={state.id}
                                    shipment={state.dataByCode[state.id]}
                                    dataLocal={data}
                                    callCallbackForSend={handleFormSent}
                                />
                            )}
                        </DialogContent>
                    </Fragment>
                )}
            </Dialog>
        </Fragment>
    );
};
