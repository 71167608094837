/***
 *
 * @param time string like '00:10:23'
 * @returns {string} output will be string '10m23s'
 */
const formatTimeToNextStop = (time) => {
    let output = '';
    const timeChars = Array.from(time);

    if (timeChars[0] !== '0') {
        output += timeChars[0];
    }
    if (timeChars[1] !== '0') {
        output += timeChars[1];
    }
    if (output.length !== 0) {
        output += 'h';
    }

    let minutesWasAdded = false;
    if (timeChars[3] !== '0') {
        output += timeChars[3];
        minutesWasAdded = true;
    }
    if (timeChars[4] !== '0') {
        output += timeChars[4];
        minutesWasAdded = true;
    }
    if (minutesWasAdded) {
        output += 'm';
    }

    let secondsWasAdded = false;
    if (timeChars[6] !== '0') {
        output += timeChars[6];
        secondsWasAdded = true;
    }
    if (timeChars[7] !== '0') {
        output += timeChars[7];
        secondsWasAdded = true;
    }
    if (secondsWasAdded) {
        output += 's';
    }

    return output;
}

export {
    formatTimeToNextStop
}
