import React from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import {green, red} from "@material-ui/core/colors";
import {Switch as MaterialUISwitch} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const ColorSwitch = withStyles({
    switchBase: {
        color: red[300],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(MaterialUISwitch);

const useStyles = makeStyles(theme => ({
    mainLabel: {
        paddingTop: 10,
    },
}));

export default function MySwitch({label, value, checked, onChange}) {
    const classes = useStyles();
    return (
        <Grid component="label" container alignItems="center" spacing={0}>
            <Grid item xs={12}>
                <Typography className={classes.mainLabel}>{label}</Typography>
            </Grid>
            <Grid item>Off</Grid>
            <Grid item>
                <ColorSwitch
                    checked={checked}
                    onChange={onChange}
                    value={value}
                />
            </Grid>
            <Grid item>On</Grid>
        </Grid>
    );
}