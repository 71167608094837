import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 10000,
    cursor: 'pointer',
  },
  bonusBackground: {
    '--opacity': 0.5,
    backgroundImage: `linear-gradient(
            rgba(255, 0, 24, var(--opacity)) 0%,
            rgba(255, 0, 24, var(--opacity)) 16.6666%,
            rgba(255, 165, 44, var(--opacity)) 16.6666%,
            rgba(255, 165, 44, var(--opacity)) 33.333%,
            rgba(255, 255, 65, var(--opacity)) 33.333%,
            rgba(255, 255, 65, var(--opacity)) 50%,
            rgba(0, 128, 24, var(--opacity)) 50%,
            rgba(0, 128, 24, var(--opacity)) 66.6666%,
            rgba(0, 0, 249, var(--opacity)) 66.6666%,
            rgba(0, 0, 249, var(--opacity)) 83.3333%,
            rgba(134, 0, 125, var(--opacity)) 83.3333%,
            rgba(134, 0, 125, var(--opacity)) 100%
        )`,
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: '50px',
    color: 'white',
    transform: 'translate(-50%,-50%)',
    '-ms-transform': 'translate(-50%,-50%)',
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
      <div className={clsx(classes.root)}>
        <div className={classes.text}>
          <CircularProgress size="2em" />
        </div>
      </div>
  );
}
